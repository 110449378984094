import { InputNumber, InputText } from 'com-ui2/controls'
import React, { useEffect, useState } from 'react'

import useDebounce from 'com-ui/hooks/useDebounce'
import { InputMask } from 'primereact/inputmask'

type InputType = 'text' | 'number' | 'dropdown' | 'checkbox' | 'radio' | 'mask'

interface IDebouncedInput {
  type: InputType
  initValue: any
  onChanged: (value: any) => void
  mask?: string
  [key: string]: any
}

export function DebouncedInput({ type, initValue, onChanged, mask = '99', ...props }: IDebouncedInput) {
  const [val, setVal] = useState(initValue)
  const valDebounced = useDebounce(val, 500)

  const onChange = (value: any) => {
    setVal(value)
  }

  useEffect(() => {
    if (initValue !== val) setVal(initValue)
  }, [initValue])

  useEffect(() => {
    if (initValue !== val) onChanged?.(val)
  }, [valDebounced])

  return type === 'text' ? (
    <InputText value={val} onChange={(_event: any) => onChange(_event.target.value)} {...props} />
  ) : type === 'number' ? (
    <InputNumber value={val} onChange={(_event: any) => onChange(_event.value)} {...props} />
  ) : type === 'mask' ? (
    <InputMask mask={mask} value={val} onChange={(_event: any) => onChange(_event.value)} {...props} />
  ) : null
}
