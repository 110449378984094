import {
  IChoiceGroupProps,
  Customizer,
  ChoiceGroup,
  memoizeFunction,
  useCustomizationSettings,
  ITheme
} from '@fluentui/react'
import { mergeThemes } from '@fluentui/theme'
import React from 'react'

export const generateChoiceGroupTheme = memoizeFunction((theme: ITheme) => {
  return mergeThemes(theme, {
    semanticColors: {
      inputBorderHovered: 'var(--secondary-100-darker)',
      inputBackgroundChecked: 'var(--secondary-100)',
      inputBackgroundCheckedHovered: 'var(--secondary-100-darker)'
    }
  })
})

export const CrChoiceGroup: React.FC<IChoiceGroupProps> = (props) => {
  const settings = useCustomizationSettings(['theme'])
  return (
    <Customizer settings={{ theme: generateChoiceGroupTheme(settings.theme) }}>
      <ChoiceGroup {...props} />
    </Customizer>
  )
}
