import styled from 'styled-components'

export interface ICard extends Types.PlainObject {
  padding?: string
  borderRadius?: string
}

export default function Card({ padding = '24px 32px', borderRadius = '8px', ...props }: Types.PlainObject) {
  return (
    <CardContainer {...props} style={{ padding, borderRadius }}>
      {props.children}
    </CardContainer>
  )
}

const CardContainer = styled.div`
  border-radius: 8px;
  box-shadow: var(--depth-4);
  background-color: var(--white-100);
  transition: var(--transition);
  height: 100%;
  &:hover {
    box-shadow: var(--depth-16);
  }

  &.in-bg-white {
    box-shadow: none;
    border: 2px solid var(--indigo-3);
    box-sizing: border-box;
  }
`
