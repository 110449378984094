import { FaceBookContent, ProductService, UploadType } from 'src/services'
import React, { forwardRef, useImperativeHandle, useState } from 'react'
import { Dialog } from 'primereact/dialog'
import { Loading } from 'com-ui2/components'
import css from 'styled-jsx/css'
import { Icon } from 'com-ui2/components'
import { iconPath } from 'com-ui/utilities/imagePath'

import { productImageBuilder } from 'src/helpers'
import { Button } from 'com-ui2/controls'
import { downloadFileWithAxios } from 'com-ui-biz/scm/utilities'
import { copyText } from 'com-ui2/utilities/copy'
import { showToast } from 'com-ui2/utilities'
const PreviewProductDialog = forwardRef((_props, ref) => {
  const [visible, setVisible] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  // const [isLoadingDl, setIsLoadingDl] = useState(false)
  const [id, setId] = useState('')
  const [productPreview, setProductPreview] = useState<FaceBookContent>({})
  function onHide() {
    setVisible(false)
  }
  useImperativeHandle(ref, () => ({
    openModal(id: string) {
      if (id) {
        setIsLoading(true)
        setId(id)
        ProductService.product({ id })
          .then((result) => {
            setProductPreview(result?.facebook_content || {})
          })
          .catch(console.error)
          .finally(() => setIsLoading(false))
      }
      setVisible(true)
    }
  }))

  // const handleDownLoadVideo = async () => {
  //   setIsLoadingDl(true)
  //   await downloadFileWithAxios(
  //     productImageBuilder(id || '', productPreview.video ?? '', UploadType.facebookads),
  //     productPreview?.video || ''
  //   )
  //   setIsLoadingDl(false)
  // }
  return (
    <>
      <Dialog
        blockScroll={true}
        header="Suggested Ads"
        className="dialog"
        onHide={onHide}
        visible={visible}
        draggable={false}
        resizable={false}
      >
        <Loading isLoading={isLoading}></Loading>
        <div className="d-flex flex-column dialog__media">
          {/* <div>
            <h5 className="subtitle-1">Facebook video - Pushup</h5>
          </div> */}
          <div className="title">
            <p className="body-2b">Primary text</p>
            <div
              className="des"
              dangerouslySetInnerHTML={{
                __html: (productPreview?.primary_text || '').replaceAll('\n', '<br />')
              }}
            ></div>
            <span className="icon">
              <Icon
                onClick={() => {
                  copyText(productPreview?.primary_text || '')
                  showToast([{ life: 2000, severity: 'success', summary: 'Message', detail: 'Copy to Clipboard' }])
                }}
                src={iconPath('copy_regular')}
                size="20px"
                fill="var(--indigo-8)"
              />
            </span>
          </div>
          <div className="title">
            <p className="body-2b">Headline</p>
            <div className="des">{productPreview?.headline}</div>
            <span className="icon">
              <Icon
                onClick={() => {
                  copyText(productPreview?.headline || '')
                  showToast([{ life: 2000, severity: 'success', summary: 'Message', detail: 'Copy to Clipboard' }])
                }}
                src={iconPath('copy_regular')}
                size="20px"
                fill="var(--indigo-8)"
              />
            </span>
          </div>
          <div className="title">
            <p className="body-2b">Description</p>
            <div
              className="des"
              dangerouslySetInnerHTML={{ __html: (productPreview?.description || '').replaceAll('\n', '<br />') }}
            ></div>
            <span className="icon">
              <Icon
                onClick={() => {
                  copyText(productPreview?.description || '')
                  showToast([{ life: 2000, severity: 'success', summary: 'Message', detail: 'Copy to Clipboard' }])
                }}
                src={iconPath('copy_regular')}
                size="20px"
                fill="var(--indigo-8)"
              />
            </span>
          </div>
          {/* <div className="title">
            <p className="body-2b">Note</p>
            <div
              className="des"
              dangerouslySetInnerHTML={{ __html: (productPreview?.description || '').replaceAll('\n', '<br />') }}
            ></div>
            <span className="icon">
              <Icon
                onClick={() => {
                  copyText(productPreview?.description || '')
                  showToast([{ life: 2000, severity: 'success', summary: 'Message', detail: 'Copy to Clipboard' }])
                }}
                src={iconPath('copy_regular')}
                size="20px"
                fill="var(--indigo-8)"
              />
            </span>
          </div> */}
          <div className="download d-flex">
            <div className="d-flex flex-column body-2b">
              <div className="d-flex">
                <span className="icon-download">
                  <Icon src={iconPath('image_regular')} size="20px" fill="var(--indigo-8)" />
                </span>
                <p className="ml-1">Thumbnail</p>
              </div>
              {productPreview?.thumbnail && (
                <>
                  <div className="download-content">
                    <img
                      src={productImageBuilder(id || '', productPreview.thumbnail ?? '', UploadType.facebookads)}
                      alt={productPreview?.thumbnail || ''}
                    />
                  </div>

                  <Button
                    className="m-0"
                    size="small"
                    type="secondaryBorder"
                    onClick={() =>
                      downloadFileWithAxios(
                        productImageBuilder(id || '', productPreview.thumbnail ?? '', UploadType.facebookads),
                        productPreview?.thumbnail || ''
                      )
                    }
                  >
                    <Icon
                      className="mr-2"
                      src={iconPath('arrow_download_regular')}
                      size="16px"
                      fill="var(--secondary-100)"
                    />
                    <span className="body-2">Download</span>
                  </Button>
                </>
              )}
            </div>
            <div className="video d-flex flex-column body-2b">
              <div className="d-flex">
                <span className="icon-download">
                  <Icon src={iconPath('video_clip_regular')} size="20px" fill="var(--indigo-8)" />
                </span>
                <p className="ml-1">Video</p>
              </div>
              {productPreview?.video && (
                <>
                  <div className="download-content">
                    <video controls>
                      <track kind="captions"></track>
                      <source
                        src={productImageBuilder(id || '', productPreview.video ?? '', UploadType.facebookads)}
                        type="video/mp4"
                      />
                      <source
                        src={productImageBuilder(id || '', productPreview.video ?? '', UploadType.facebookads)}
                        type="video/ogg"
                      />
                    </video>
                  </div>
                  <Button
                    className="m-0"
                    size="small"
                    type="secondaryBorder"
                    onClick={() => {
                      window.open(
                        `${productImageBuilder(id || '', productPreview.video ?? '', UploadType.facebookads)}`,
                        '_blank'
                      )
                    }}
                  >
                    <Icon
                      className="mr-2"
                      src={iconPath('arrow_download_regular')}
                      size="16px"
                      fill="var(--secondary-100)"
                    />
                    <span className="body-2">Download</span>
                  </Button>
                </>
              )}
            </div>
          </div>
        </div>
        <style jsx>{style}</style>
      </Dialog>
    </>
  )
})
const style = css`
  .dialog {
    display: flex;
    flex-direction: column;
    &__media {
      width: 880px;
      margin-bottom: 30px;
      @media (max-width: 768px) {
        width: 100%;
      }
      img {
        width: 64px;
        height: 64px;
        object-fit: cover;
        display: block;
        overflow: hidden;
      }
    }
  }
  /* .btn-title {
    color: var((--secondary-100));
    display: flex;
    justify-content: space-between;
    align-items: center;
  } */
  .title {
    position: relative;
  }
  .des {
    border: 1px solid #eaecf0;
    box-sizing: border-box;
    border-radius: 4px;
    background: var(--indigo-1);
    min-height: 32px;
    padding: 8px 32px 8px 16px;
  }
  .icon {
    position: absolute;
    top: 54px;
    right: 8px;
  }
  .icon-download {
    margin: auto 0;
    margin-bottom: 4px;
  }
  .video {
    margin-left: 48px;
  }
  .download-content {
    width: 104px;
    height: 104px;
    border: 1px solid var(--indigo-3);
    box-sizing: border-box;
    border-radius: 8px;
    margin-bottom: 16px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 8px;
    }
    video {
      width: 100%;
      height: 100%;
      border-radius: 8px;
    }
  }
`
export default PreviewProductDialog
