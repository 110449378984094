import { useClickOutside } from 'com-ui2/hooks'
import { isValid, parse, format, isBefore, isAfter } from 'date-fns'
import { InputMask, InputMaskProps } from 'primereact/inputmask'
import { useEffect, useRef, useState } from 'react'

export interface InputMaskDateTimeProps extends InputMaskProps {
  date: string | number | Date | any
  template?: 'MM-dd-yyyy HH:mm' | 'MM-dd-yyyy' | 'yyyy MM dd - HH:mm'
  minDate?: Date
  maxDate?: Date
}

export const InputMaskDateTime = ({
  date,
  template = 'MM-dd-yyyy HH:mm',
  minDate = new Date('01-01-1970'),
  maxDate = new Date('01-01-2050'),
  onChange,
  className,
  ...props
}: InputMaskDateTimeProps) => {
  const slotChar = template
  const mask = template.replace(/[dMyHm]/g, '9')
  const [value, setValue] = useState('')
  const [, setError] = useState(true)
  const validateDate = (date: Date) => isValid(date) && isBefore(date, maxDate) && isAfter(date, minDate)
  const ref = useRef(null)

  useClickOutside(ref, () => {
    setError((error) => {
      if (error && ref?.current) {
        ;(ref.current as any).value = ''
        setValue('')
      }
      return error
    })
  })

  useEffect(() => {
    let _error = true
    try {
      const value = date ? format(date, template) || '' : ''
      setValue(value)
      _error = false
    } catch (error) {
      console.log(error)
    }
    setError(_error)
  }, [date])

  const onComplete = (e: any) => {
    const date = parse(e.value || '', template, 0)
    const validated = validateDate(date)
    let _error = true
    if (validated) {
      onChange?.(date as any)
      _error = false
    }
    setError(_error)
  }

  return (
    <InputMask
      {...{
        mask,
        slotChar,
        placeholder: slotChar,
        value,
        onComplete: onComplete,
        ref: (r: any) => (ref.current = r?.input),
        ...props
      }}
    />
  )
}
