import { useContext } from 'react'

import Head from 'next/head'
import { getCmsRoutes } from 'src/helpers'
import { sidebarContext } from 'src/layout/cms.layout'
import { useRouter } from 'next/router'
import { LogoUsername } from './LogoUsername'
import { Icon } from 'com-ui2/components/svg/Icon'
import { commonShared } from 'com-ui/utilities/iconPath'
interface HeaderProps {
  className?: string
}

export const Header = ({ className }: HeaderProps) => {
  const router = useRouter()
  const { setActive } = useContext(sidebarContext)
  const header = getCmsRoutes().find((route) => router.pathname.startsWith(route.href))?.text
  const description = getCmsRoutes().find((route) => router.pathname.startsWith(route.href))?.description
  const iconDescription = getCmsRoutes().find((route) => router.pathname.startsWith(route.href))?.iconDescription

  return (
    <header className={`custom-header ${className}`}>
      <Head>{typeof header != 'string' ? null : <title>{header}</title>}</Head>
      <div className="container-fluid header-container">
        <div className="header__left">
          <div className="d-md-none">
            <button className="navbar-burger" onClick={() => setActive(true)}>
              <span aria-hidden="true" />
              <span aria-hidden="true" />
              <span aria-hidden="true" />
            </button>
          </div>
          <div className="d-none d-md-block">
            <h5 className="head-primary">{header}</h5>
            {description && (
              <div className="body-2 d-flex align-items-center" style={{ color: 'var(--indigo-8)' }}>
                {description}
                {iconDescription && (
                  <Icon src={commonShared(`${iconDescription}`)} enableClick={false} size="24px" className="ml-2" />
                )}
              </div>
            )}
          </div>
        </div>
        {/* <div className="header__right" onClick={() => setOpen((open) => !open)}>
          <span className="user-text">
            {userProfile?.first_name} {userProfile?.last_name}
          </span>
          <span className="user-logo">
            {!userProfile && <span>{'A'}</span>}
            {userProfile && userProfile.avatar && <img src={userProfile.avatar} alt="" />}
            {userProfile && !userProfile.avatar && <span>{userProfile.first_name?.charAt(0)}</span>}
          </span>
          <div className={`header__right__dropdown ${open ? 'active' : ''}`} ref={dropdownRef}>
            <Link href="/settings">
              <a className="dropdown-link">
                <Icon src={commonShared('settings')} size="18px" className="mr-2" />
                <span>Setting</span>
              </a>
            </Link>
            <a href={logoutUrl} className="dropdown-link">
              <Icon src={commonShared('turn-off')} size="18px" className="mr-2" />
              <span>Log out</span>
            </a>
          </div>
        </div> */}
        {/* <div className={`dropdown-overlay d-md-none ${open ? 'active' : ''}`} /> */}

        <LogoUsername />
      </div>
    </header>
  )
}
