import React from 'react'
import css from 'styled-jsx/css'

interface TextEllipsisProps {
  width?: number
  text: string
  className?: string
  showTitle?: boolean
}

export const TextEllipsis = ({ width = 200, text, className, showTitle = true }: TextEllipsisProps) => {
  return (
    <div style={{ width: `${width}px` }} className={`${className} text`} title={showTitle ? text : ''}>
      {text}
      <style jsx>{style}</style>
    </div>
  )
}

const style = css`
  .text {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
`
