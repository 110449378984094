import { ProductViewInList } from './../services/cms'
import { namespaceConfig } from 'fast-redux'

type productState = {
  product: ProductViewInList | null
  visibleModal: boolean
}

const DEFAULT_STATE: productState = { product: null, visibleModal: false }

const { action, getState } = namespaceConfig('product', DEFAULT_STATE)

export const getProduct = (state: any) => getState(state)

export const initProduct = action(
  'initProduct',
  function (state: productState, product: ProductViewInList, visibleModal: boolean) {
    if (!product) return { ...state }
    return { ...state, product, visibleModal }
  }
)

export const updateProduct = action('updateProduct', function (state: productState, product: ProductViewInList) {
  if (!product) return { ...state }
  return { ...state, product: { ...state.product, ...product }, visibleModal: true }
})

export const visibleModalCreateSellPage = action(
  'visibleModalCreateSellPage',
  function (state: productState, visibleModal: boolean) {
    return { ...state, visibleModal: visibleModal }
  }
)
