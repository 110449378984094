import {
  DATE_FORMAT,
  DELIMITER,
  InputDateRangeProps,
  MASK_FORMAT,
  MAX_DATE,
  MIN_DATE,
  inputDateStyle
} from './DateHelper'
import { format, isAfter, isBefore, isValid, parse } from 'date-fns'
import { useEffect, useMemo, useRef, useState } from 'react'

import { Icon } from 'com-ui2/components'
import { InputGroup } from '../containers'
import { InputMask } from 'primereact/inputmask'
import { PickerDateRange } from './PickerDateRange'
import { iconPath } from 'com-ui/utilities/imagePath'
import { useClickOutside } from 'com-ui2/hooks'

/** TODO :
 * implement auto: detect screen
 * bug lost value when blur input mask
 * - collapse effect
 * - clear on blur
 * - props for default config
 */

const mask = `${MASK_FORMAT}${DELIMITER}${MASK_FORMAT}`
const slotChar = `${DATE_FORMAT}${DELIMITER}${DATE_FORMAT}`
const formatter = (start?: Date, end?: Date) => {
  if (!start || !end) {
    return ''
  }
  try {
    return `${format(start, DATE_FORMAT)}${DELIMITER}${format(end, DATE_FORMAT)}`
  } catch (error) {
    return ''
  }
}

export function InputDateRange({
  startDate,
  endDate,
  minDate = MIN_DATE,
  maxDate = MAX_DATE,
  className,
  onChange,
  position = 'bottom left',
  pickerProps,
  placeholder,
  inputProp
}: InputDateRangeProps) {
  const [focus, setFocus] = useState(false)
  const ref = useRef(null)
  useClickOutside(ref, () => {
    setFocus(false)
  })

  return useMemo(
    () => (
      <div className={`input-date-container ${className || ''}`} ref={ref}>
        <div className="input-date" onClick={(_e) => !focus && setFocus(true)}>
          <InputMaskDateRange {...{ placeholder, startDate, endDate, minDate, maxDate, onChange, focus, inputProp }} />
        </div>
        <div className={`date-wrapper ${position} ${focus ? 'open' : ''}`}>
          <PickerDateRange {...{ startDate, endDate, minDate, maxDate, onChange, pickerProps }} />
        </div>
        <style jsx>{inputDateStyle}</style>
      </div>
    ),
    [startDate, endDate, focus]
  )
}

const InputMaskDateRange = ({
  startDate,
  endDate,
  minDate = MIN_DATE,
  maxDate = MAX_DATE,
  onChange,
  focus,
  placeholder,
  inputProp
}: InputDateRangeProps) => {
  const nextVal = formatter(startDate, endDate)
  const [value, setValue] = useState(nextVal)
  useEffect(() => {
    setValue((val: string) => {
      //console.log('set value', nextVal, val)
      return nextVal !== val ? nextVal : val
    })
  }, [nextVal, focus])

  const _onChange = (e: any) => {
    const _value = focus ? e.value : nextVal
    setValue(_value)
    validate(_value)
  }

  const validate = (val: string) => {
    if (!val) {
      onChange?.(undefined, undefined)
    }
    const [nextStartDate, nextEndDate] = val.split(DELIMITER).map((s: string) => {
      const date = parse(s, DATE_FORMAT, new Date())
      return isValid(date) && isBefore(date, maxDate) && isAfter(date, minDate) ? date : undefined
    })
    if (
      !nextStartDate ||
      !nextEndDate ||
      formatter(nextStartDate, nextEndDate) == formatter(startDate, endDate) ||
      isBefore(nextEndDate, nextStartDate)
    ) {
      return
    }
    onChange?.(nextStartDate, nextEndDate)
  }

  return (
    <InputGroup
      suffix={
        <div className="d-flex align-items-center">
          <Icon src={iconPath('calendar_ltr_regular')} size="20px" fill="var(--gray-7)" />
        </div>
      }
      prefixDelimiter={false}
      suffixDelimiter={false}
    >
      <InputMask
        mask={mask}
        slotChar={slotChar}
        value={value}
        onChange={_onChange}
        placeholder={`${placeholder ? placeholder : `From${DELIMITER}To`}`}
        //unmask={true}
        {...inputProp}
      />
    </InputGroup>
  )
}
