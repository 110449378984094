import * as Sentry from '@sentry/node'
import getConfig from 'next/config'
import { Integrations } from '@sentry/tracing'

import { isBrowser } from 'com-ui/utilities/ssr'
let initialize = false
import { CaptureConsole } from '@sentry/integrations'

export const initSentry = () => {
  if (initialize) return
  initialize = true
  const sentryDsn = isBrowser() ? getConfig().publicRuntimeConfig.sentryDsn : process.env.Sentry__Dsn
  const sentryEnvironment = isBrowser() ? getConfig().publicRuntimeConfig.env : process.env.env
  const buildVersion = isBrowser() ? getConfig().publicRuntimeConfig.buildVersion : process.env.BUILD_VERSION

  if (sentryDsn) {
    console.log('error')
    const integrations = [
      new Integrations.BrowserTracing(),
      new CaptureConsole({
        levels: ['error', 'warn']
      })
    ]

    Sentry.init({
      ignoreErrors: [/^Cannot parse: xyz*$/],
      integrations,
      dsn: sentryDsn,
      environment: sentryEnvironment == 'production' ? 'prd' : sentryEnvironment,
      release: buildVersion
    })
  }
}
