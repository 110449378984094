import { DataTable as Table, DataTableProps as TableProps } from 'primereact/datatable'
import React from 'react'
import css from 'styled-jsx/css'

interface DataTableProps extends TableProps {
  children: any
}

export const DataTable = ({ children, ...props }: DataTableProps) => {
  return (
    <div className="table-wrapper">
      <Table {...props}>{children}</Table>
      <style jsx>{style}</style>
    </div>
  )
}

const style = css`
  .table-wrapper {
    overflow: auto;
    display: inline;
  }
  :global(.p-datatable) {
    min-width: 1140px;
  }

  :global(body .p-datatable .p-datatable-scrollable-header, body .p-datatable .p-datatable-scrollable-footer) {
    background: var(--indigo-2);
  }
  :global(.p-datatable .p-datatable-thead > tr > th) {
    background: var(--indigo-2);
  }
`
