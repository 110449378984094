import { InputTextareaProps, InputTextarea as PInputTextarea } from 'primereact/inputtextarea'
import { forwardRef, useRef } from 'react'

import { Icon } from 'com-ui2/components'
import { iconPath } from 'com-ui/utilities/imagePath'
import { useCombinedRefs } from 'com-ui/hooks'

export const InputTextarea = forwardRef(
  ({ className, placeholder, value, onChange, ...props }: InputTextareaProps | any, forwardRef) => {
    const ref = useCombinedRefs(forwardRef, useRef(null))
    const reset = () => {
      const target: any = (ref as any)?.current
      if (target) {
        target.value = ''
        onChange?.({ target: target } as any)
      }
    }
    return (
      <div className={`input-textarea-wrapper ${className || ''}`}>
        <PInputTextarea {...(props as any)} ref={ref} value={value} onChange={onChange} placeholder={placeholder} />
        {value && (
          <div className="icon-wrapper">
            <Icon size="14px" src={iconPath('dismiss_regular')} fill="var(--gray-6)" onClick={reset} />
          </div>
        )}
        <style jsx>
          {`
            .input-textarea-wrapper {
              position: relative;
              :global(.p-inputtext) {
                width: 100%;
                &:focus,
                &:hover {
                  & ~ .icon-wrapper {
                    display: inline-flex;
                  }
                }
              }
            }
            .icon-wrapper {
              display: none;
              position: absolute;
              align-items: center;
              justify-content: center;
              width: 24px;
              height: 24px;
              top: 4px;
              right: 4px;
              background-color: var(--white-100);
              &:hover {
                display: inline-flex;
              }
            }
          `}
        </style>
      </div>
    )
  }
)
