import { PropsWithChildren, ReactNode, useMemo } from 'react'
import { ValidatorFn, isRequire, validateField } from 'com-ui2/hooks'

import { InputGroup } from './InputGroup'
import { InputText } from '../InputText'

export interface ValidateControlProps {
  title: string
  value?: any
  onChange?: any
  editor?: string | ReactNode
  require?: boolean
  requireType?: 'star' | 'optional'
  validators?: ValidatorFn[]
  placeholder?: string
  firstSubmit?: boolean
  error?: string
  className?: string
  disabled?: boolean
  prefix?: string | ReactNode
}

export const ValidateControl = (props: ValidateControlProps) =>
  useMemo(() => <Control {...props} />, [props.value, props.require, props.firstSubmit, props.error, props.validators])

function Control({
  title,
  placeholder,
  value,
  onChange,
  editor,
  require,
  requireType = 'optional',
  validators = [],
  firstSubmit,
  error = '',
  disabled = false,
  prefix
}: PropsWithChildren<ValidateControlProps>) {
  validators = require ? [isRequire(), ...validators] : validators
  error = firstSubmit ? validateField(value, title, validators) || error : error || ''
  const defaultEditor = <InputText value={value} onChange={onChange} placeholder={placeholder} disabled={disabled} />

  return (
    <InputGroup
      title={
        <>
          {title}
          {!require && requireType === 'optional' ? <span className="text--primary ml-1">(optional)</span> : null}
          {require && requireType === 'star' ? '*' : null}
        </>
      }
      prefix={prefix}
      error={error}
    >
      {editor || defaultEditor}
    </InputGroup>
  )
}
