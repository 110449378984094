import React from 'react'
import css from 'styled-jsx/css'
import { commonShared } from 'com-ui/utilities/imagePath'
import { Button } from 'com-ui2/controls'

const UnderReview = () => {
  return (
    <>
      <div className="layout-under-login container ">
        <div className="under-login">
          <div className="logo-container">
            <img src={commonShared('selless-logo-black.svg')} alt="selless logo" />
          </div>
          <div className="d-flex justify-content-center">
            <p className="h6 body-1 text-center-style " style={{ textAlign: 'center', color: 'var(--gray-8)' }}>
              We are currently reviewing your submission and shall get in touch with you shortly
            </p>
          </div>

          <div className="d-flex justify-content-center">
            <Button className="btn" type="primary" onClick={() => window.open('https://selless.com', '_blank')}>
              Go to Selless Site
            </Button>
          </div>
        </div>
        <style jsx>{style}</style>
      </div>
    </>
  )
}

const style = css`
  :global(.app) {
    height: 100vh;
  }
  .layout-under-login {
    min-height: 80vh;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-left: 30px;
    padding-right: 30px;
  }

  .under-login {
    padding: 80px;
    box-shadow: var(--depth-16);
    background-color: var(--white-100);
    border-radius: 16px;
    max-width: 624px;
    width: 100%;
    margin: auto;
    max-height: 384px;
    height: 100%;
    @media (max-width: 768px) {
      padding: 48px;
    }
  }

  .logo-container {
    padding: 0 0 32px;
    text-align: center;
  }

  .text-center-style {
    max-width: 358px;
    width: 100%;
    margin-bottom: 48px;
  }
  :global(.button_) {
    padding: 12px 24px !important;
    max-width: 250px;
    width: 100% !important;
    height: auto !important;
    @media (max-width: 768px) {
      width: fit-content !important;
    }
  }
`

export default UnderReview
