import css from 'styled-jsx/css'

export interface CardProps extends Types.PlainObject {
  className?: string
}

export default function Card({ className, ...props }: CardProps) {
  return (
    <article {...props} className={`article ${className || ''} `}>
      {props.children}
      <style jsx>{style}</style>
    </article>
  )
}

const style = css`
  .article {
    border-radius: 8px;
    box-shadow: var(--depth-4);
    background-color: var(--white-100);
    transition: var(--transition);

    &:hover {
      box-shadow: var(--depth-16);
    }
    &.in-bg-white {
      box-shadow: none;
      border: 2px solid var(--indigo-3);
      box-sizing: border-box;
    }
  }
`
