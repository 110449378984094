import { CSSProperties, PropsWithChildren, useRef, useState } from 'react'

import { useClickOutside } from 'com-ui2/hooks'

interface ICallout {
  calloutContent?: JSX.Element
  calloutClassname?: string
  calloutDropdownStyle?: CSSProperties
  isBeakVisible?: boolean
  calloutPosition?: 'left' | 'right'
  width?: string
}

export const Callout = ({
  children,
  calloutContent,
  isBeakVisible = true,
  calloutDropdownStyle,
  calloutClassname,
  calloutPosition = 'right',
  width = '200px'
}: PropsWithChildren<ICallout>) => {
  const dropdownRef = useRef<any>(null)
  const [open, setOpen] = useState(false)

  useClickOutside(dropdownRef, () => {
    setOpen(false)
  })

  return (
    <div
      className="callout"
      onClick={(e) => {
        e.stopPropagation()
        setOpen((open) => !open)
      }}
    >
      {children}
      {calloutContent && (
        <div
          className={`callout__dropdown ${open ? 'active' : ''} ${calloutPosition} ${calloutClassname ?? ''}`}
          style={calloutDropdownStyle}
          ref={dropdownRef}
        >
          {calloutContent}
        </div>
      )}
      <style jsx>{`
        .callout {
          position: relative;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;

          .callout__dropdown {
            position: absolute;
            z-index: 999;
            top: calc(100% + ${isBeakVisible ? '14' : '4'}px);
            right: calc(50% - 16px);
            background-color: #fff;
            border-radius: 8px;
            /* stylelint-disable */
            box-shadow: 0 0.5em 1em -0.125em rgba(10, 10, 10, 0.1), 0 0 0 1px rgba(10, 10, 10, 0.02);
            /* stylelint-enable */
            width: ${width};
            padding-top: 0.75rem;
            padding-bottom: 0.75rem;
            opacity: 0;
            display: none;

            &.active {
              opacity: 1;
              display: block;
            }

            &::after {
              width: 16px;
              height: 16px;
              content: '';
              border: 1px solid transparent;
              border-top-color: #ececec;
              border-right-color: #ececec;
              transform: rotate(-45deg);
              position: absolute;
              top: -8px;
              right: 16px;
              background-color: #fff;
              display: ${isBeakVisible ? 'block' : 'none'};
            }

            &.left {
              right: auto;
              left: 0;
              &::after {
                right: auto;
                left: 8px;
              }
            }
          }
        }
      `}</style>
    </div>
  )
}
