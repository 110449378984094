import { IButtonStyles, memoizeFunction, mergeStyleSets } from '@fluentui/react'

import { CrButtonProps } from './cr-button'

export const baseButtonStyles: IButtonStyles = {
  root: {
    fontFamily: 'var(--font)',
    fontWeight: 600,
    borderRadius: 'var(--round-8)'
  },
  icon: {
    fontSize: 20,
    lineHeight: 24,
    height: 'unset'
  }
}

export const secondaryButtonStyles: IButtonStyles = mergeStyleSets(baseButtonStyles, {
  root: {
    backgroundColor: 'var(--secondary-100)',
    borderColor: 'var(--secondary-100)'
  },
  rootHovered: {
    backgroundColor: 'var(--secondary-100-darker)',
    borderColor: 'var(--secondary-100-darker)'
  },
  rootPressed: {
    backgroundColor: 'var(--secondary-100-dark)',
    borderColor: 'var(--secondary-100-dark)'
  }
})

export const whiteButtonStyles: IButtonStyles = mergeStyleSets(baseButtonStyles, {
  root: {
    backgroundColor: 'var(--white-100)',
    borderColor: 'var(--white-100)'
  },
  rootHovered: {
    backgroundColor: 'var(--indigo-2)',
    borderColor: 'var(--indigo-2)'
  },
  rootPressed: {
    backgroundColor: 'var(--indigo-1)',
    borderColor: 'var(--indigo-1)'
  }
})

export const neutralButtonStyles: IButtonStyles = mergeStyleSets(baseButtonStyles, {
  root: {
    backgroundColor: 'var(--indigo-2)',
    borderColor: 'var(--indigo-2)'
  },
  rootHovered: {
    backgroundColor: 'var(--indigo-4)',
    borderColor: 'var(--indigo-4)'
  },
  rootPressed: {
    backgroundColor: 'var(--indigo-3)',
    borderColor: 'var(--indigo-3)'
  }
})

export const neutralBorderButtonStyles: IButtonStyles = mergeStyleSets(baseButtonStyles, {
  root: {
    backgroundColor: 'var(--white-100)',
    borderColor: 'var(--indigo-4)',
    borderWidth: 2
  },
  rootHovered: {
    backgroundColor: 'var(--indigo-2)',
    borderColor: 'var(--indigo-6)'
  },
  rootPressed: {
    backgroundColor: 'var(--indigo-1)',
    borderColor: 'var(--indigo-5)'
  }
})

export const secondaryBorderButtonStyles: IButtonStyles = mergeStyleSets(baseButtonStyles, {
  root: {
    backgroundColor: 'var(--white-100)',
    borderColor: 'var(--secondary-50)',
    color: 'var(--secondary-100)',
    borderWidth: 2
  },
  rootHovered: {
    backgroundColor: 'var(--indigo-2)',
    borderColor: 'var(--secondary-50-darker)',
    color: 'var(--secondary-100)'
  },
  rootPressed: {
    backgroundColor: 'var(--indigo-1)',
    borderColor: 'var(--secondary-50-dark)',
    color: 'var(--secondary-100)'
  }
})

const getTypeStyles = (props: CrButtonProps): IButtonStyles => {
  switch (props.type) {
    case 'secondary':
      return secondaryButtonStyles
    case 'white':
      return whiteButtonStyles
    case 'neutral':
      return neutralButtonStyles
    case 'neutralBorder':
      return neutralBorderButtonStyles
    case 'secondaryBorder':
      return secondaryBorderButtonStyles
    default:
      return baseButtonStyles
  }
}

const defaultSizeStyles = {
  root: {
    height: 40,
    padding: '8px 16px'
  }
}

const bigSizeStyles = {
  root: {
    height: 48,
    fontFamily: 'var(--font-header)',
    padding: '12px 24px'
  },
  label: {
    fontSize: 14,
    lineHeight: 24
  }
}

const smallSizeStyles = {
  root: {
    height: 32,
    borderRadius: 'var(--round-4)',
    padding: '4px 12px'
  },
  label: {
    fontSize: 13,
    lineHeight: 24
  }
}

const getSizeStyles = (props: CrButtonProps): IButtonStyles => {
  switch (props.sizing) {
    case 'small':
      return smallSizeStyles
    case 'medium':
      return defaultSizeStyles
    case 'big':
      return bigSizeStyles
    default:
      return defaultSizeStyles
  }
}

const iconAlignedCenterStyles: IButtonStyles = {
  textContainer: {
    flexGrow: 0
  }
}

const getIconAlignedStyles = (props: CrButtonProps): IButtonStyles => {
  switch (props.iconAligned) {
    case 'center':
      return iconAlignedCenterStyles
    default:
      return {}
  }
}

const fontNormalStyle: IButtonStyles = {
  textContainer: {
    fontWeight: 400
  },
  label: {
    fontWeight: 400
  }
}

const getFontStyle = (props: CrButtonProps): IButtonStyles => {
  switch (props.font) {
    case 'normal':
      return fontNormalStyle
    default:
      return {}
  }
}

export const getCrButtonStyles = memoizeFunction(
  (props: CrButtonProps): IButtonStyles => {
    return mergeStyleSets(getTypeStyles(props), getSizeStyles(props), getIconAlignedStyles(props), getFontStyle(props))
  }
)
