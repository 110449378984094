/* eslint-disable prettier/prettier */
import { MultiSelectProps, MultiSelect as PMultiSelect } from 'primereact/multiselect'

export function MultiSelect({ className, ...props }: MultiSelectProps) {
  return (
    <div className={`${className || ''}`}>
      <PMultiSelect {...props} />
      <style jsx>{`
        :global(.p-multiselect) {
          display: flex;
          transition: border var(--transition), box-shadow var(--transition);
        }
        :global(.p-multiselect:not(.p-disabled)) {
          box-shadow: var(--depth-4);
          border: 1px solid var(--indigo-2);
        }
        :global(.p-multiselect:not(.p-disabled).p-focus),
        :global(.p-multiselect:not(.p-disabled):hover) {
          box-shadow: var(--depth-2);
          border: 1px solid var(--indigo-4);
        }
        :global(.p-multiselect-panel .p-multiselect-header .p-multiselect-close) {
          margin-right: -10px;
        }
        :global(.p-multiselect-item) {
          color: var(--indigo-4);
          font-weight: 500;
          font-size: 13px;
          line-height: 24px;
        }
        :global(.p-multiselect-item .p-checkbox-box), 
        :global(.p-multiselect-header .p-checkbox .p-checkbox-box)
        {
          border: 2px solid var(--indigo-7);
        }
        :global(.p-multiselect-panel .p-multiselect-items .p-multiselect-item:focus) {
          box-shadow: none;
        }
        :global(.p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight) {
          background-color: var(--indigo-1);
        }
        :global(.p-multiselect-panel .p-multiselect-items .p-multiselect-item:not(.p-highlight):not(.p-disabled):hover) {
          background-color: var(--indigo-2);
        }

        :global(.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box:hover) {
          border-color: var(--secondary-100);
        }
        :global(.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover),
        :global(.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight),
        :global(.p-checkbox .p-checkbox-box.p-highlight) {
          border-color: var(--secondary-100);
          background: var(--secondary-100);

        }
        :global(.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus) {
          box-shadow: none;
        }
        :global(.p-multiselect-panel .p-multiselect-header .p-multiselect-filter-container .p-multiselect-filter-icon) {
          color: var(--gray-7);
          font-size: 16px;
          line-height: 24px;
          transform: translate(-50%, -50%);
          margin-top: 0;
        }
        :global(.p-multiselect .p-multiselect-label, .p-multiselect .p-multiselect-label.p-placeholder) {
          font-weight: 500;
          font-size: 13px;
          line-height: 24px;
        }
        :global(.p-multiselect .p-multiselect-label.p-placeholder) {
          color: var(--gray-8);
        }
      `}</style>
    </div>
  )
}
