import styled from 'styled-components'
import { SketchPicker } from 'react-color'
import { useEffect, useRef, useState } from 'react'
import { useId } from '@fluentui/react-hooks'
import CrLabel from './cr-label'
import { CrTextField } from './text-field'

export interface IColorEditor extends Types.PlainObject {
  value?: string
  onChange?: (value: string) => void
}

export type Icolor = {
  hex: string
  hsl: {
    a: number | string
    h: number | string
    l: number | string
    s: number | string
  }
  hsv: {
    a: number | string
    h: number | string
    s: number | string
    v: number | string
  }
  oldHue: number | string
  rgb: {
    a: number | string
    b: number | string
    g: number | string
    r: number | string
  }
  source: string
}

const defaultColor = {
  rgb: {
    r: 74,
    g: 114,
    b: 226,
    a: 1
  },
  hex: '#4a90e2'
}

export default function CrColorEditor(props: IColorEditor) {
  const colorPickerId = useId('colorPickerId')
  const [selectedColor, setSelectedColor] = useState<any>({
    ...defaultColor,
    hex: props.value
  })
  const ref = useRef(null)
  const [showPicker, setShowPicker] = useState(false)

  useEffect(() => {
    function handleContains(event: React.MouseEvent<HTMLDivElement, MouseEvent> | any) {
      const container: any = ref.current
      const contain = container && container.contains(event.target)
      if (!contain) {
        setShowPicker(false)
      }
    }
    document && document.addEventListener('mousedown', handleContains)
    return () => {
      document && document.removeEventListener('mousedown', handleContains)
    }
  })

  const onChange = (_color: Icolor) => {
    setSelectedColor(_color)
    props?.onChange?.(_color.hex)
  }

  return (
    <CrLabel {...{ ...props, htmlFor: colorPickerId }}>
      <ColorPickerContainer ref={ref}>
        <CrTextField
          {...{ ...props, label: '', value: selectedColor.hex || '', onChange: () => null }}
          onClick={(_e) => setShowPicker(!showPicker)}
        />
        <span className="color-preview" style={{ backgroundColor: selectedColor.hex || '' }}></span>
        {showPicker && (
          <div className="color-picker">
            <SketchPicker {...{ color: selectedColor.rgb, onChange }} />
          </div>
        )}
      </ColorPickerContainer>
    </CrLabel>
  )
}

const ColorPickerContainer = styled.div`
  position: relative;
  .ms-TextField-field {
    padding-left: 50px;
  }
  .color-preview {
    position: absolute;
    top: 50%;
    left: 12px;
    transform: translateY(-50%);
    display: inline-block;
    width: 32px;
    height: 20px;
    border-radius: 30px;
    border: 1px solid var(--indigo-3);
    margin-right: 5px;
    pointer-events: none;
  }
  .color-picker {
    position: absolute;
    display: inline-block;
    top: 100%;
    left: 0;
    z-index: 10;
  }
`
