import { Button } from 'com-ui2/controls'
import React from 'react'
import css from 'styled-jsx/css'
import { Icon, Tooltip } from 'com-ui2/components'
import { showToast } from 'com-ui2/utilities'
import { iconPath } from 'com-ui/utilities/imagePath'
import { copyText } from 'com-ui2/utilities/copy'

interface CopyLinkTagProps {
  text?: string
  textCopy?: string
  keyId?: string
  tooltipText?: string
}
export function CopyLinkTag({ text, textCopy, tooltipText, keyId = 'x' }: CopyLinkTagProps) {
  const clickHandle = () => {
    copyText(text || '')
    showToast({ severity: 'success', summary: 'Text copied!' })
  }

  return (
    <>
      <div className={`d-flex align-items-center wrap-content justify-content-between`}>
        <div className="subtitle-2" style={{ color: 'var(--gray-7)' }}>
          {text}
        </div>
        <div className={`d-flex align-items-center`} >
          <Button type="secondaryLink" style={{ padding: 0, margin: 0, height: 24, width: 110 }} onClick={clickHandle}>
            <>
              <Tooltip target={`.copy-indicator_${keyId}`} showDelay={200} type="white">
                <span className="body-2">{tooltipText ?? 'Copy'}</span>
              </Tooltip>
              <div className={`copy-indicator_${keyId} d-flex align-items-center`}>
                <Icon src={iconPath('copy_regular')} fill="var(--blue-500)" size={'20px'} />
              </div>
            </>
            <span className="ml-2" style={{ color: 'var(--blue-500)' }}>
              {textCopy}
            </span>
          </Button>
        </div>
      </div>
      <style jsx>{styleCopyTag}</style>
    </>
  )
}

const styleCopyTag = css`
  .wrap-content {
    padding: 12px 24px;
    background: var(--secondary-30);
    border: 2px dashed var(--secondary-50);
    border-radius: 8px;
  }
`
