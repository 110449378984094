import * as Highcharts from 'highcharts'

import HighchartsExporting from 'highcharts/modules/exporting'
import HighchartsReact from 'highcharts-react-official'

if (typeof Highcharts === 'object') {
  HighchartsExporting(Highcharts)
}

/**
 * API: https://api.highcharts.com/highcharts/
 * Demo: https://www.highcharts.com/demo
 */
export function Chart(props: HighchartsReact.Props) {
  return <HighchartsReact highcharts={Highcharts} {...props} />
}
