import { Icon } from 'com-ui2/components'
import { SellessDomain } from 'src/services'
import css from 'styled-jsx/css'
import { getPrimaryDomain } from 'src/helpers'
import { iconPath } from 'com-ui/utilities/imagePath'

interface CardDomainProps {
  domains?: SellessDomain[]
  slug?: string
}
export const CardDomain = ({ domains, slug }: CardDomainProps) => {
  const primaryDomain = getPrimaryDomain(domains)

  return (
    <>
      <a
        className="domain-link body-2 d-flex align-items-center"
        href={`https://${primaryDomain}/${slug}` ?? '/'}
        target="_blank"
        rel="noreferrer"
      >
        <span className="text">
          {primaryDomain}/{slug}
        </span>
        <Icon className="ml-1" src={iconPath('open_regular')} fill="var(--secondary-100)" size="20px" />
      </a>
      <style jsx>{style}</style>
    </>
  )
}

const style = css`
  .domain-link {
    padding: 8px;
    border-radius: 8px;
    color: var(--blue-600);
    width: fit-content;
    .text {
      max-width: 200px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
`
