import { DropDownListModel, SellPageSetting, SellPageViewInDetail, Store } from 'src/services'

import { DomainByPublisher } from 'src/services/domain'
import { deepCloneObject } from 'src/helpers'
import { namespaceConfig } from 'fast-redux'

type ShopState = {
  shop: SellPageViewInDetail | null
  shopOriginal: SellPageViewInDetail | null
  shopDomains: DropDownListModel[]
  submitDraft: SellPageViewInDetail | null
  isLoading: boolean
}

const DEFAULT_STATE: ShopState = {
  shop: null,
  shopOriginal: null,
  shopDomains: [],
  submitDraft: null,
  isLoading: false
}

const { action, getState } = namespaceConfig('shop', DEFAULT_STATE)

const getShopState = (state: any): ShopState => getState(state)

export const getShop = (state: any) => getShopState(state).shop
export const getShopOriginal = (state: any) => getShopState(state).shopOriginal
export const getShopDomains = (state: any) => getShopState(state).shopDomains
export const getShopProps = <K extends keyof SellPageViewInDetail>(key: K) => (state: any) =>
  getShopState(state).shop?.[key]
export const getShopSettingProps = <K extends keyof SellPageSetting>(key: K) => (state: any) =>
  getShopState(state).shop?.setting?.[key]
export const getShopStoreProps = <K extends keyof Store>(key: K) => (state: any) =>
  getShopState(state).shop?.store?.[key]
export const getSubmitDraftState = (state: any) => getShopState(state).submitDraft
export const getLoadingState = (state: any) => getShopState(state).isLoading

export const initShop = action('initShop', function (state: ShopState, shop: SellPageViewInDetail) {
  if (!shop) return { ...state }
  return { ...state, shop, shopOriginal: deepCloneObject(shop) }
})

export const updateShop = action('updateShop', function (state: ShopState, shop: SellPageViewInDetail) {
  if (!shop) return { ...state }
  const newShop = { ...state.shop }
  if (shop.setting) {
    newShop.setting = { ...newShop.setting, ...shop.setting }
    delete shop.setting
  }
  if (shop.store) {
    newShop.store = { ...newShop.store, ...shop.store }
    delete shop.store
  }
  return { ...state, shop: { ...newShop, ...shop } }
})

export const updateShopDomains = action('updateShopDomains', function (state: ShopState, domains: DomainByPublisher[]) {
  if (!domains) return { ...state }
  return { ...state, shopDomains: domains }
})

export const updateLoading = action('updateLoading', function (state: ShopState, isLoading: boolean) {
  return { ...state, isLoading }
})

export const updateStateSubmitDraft = action(
  'updateStateSubmitDraft',
  function (state: ShopState, submitDraft: SellPageViewInDetail | null) {
    return { ...state, submitDraft }
  }
)
