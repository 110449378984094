import { useRef, useState } from 'react'
import { useMotionDndContext } from './dnd-context'
import { DropSetting } from './types'

export function useMotionDrop<E extends HTMLElement>({
  accept,
  canDrop = () => true,
  drop = () => null,
  hover = () => null,
  hoverBegin = () => null,
  hoverEnd = () => null
}: DropSetting) {
  const { draggingItem, setDraggingItem } = useMotionDndContext()
  const [isHovering, setHovering] = useState(false)

  const dropRef = useRef<E>(null)

  const onHoverStart = (event: PointerEvent) => {
    setHovering(true)
    if (draggingItem) {
      hoverBegin(draggingItem, event)
    }
  }
  const onHoverEnd = (event: PointerEvent) => {
    setHovering(false)
    hoverEnd(draggingItem, event)
  }
  const onHover = (event: React.PointerEvent) => {
    if (draggingItem) {
      hover(draggingItem, event)
    }
  }
  const onDrop = (event: PointerEvent) => {
    if (draggingItem && accept === draggingItem.type && canDrop(draggingItem)) {
      drop(draggingItem, event)
      setDraggingItem(undefined)
    }
  }

  return { isHovering, dropRef, onHoverStart, onHoverEnd, onHover, onDrop }
}
