import { DefaultButton, IButtonProps, concatStyleSets } from '@fluentui/react'

import React from 'react'
import { getCrButtonStyles } from './cr-button.styles'

export type CrButtonType = 'primary' | 'secondary' | 'white' | 'neutral' | 'neutralBorder' | 'secondaryBorder'

export type CrButtonSize = 'big' | 'medium' | 'small'

export type CrButtonIconAligned = 'center'

export type CrButtonFont = 'normal' | 'bold'

export interface CrButtonProps extends IButtonProps {
  type?: CrButtonType
  sizing?: CrButtonSize
  iconAligned?: CrButtonIconAligned
  font?: CrButtonFont
}

export const CrButton: React.FC<CrButtonProps> = ({ ...props }) => {
  return (
    <DefaultButton
      {...props}
      primary={props.type && ['primary', 'secondary'].includes(props.type)}
      styles={concatStyleSets(getCrButtonStyles(props), props.styles)}
    />
  )
}
