export interface AvatarProps {
  src: string
  size?: 16 | 20 | 24 | 28 | 32 | 48 | 64
  className?: string
  borderRadius?: string
  onError?: () => any
}

export const Avatar = ({ src, size = 32, className, borderRadius = '50%', onError }: AvatarProps) => {
  return (
    <>
      <img src={src} alt="avatar" className={`${className || ''} avatar`} onError={() => onError?.()} />
      <style jsx>
        {`
          .avatar {
            height: ${size}px;
            width: ${size}px;
            border-radius: ${borderRadius};
            object-fit: scale-down;
          }
        `}
      </style>
    </>
  )
}
