import { ILoading, Loading } from './Loading'

import { useEffect } from 'react'
import { useRef } from 'react'

export interface InfiniteLoadingProps extends Omit<ILoading, 'isLoading'> {
  onNext: any
  className?: string
  isLoading?: boolean
}

export const InfiniteLoading = ({
  isLoading = true,
  onNext,
  className,
  iconSize = '24px',
  loadingText = 'Loading ...',
  ...props
}: InfiniteLoadingProps) => {
  const observer = useRef<any>(null)
  const ref = useRef(null)
  useEffect(() => {
    const element = ref.current
    if (element) {
      observer.current = createObserve(onNext)
      observer.current?.observe(element)
    }
    return () => {
      if (element) {
        observer.current?.unobserve(element)
      }
    }
  }, [ref])

  return (
    <div className={`${className || ''} loading-container`} ref={ref}>
      <Loading
        {...{
          isLoading,
          loadingText,
          iconSize,
          showLoadingBackground: false,
          ...props
        }}
      />
      <style jsx>{`
        .loading-container {
          position: relative;
          padding-top: 8px;
          padding-bottom: 8px;
        }
      `}</style>
    </div>
  )
}

const createObserve = (fn: any) => {
  const options = {
    root: null,
    rootMargin: '0px',
    threshold: 0
  }
  let prevRatio = 0
  let direction = 'up'
  function handleIntersection(entries: IntersectionObserverEntry[], _observer: IntersectionObserver) {
    entries.forEach((entry) => {
      if (entry.intersectionRatio > prevRatio) {
        console.log('go down')
        direction = 'down'
      } else {
        console.log('go up')
        direction = 'up'
      }
      prevRatio = entry.intersectionRatio
    })
    if (direction == 'down') {
      fn?.()
    }
  }
  return new IntersectionObserver(handleIntersection, options)
}
