import { IButtonStyles, memoizeFunction, mergeStyleSets } from '@fluentui/react'

import { CrActionButtonProps } from './cr-action-button'

export const baseActionButtonStyles: IButtonStyles = {
  root: {
    border: 'none',
    background: 'none',
    color: 'var(--gray-8)',
    fontFamily: 'var(--font)',
    fontWeight: 600,
    height: 'auto',
    padding: 8
  },
  rootHovered: {
    background: 'none',
    color: 'var(--secondary-100)'
  },
  rootPressed: {
    background: 'none',
    color: 'var(--secondary-100)'
  },
  label: {
    lineHeight: 40,
    fontSize: 16
  },
  icon: {
    fontSize: 24,
    lineHeight: 24,
    height: 'unset'
  }
}

export const iconLeftActionButtonStyles: IButtonStyles = mergeStyleSets(baseActionButtonStyles, {
  flexContainer: {
    flexDirection: 'row'
  }
})

export const iconRightActionButtonStyles: IButtonStyles = mergeStyleSets(baseActionButtonStyles, {
  flexContainer: {
    flexDirection: 'row-reverse'
  }
})

const getTypeStyles = (props: CrActionButtonProps): IButtonStyles => {
  switch (props.type) {
    case 'iconLeft':
      return iconLeftActionButtonStyles
    case 'iconRight':
      return iconRightActionButtonStyles
    default:
      return iconLeftActionButtonStyles
  }
}

const defaultSizeStyles: IButtonStyles = {
  label: {
    lineHeight: 40,
    fontSize: 16
  }
}

const smallSizeStyles: IButtonStyles = {
  label: {
    lineHeight: 24,
    fontSize: 13,
    fontWeight: 400
  },
  icon: {
    fontSize: 20,
    lineHeight: 20
  }
}

const getSizeStyles = (props: CrActionButtonProps): IButtonStyles => {
  switch (props.sizing) {
    case 'small':
      return smallSizeStyles
    case 'medium':
      return defaultSizeStyles
    default:
      return defaultSizeStyles
  }
}

export const getCrButtonStyles = memoizeFunction(
  (props: CrActionButtonProps): IButtonStyles => {
    return mergeStyleSets(getTypeStyles(props), getSizeStyles(props))
  }
)
