import { Column, ColumnProps } from 'primereact/column'
import { DataTableProps } from 'primereact/datatable'

import OrderDetail from './OrderDetail'
import css from 'styled-jsx/css'
import { useRef } from 'react'
import { DataTable } from 'com-ui2/controls'

interface CardDomainProps extends DataTableProps {
  columns: ColumnProps[]
  data?: Array<object>
}
export const OrderTable = ({ data, columns, ...props }: CardDomainProps) => {
  const modalRef = useRef<any>()

  return (
    <div className="d-flex">
      <DataTable
        value={data || []}
        columnResizeMode="fit"
        scrollable
        lazy
        className="order-table"
        {...props}
        onRowClick={(row) => {
          modalRef.current?.openModal(row?.data?.id)
        }}
      >
        {columns.map((col: any, i: number) => {
          return <Column key={`${col.field}-${i}`} {...col} />
        })}
      </DataTable>
      <OrderDetail ref={modalRef}></OrderDetail>
      <style jsx>{style}</style>
    </div>
  )
}

const style = css``
