import { Callout, Icon, SmartAvatar } from 'com-ui2/components'
import { useEffect, useState } from 'react'

import { commonShared } from 'com-ui/utilities/iconPath'
import getConfig from 'next/config'
import { getProfileState } from 'com-ui-biz/profile/stores/store'
import { useSelector } from 'react-redux'
import { getPublisherState } from 'src/containers/settings/store'
import { useRouter } from 'next/router'

export const LogoUsername = () => {
  const router = useRouter()
  const [logoutUrl, setLogoutUrl] = useState<string>('')

  useEffect(() => {
    const urlSearch = `return_url=${encodeURIComponent(`${window.location.pathname}${window.location.search}`)}`
    const loginUrl = encodeURIComponent(`${window.location.origin}/login?${urlSearch}`)
    const _url = `${getConfig().publicRuntimeConfig.apiBasePath}auth/logout?redirect_uri=${loginUrl}`
    setLogoutUrl(_url)
  }, [router.asPath])
  const userName = `${useSelector(getPublisherState)?.first_name || ''} ${
    useSelector(getPublisherState)?.last_name || ''
  }`
  const userProfile = useSelector(getProfileState)
  const publisherDetail = useSelector(getPublisherState)

  return (
    <div className="d-flex align-items-center ml-auto">
      <div className="team-badge__wrapper">
        <style jsx>{`
          .team-badge__team {
            background-color: var(--primary-30);
            border-radius: 4px;
          }

          .team-badge__name {
            text-align: end;
          }
        `}</style>
        {userName && <div className="mr-3 body-2 text--gray team-badge__name">{userName}</div>}
        {publisherDetail?.team && (
          <div className="mr-3 body-2 text--primary text-end px-2 team-badge__team">{publisherDetail?.team}</div>
        )}
      </div>
      <Callout
        calloutContent={
          <div className="">
            <a href={logoutUrl} className="dropdown-link menu-item">
              <Icon src={commonShared('turn-off')} size="18px" className="mr-2" />
              <span>Log out</span>
            </a>
            <style jsx>{`
              .menu-item {
                padding: 8px 16px;
                display: flex;
                align-items: center;
                &:hover {
                  background-color: var(--indigo-1);
                }
              }
            `}</style>
          </div>
        }
      >
        <SmartAvatar src={userProfile?.avatar} name={userName} />
      </Callout>
    </div>
  )
}
