import { Axios } from 'com-ui2/utilities'
import getConfig from 'next/config'
import { serviceOptions } from './cms'

const instance = Axios({
  baseURL: getConfig().publicRuntimeConfig.apiBasePath || '',
  timeout: 600000,
  withCredentials: true
})

serviceOptions.axios = instance

export * from './cms'
