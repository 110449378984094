import { collapseRouter, config, getCmsRoutes } from 'src/helpers'
import { useContext, useEffect } from 'react'

import { Icon } from 'com-ui2/components/svg/Icon'
import Link from 'next/link'
import { commonShared } from 'com-ui/utilities/iconPath'
import css from 'styled-jsx/css'
import { sidebarContext } from 'src/layout/cms.layout'
import { useRouter } from 'next/router'

export const Sidebar = () => {
  const router = useRouter()
  const isActiveLink = (link: string) => router.pathname.startsWith(link)
  const isCollapse = collapseRouter.includes(router.pathname) || false
  const { active, setActive } = useContext(sidebarContext)

  useEffect(() => {
    setActive(false)
  }, [router])

  return (
    <div className={`sidebar ${active ? 'active' : ''} ${isCollapse ? 'sidebar-collapse' : ''}`}>
      <div className="logo">
        <figure className="image">
          <img
            src={`${config.cdnBasePath}t/c/i/${isCollapse ? 'selless-logo-short.svg' : 'selless-logo-black.svg'}`}
            alt="selless logo"
          />
        </figure>
      </div>
      <div className="line"></div>
      <div className="menu">
        {getCmsRoutes().map((route, index) => (
          <div className={`menu__item ${isActiveLink(route.href) ? 'active' : ''}`} key={index}>
            <Link href={route.href ?? ''} as={route.as}>
              <a className="item">
                {route.icon && <Icon src={commonShared(`${route.icon}`)} fill="var(--d-70)" className="icon" />}
                <div className="item__text">{route.text}</div>
              </a>
            </Link>
          </div>
        ))}
      </div>
      <style jsx>{style}</style>
    </div>
  )
}

const style = css`
  .sidebar {
    overflow: hidden;
    z-index: 100;
    flex: 1;
    background-color: var(--white-100);
    box-shadow: 0px 3.2px 7.2px rgba(101, 115, 129, 0.13);
    position: fixed;
    left: 0;
    min-height: 100vh;
    height: 100%;
    width: 224px;
    padding-top: 20px;
    transition: 0.3s;

    &.sidebar-collapse {
      width: 50px;
      .item__text {
        display: none;
      }
      .logo {
        padding-left: 11px;
      }

      .menu {
        &__item {
          .item {
            padding: 14px;
          }

          /* &:not(:last-child) {
          margin-bottom: 24px;
        } */

          :global(.icon) {
            margin-right: 0;
            position: relative;
          }
          &:hover {
            .item {
              color: var(--indigo-9);
              background: var(--indigo-2);
            }
            :global(.icon) {
              fill: var(--indigo-5);
            }
          }
          &.active {
            border-right: 3px solid var(--primary-100);
            .item {
              color: var(--indigo-9);
              background: var(--indigo-1);
            }
            :global(.icon) {
              fill: var(--primary-50);
            }
          }
        }
      }
    }

    .logo {
      margin-bottom: 20px;
      padding-left: 24px;
      .image {
        margin: 0;
        padding: 0;
      }

      img {
        height: 32px;
        width: unset;
      }
    }

    :global(.icon) {
      fill: var(--indigo-4);
    }
    .line {
      border: 1px solid var(--indigo-3);
      margin-bottom: 23px;
    }
    .menu {
      &__item {
        .item {
          color: var(--gray-8);
          display: flex;
          font-family: var(--font-header);
          font-weight: 600;
          font-size: 14px;
          line-height: 24px;
          padding: 12px 0 12px 24px;
        }

        /* &:not(:last-child) {
          margin-bottom: 24px;
        } */

        :global(.icon) {
          margin-right: 18px;
          position: relative;
        }
        &:hover {
          .item {
            color: var(--indigo-9);
            background: var(--indigo-2);
          }
          :global(.icon) {
            fill: var(--indigo-5);
          }
        }
        &.active {
          border-right: 3px solid var(--primary-100);
          .item {
            color: var(--indigo-9);
            background: var(--indigo-1);
          }
          :global(.icon) {
            fill: var(--primary-50);
          }
        }
      }
    }

    &.collapsed {
      .item {
        height: 32px;
        :global(.icon) {
          left: 4px;
        }
      }
      .item__text {
        display: none;
      }
    }
  }
`
