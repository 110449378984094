/** Generate by swagger-axios-codegen */
// @ts-ignore
/* eslint-disable */

/** Generate by swagger-axios-codegen */
/* eslint-disable */
// @ts-ignore
import axiosStatic, { AxiosInstance, AxiosRequestConfig } from 'axios';

export interface IRequestOptions extends AxiosRequestConfig {}

export interface IRequestConfig {
  method?: any;
  headers?: any;
  url?: any;
  data?: any;
  params?: any;
}

// Add options interface
export interface ServiceOptions {
  axios?: AxiosInstance;
}

// Add default options
export const serviceOptions: ServiceOptions = {};

// Instance selector
export function axios(configs: IRequestConfig, resolve: (p: any) => void, reject: (p: any) => void): Promise<any> {
  if (serviceOptions.axios) {
    return serviceOptions.axios
      .request(configs)
      .then(res => {
        resolve(res.data);
      })
      .catch(err => {
        reject(err);
      });
  } else {
    throw new Error('please inject yourself instance like axios  ');
  }
}

export function getConfigs(method: string, contentType: string, url: string, options: any): IRequestConfig {
  const configs: IRequestConfig = { ...options, method, url };
  configs.headers = {
    ...options.headers,
    'Content-Type': contentType
  };
  return configs;
}

export const basePath = '';

export interface IList<T> extends Array<T> {}
export interface List<T> extends Array<T> {}
export interface IDictionary<TValue> {
  [key: string]: TValue;
}
export interface Dictionary<TValue> extends IDictionary<TValue> {}

export interface IListResult<T> {
  items?: T[];
}

export class ListResultDto<T> implements IListResult<T> {
  items?: T[];
}

export interface IPagedResult<T> extends IListResult<T> {
  totalCount?: number;
  items?: T[];
}

export class PagedResultDto<T> implements IPagedResult<T> {
  totalCount?: number;
  items?: T[];
}

// customer definition
// empty

export class AdManagerService {
  /**
   *
   */
  static search(
    params: {
      /** requestBody */
      body?: AdManagerQuery;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AdManagerViewModelPagedList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/cms/shop/ad_manager/search';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static total(
    params: {
      /** requestBody */
      body?: AdManagerQuery;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AdManagerByProvider> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/cms/shop/ad_manager/total';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static totalOrganic(
    params: {
      /** requestBody */
      body?: AdManagerQuery;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<AdManagerViewModel> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/cms/shop/ad_manager/total-organic';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static adManager(
    params: {
      /**  */
      id: string;
      /**  */
      provider?: AdProviders;
      /** requestBody */
      body?: AdRequestViewModel;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/cms/shop/ad_manager/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);
      configs.params = { provider: params['provider'] };
      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class CmsAdminService {
  /**
   *
   */
  static publisher(
    params: {
      /**  */
      publisher: string;
      /**  */
      domain?: string;
      /**  */
      pageIndex?: number;
      /**  */
      pageSize?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ADSellPageViewInListPagedList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/cms/shop/admin/sellpage/publisher/{publisher}';
      url = url.replace('{publisher}', params['publisher'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { domain: params['domain'], page_index: params['pageIndex'], page_size: params['pageSize'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static fee(
    params: {
      /**  */
      id: string;
      /** requestBody */
      body?: ADUpdateSellPage;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ADSellPageViewInList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/cms/shop/admin/sellpage/fee/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static performance(
    params: {
      /**  */
      publisher: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ad_publisher_performance_view> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/cms/shop/admin/sellpage/order/{publisher}/performance';
      url = url.replace('{publisher}', params['publisher'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static list(
    params: {
      /**  */
      publisher: string;
      /**  */
      from?: string;
      /**  */
      to?: string;
      /**  */
      domain?: string;
      /**  */
      pageIndex?: number;
      /**  */
      pageSize?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<po_viewPagedList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/cms/shop/admin/sellpage/order/{publisher}/list';
      url = url.replace('{publisher}', params['publisher'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        from: params['from'],
        to: params['to'],
        domain: params['domain'],
        page_index: params['pageIndex'],
        page_size: params['pageSize']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static order(
    params: {
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<po_view> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/cms/shop/admin/sellpage/order/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class DomainService {
  /**
   *
   */
  static willexpired(options: IRequestOptions = {}): Promise<DomainViewModel[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/cms/shop/domains/willexpired';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class FacebookService {
  /**
   *
   */
  static all(options: IRequestOptions = {}): Promise<fb_account_view[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/cms/shop/facebook/account/all';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static add(
    params: {
      /** requestBody */
      body?: TokenFacebookAddRQ;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/cms/shop/facebook/account/add';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static disconnect(
    params: {
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/cms/shop/facebook/account/{id}/disconnect';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static reconnect(
    params: {
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/cms/shop/facebook/account/{id}/reconnect';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class FileUploadService {
  /**
   *
   */
  static sellpage(
    params: {
      /**  */
      id?: string;
      /**  */
      uploadType?: UploadType;
      /**  */
      files: [];
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<string[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/cms/shop/media/sellpage';

      const configs: IRequestConfig = getConfigs('post', 'multipart/form-data', url, options);
      configs.params = { id: params['id'], upload_type: params['uploadType'] };
      let data = null;
      data = new FormData();
      if (params['files']) {
        if (Object.prototype.toString.call(params['files']) === '[object Array]') {
          for (const item of params['files']) {
            data.append('files', item as any);
          }
        } else {
          data.append('files', params['files'] as any);
        }
      }

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static personalDocument(
    params: {
      /**  */
      id?: string;
      /**  */
      file: any;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<string> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/cms/shop/media/personal-document';

      const configs: IRequestConfig = getConfigs('post', 'multipart/form-data', url, options);
      configs.params = { id: params['id'] };
      let data = null;
      data = new FormData();
      if (params['file']) {
        if (Object.prototype.toString.call(params['file']) === '[object Array]') {
          for (const item of params['file']) {
            data.append('file', item as any);
          }
        } else {
          data.append('file', params['file'] as any);
        }
      }

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class FinanceService {
  /**
   *
   */
  static cutoff(
    params: {
      /**  */
      from?: string;
      /**  */
      to?: string;
      /**  */
      pageIndex?: number;
      /**  */
      pageSize?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<FinanceCutoffViewPagedList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/cms/shop/finance/cutoff';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        from: params['from'],
        to: params['to'],
        page_index: params['pageIndex'],
        page_size: params['pageSize']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class JwtService {
  /**
   *
   */
  static jwt(
    params: {
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<string> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/cms/shop/jwt/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class NotificationService {
  /**
   *
   */
  static clearanceWarning(options: IRequestOptions = {}): Promise<ClearanceWarningResponseModel> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/cms/shop/noti/clearance_warning';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static clearanceWarning1(options: IRequestOptions = {}): Promise<ClearanceWarningResponseModel> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/cms/shop/v2/noti/clearance_warning';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class OrdersService {
  /**
   *
   */
  static sellpage(
    params: {
      /** sellpage_id */
      id?: string;
      /**  */
      from?: string;
      /**  */
      to?: string;
      /**  */
      pageIndex?: number;
      /**  */
      pageSize?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<po_viewPagedList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/cms/shop/order/sellpage';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        id: params['id'],
        from: params['from'],
        to: params['to'],
        page_index: params['pageIndex'],
        page_size: params['pageSize']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static order(
    params: {
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<po_view> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/cms/shop/order/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static publisher(
    params: {
      /**  */
      from?: string;
      /**  */
      to?: string;
      /**  */
      productId?: string;
      /**  */
      pageId?: string;
      /**  */
      pageIndex?: number;
      /**  */
      pageSize?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<po_viewPagedList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/cms/shop/order/publisher';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        from: params['from'],
        to: params['to'],
        product_id: params['productId'],
        page_id: params['pageId'],
        page_index: params['pageIndex'],
        page_size: params['pageSize']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static performance(
    params: {
      /**  */
      from?: string;
      /**  */
      to?: string;
      /** page_ids */
      ids?: string;
      /** ppage_ids */
      ppageIds?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<performance_view> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/cms/shop/order/performance';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { from: params['from'], to: params['to'], ids: params['ids'], ppage_ids: params['ppageIds'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static newperformance(
    params: {
      /**  */
      inputUserId?: string;
      /**  */
      from?: string;
      /**  */
      to?: string;
      /** page_ids */
      ids?: string;
      /** ppage_ids */
      ppageIds?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<performance_view> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/cms/shop/order/newperformance';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        inputUserId: params['inputUserId'],
        from: params['from'],
        to: params['to'],
        ids: params['ids'],
        ppage_ids: params['ppageIds']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class ProductService {
  /**
   *
   */
  static list(
    params: {
      /**  */
      searchType?: ProductSearchType;
      /**  */
      search?: string;
      /**  */
      pageIndex?: number;
      /**  */
      pageSize?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ProductViewInListPagedList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/cms/shop/product/list';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        searchType: params['searchType'],
        search: params['search'],
        page_index: params['pageIndex'],
        page_size: params['pageSize']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static optionProduct(
    params: {
      /**  */
      pageIndex?: number;
      /**  */
      pageSize?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ProductViewInListPagedList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/cms/shop/product/option_product';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { page_index: params['pageIndex'], page_size: params['pageSize'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static product(
    params: {
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PPagePublished> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/cms/shop/product/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class ProductRequestService {
  /**
   *
   */
  static create(
    params: {
      /** requestBody */
      body?: ProductRequestRQ;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ProductRequest> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/cms/shop/product-request/create';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static get(
    params: {
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ProductRequest> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/cms/shop/product-request/get/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static all(options: IRequestOptions = {}): Promise<ProductRequest[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/cms/shop/product-request/search/all';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class PublicService {
  /**
   * get  latest condition
   */
  static condition(options: IRequestOptions = {}): Promise<ConditionInfo> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/cms/shop/public/condition';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Check email available
   */
  static checkEmail(
    params: {
      /**  */
      email: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<string> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/cms/shop/public/{email}/check_email';
      url = url.replace('{email}', params['email'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static register(
    params: {
      /** requestBody */
      body?: PublisherRegister;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<string> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/cms/shop/public/register';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static publisherInfo(options: IRequestOptions = {}): Promise<PublisherInfo> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/cms/shop/public/publisher_info';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Update Info
   */
  static updateInfo(
    params: {
      /** requestBody */
      body?: PublisherUpdateInfo;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/cms/shop/public/update_info';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class PublisherService {
  /**
   * change password
   */
  static changePassword(
    params: {
      /** Publisher ID */
      id: string;
      /** requestBody */
      body?: PublisherChangePassword;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/cms/shop/publisher/{id}/change_password';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Update Payout
   */
  static updatePayout(
    params: {
      /** Publisher ID */
      id: string;
      /** requestBody */
      body?: PublisherUpdatePayout;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/cms/shop/publisher/{id}/update_payout';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * Update Info
   */
  static updateInfo(
    params: {
      /** Publisher ID */
      id: string;
      /** requestBody */
      body?: PublisherUpdateInfo;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/cms/shop/publisher/{id}/update_info';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class SellPageService {
  /**
   *
   */
  static sellpage(
    params: {
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SellPageViewInDetail> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/cms/shop/sellpage/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static sellpage1(
    params: {
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/cms/shop/sellpage/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static all(options: IRequestOptions = {}): Promise<DropDownListModel[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/cms/shop/sellpage/all';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static range(
    params: {
      /**  */
      from?: string;
      /**  */
      to?: string;
      /**  */
      ids?: string;
      /**  */
      pageIndex?: number;
      /**  */
      pageSize?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SellPageViewInListPagedList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/cms/shop/sellpage/range';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        from: params['from'],
        to: params['to'],
        ids: params['ids'],
        page_index: params['pageIndex'],
        page_size: params['pageSize']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static sellpage2(
    params: {
      /**  */
      productPageId: string;
      /** requestBody */
      body?: CreateSellPage;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<string> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/cms/shop/sellpage/{product_page_id}';
      url = url.replace('{product_page_id}', params['productPageId'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static update(
    params: {
      /**  */
      id: string;
      /** requestBody */
      body?: UpdateSellPage;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SellPageViewInDetail> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/cms/shop/sellpage/{id}/update';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static setPrimaryDomain(
    params: {
      /**  */
      id: string;
      /**  */
      domainId?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SellessDomain[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/cms/shop/sellpage/{id}/set-primary-domain';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);
      configs.params = { domain_id: params['domainId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static list(options: IRequestOptions = {}): Promise<string[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/cms/shop/sellpage/domain/primary/list';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static publishing(
    params: {
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/cms/shop/sellpage/{id}/publishing';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static upgratedMultistore(
    params: {
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/cms/shop/sellpage/{id}/upgrated-multistore';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static list1(
    params: {
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<DropDownListModel[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/cms/shop/sellpage/{id}/domain/publisher/list';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static connect(
    params: {
      /**  */
      id: string;
      /**  */
      domainId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SellessDomain[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/cms/shop/sellpage/{id}/domain/{domain_id}/connect';
      url = url.replace('{id}', params['id'] + '');
      url = url.replace('{domain_id}', params['domainId'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static remove(
    params: {
      /**  */
      id: string;
      /**  */
      domainId: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SellessDomain[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/cms/shop/sellpage/{id}/domain/{domain_id}/remove';
      url = url.replace('{id}', params['id'] + '');
      url = url.replace('{domain_id}', params['domainId'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static existingAnotherContentId(
    params: {
      /**  */
      id: string;
      /**  */
      contentId?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<boolean> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/cms/shop/sellpage/{id}/existing-another-content-id';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = { contentId: params['contentId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static all1(options: IRequestOptions = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/cms/shop/sellpage/publishing/all';

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static all2(
    params: {
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/cms/shop/sellpage/publishing/{id}/all';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   * return cms admin config address
   */
  static cmsConfig(options: IRequestOptions = {}): Promise<admin_config> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/cms/shop/sellpage/cms-config';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static ggShopFail(options: IRequestOptions = {}): Promise<DropDownListModel[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/cms/shop/sellpage/gg-shop-fail';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static sellpage3(
    params: {
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SellPageV2ViewInDetail> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/cms/shop/v2/sellpage/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static sellpage4(
    params: {
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/cms/shop/v2/sellpage/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('delete', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static sellpage5(
    params: {
      /** requestBody */
      body?: CreateSellPageV2;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<string> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/cms/shop/v2/sellpage';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static updateGeneralConfig(
    params: {
      /**  */
      id: string;
      /** requestBody */
      body?: UpdateSellPageV2;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SellPageV2ViewInDetail> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/cms/shop/v2/sellpage/{id}/update-general-config';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static publishingGeneralConfig(
    params: {
      /**  */
      id: string;
      /** requestBody */
      body?: UpdateSellPageV2;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SellPageV2ViewInDetail> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/cms/shop/v2/sellpage/{id}/publishing-general-config';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static publishingTrafficConfig(
    params: {
      /**  */
      id: string;
      /** requestBody */
      body?: UpdateTrafficConfigSellpageV2;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<ResponseTrafficConfigSellpageV2> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/cms/shop/v2/sellpage/{id}/publishing-traffic-config';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static upgratedMultistore1(
    params: {
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<any> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/cms/shop/v2/sellpage/{id}/upgrated-multistore';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static setPrimaryDomain1(
    params: {
      /**  */
      id: string;
      /**  */
      domainId?: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SellessDomain[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/cms/shop/v2/sellpage/{id}/set-primary-domain';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('put', 'application/json', url, options);
      configs.params = { domain_id: params['domainId'] };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static versionOptionsCreate(
    params: {
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SellpageVersionsAddResponseView> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/cms/shop/v2/sellpage/{id}/version-options-create';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static versionsStat(
    params: {
      /**  */
      id: string;
      /** requestBody */
      body?: PageVersionManagerQuery;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<PageVersionStatViewModel[]> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/cms/shop/v2/sellpage/{id}/versions-stat';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static range1(
    params: {
      /**  */
      from?: string;
      /**  */
      to?: string;
      /**  */
      ids?: string;
      /**  */
      pageIndex?: number;
      /**  */
      pageSize?: number;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SellPageViewInListPagedList> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/cms/shop/v2/sellpage/range';

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);
      configs.params = {
        from: params['from'],
        to: params['to'],
        ids: params['ids'],
        page_index: params['pageIndex'],
        page_size: params['pageSize']
      };
      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export class SellPageVersionService {
  /**
   *
   */
  static sellpageVersion(
    params: {
      /**  */
      id: string;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SellPageVersionViewInDetail> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/cms/shop/v2/sellpage-version/{id}';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('get', 'application/json', url, options);

      let data = null;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static sellpageVersion1(
    params: {
      /** requestBody */
      body?: CreateSellPageVersion;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<string> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/cms/shop/v2/sellpage-version';

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static updateVersion(
    params: {
      /**  */
      id: string;
      /** requestBody */
      body?: UpdateSellPageVersion;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SellPageVersionViewInDetail> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/cms/shop/v2/sellpage-version/{id}/update-version';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
  /**
   *
   */
  static publishingVersion(
    params: {
      /**  */
      id: string;
      /** requestBody */
      body?: UpdateSellPageVersion;
    } = {} as any,
    options: IRequestOptions = {}
  ): Promise<SellPageVersionViewInDetail> {
    return new Promise((resolve, reject) => {
      let url = basePath + '/cms/shop/v2/sellpage-version/{id}/publishing-version';
      url = url.replace('{id}', params['id'] + '');

      const configs: IRequestConfig = getConfigs('post', 'application/json', url, options);

      let data = params.body;

      configs.data = data;
      axios(configs, resolve, reject);
    });
  }
}

export interface ADSellPageViewInList {
  /**  */
  id?: string;

  /**  */
  is_multil_store?: boolean;

  /**  */
  pbase_title?: string;

  /**  */
  pbase_label?: string;

  /**  */
  page_note?: string;

  /**  */
  images?: string[];

  /**  */
  domain?: string;

  /**  */
  default_domain?: string;

  /**  */
  amount_fee?: number;

  /**  */
  percentage_fee?: number;

  /**  */
  cash_in_balance?: number;

  /**  */
  status?: SellPageStatus;

  /**  */
  slug?: string;

  /**  */
  created_at?: Date;

  /**  */
  updated_at?: Date;
}

export interface ADSellPageViewInListPagedList {
  /**  */
  data?: ADSellPageViewInList[];

  /**  */
  page_index?: number;

  /**  */
  page_size?: number;

  /**  */
  total_count?: number;

  /**  */
  total_pages?: number;

  /**  */
  has_previous_page?: boolean;

  /**  */
  has_next_page?: boolean;
}

export interface ADUpdateSellPage {
  /**  */
  amount_fee?: number;

  /**  */
  percentage_fee?: number;
}

export interface AdManagerByProvider {
  /**  */
  organic?: AdManagerViewModel;

  /**  */
  facebook?: AdManagerViewModel;

  /**  */
  pinterest?: AdManagerViewModel;

  /**  */
  google?: AdManagerViewModel;
}

export interface AdManagerQuery {
  /**  */
  sell_page?: string[];

  /**  */
  campaign_ids?: string[];

  /**  */
  adset_ids?: string[];

  /**  */
  ad_ids?: string[];

  /**  */
  campaign_status?: FbAdEntityStatus[];

  /**  */
  adset_status?: FbAdEntityStatus[];

  /**  */
  ad_status?: FbAdEntityStatus[];

  /**  */
  group_by?: GroupBy;

  /**  */
  from?: string;

  /**  */
  to?: string;

  /**  */
  page_index?: number;

  /**  */
  page_size?: number;

  /**  */
  provider?: AdProviders[];
}

export interface AdManagerViewModel {
  /**  */
  campaign_id?: string;

  /**  */
  campaign_name?: string;

  /**  */
  account_id?: string;

  /**  */
  campaign_status?: FbAdEntityStatus;

  /**  */
  adset_id?: string;

  /**  */
  adset_name?: string;

  /**  */
  adset_status?: FbAdEntityStatus;

  /**  */
  ad_id?: string;

  /**  */
  ad_name?: string;

  /**  */
  ad_status?: string;

  /**  */
  daily_budget?: number;

  /**  */
  budget_type?: BudgetType;

  /**  */
  budget_level?: BudgetLevel;

  /**  */
  spent?: number;

  /**  */
  revenue?: number;

  /**  */
  impressions?: number;

  /**  */
  click?: number;

  /**  */
  view?: number;

  /**  */
  cpp?: number;

  /**  */
  add_to_cart?: number;

  /**  */
  currency?: string;

  /**  */
  currency_rate?: number;

  /**  */
  currency_lastupdated?: Date;

  /**  */
  checkout?: number;

  /**  */
  init_checkout?: number;

  /**  */
  purchase?: number;

  /**  */
  cr?: number;

  /**  */
  cp_cart?: number;

  /**  */
  cp_view?: number;

  /**  */
  cp_checkout?: number;

  /**  */
  cp_purchase?: number;

  /**  */
  cpm?: number;

  /**  */
  roas?: number;

  /**  */
  ctr?: number;

  /**  */
  cpc?: number;

  /**  */
  cr1?: number;

  /**  */
  cr2?: number;
}

export interface AdManagerViewModelPagedList {
  /**  */
  data?: AdManagerViewModel[];

  /**  */
  page_index?: number;

  /**  */
  page_size?: number;

  /**  */
  total_count?: number;

  /**  */
  total_pages?: number;

  /**  */
  has_previous_page?: boolean;

  /**  */
  has_next_page?: boolean;
}

export interface AdRequestViewModel {
  /**  */
  id?: string;

  /**  */
  type?: UpdateType;

  /**  */
  daily_budget?: number;

  /**  */
  lifetime_budget?: number;

  /**  */
  end_time?: Date;

  /**  */
  status?: FbAdEntityStatus;
}

export interface ClearanceRatioWarningModel {
  /**  */
  store_domain?: string;

  /**  */
  clearance_page_domains?: string[];

  /**  */
  presentation_page_domains?: string[];
}

export interface ClearanceWarningResponseModel {
  /**  */
  sale_warning?: SaleSellpageWarningModel[];

  /**  */
  clearance_ratio_warning?: ClearanceRatioWarningModel[];
}

export interface ConditionInfo {
  /**  */
  end_date?: Date;

  /**  */
  content?: string;

  /**  */
  status?: condition_status;

  /**  */
  created: Date;

  /**  */
  created_by?: string;

  /**  */
  updated: Date;

  /**  */
  updated_by?: string;

  /**  */
  id?: string;
}

export interface CreateSellPage {
  /**  */
  domain_name?: string;

  /**  */
  create_type?: sell_page_create_type;
}

export interface CreateSellPageV2 {
  /**  */
  product_page_id?: string;

  /**  */
  domain_name?: string;

  /**  */
  create_type?: sell_page_create_type;
}

export interface CreateSellPageVersion {
  /**  */
  sellpage_id?: string;

  /**  */
  product_page_id?: string;
}

export interface DomainViewModel {
  /**  */
  sellpage_id?: string;

  /**  */
  is_multil_store?: boolean;

  /**  */
  renewalPrice?: number;

  /**  */
  name?: string;

  /**  */
  store_id?: string;

  /**  */
  paid?: Date;

  /**  */
  status: domain_status;

  /**  */
  expired?: Date;

  /**  */
  auto_renew?: boolean;

  /**  */
  dns_date?: Date;

  /**  */
  price?: number;

  /**  */
  annual_fee?: number;

  /**  */
  paid_amount?: number;

  /**  */
  publisher_id?: string;

  /**  */
  spend_id?: string;

  /**  */
  last_order_code?: string;

  /**  */
  last_order_date?: Date;

  /**  */
  last_access_date?: Date;

  /**  */
  last_access_url?: string;

  /**  */
  renew_action?: domain_renew_action;

  /**  */
  created: Date;

  /**  */
  created_by?: string;

  /**  */
  updated: Date;

  /**  */
  updated_by?: string;

  /**  */
  id?: string;
}

export interface DropDownListModel {
  /**  */
  id?: string;

  /**  */
  value?: string;
}

export interface FaceBookContent {
  /**  */
  primary_text?: string;

  /**  */
  headline?: string;

  /**  */
  description?: string;

  /**  */
  thumbnail?: string;

  /**  */
  video?: string;
}

export interface FinanceCutoffView {
  /**  */
  earned?: number;

  /**  */
  publisher_id?: string;

  /**  */
  revenue?: number;

  /**  */
  cost?: number;

  /**  */
  tax_amount?: number;

  /**  */
  hold?: number;

  /**  */
  unhold?: number;

  /**  */
  spent?: number;

  /**  */
  payout_processing?: number;

  /**  */
  payout_approved?: number;

  /**  */
  payout_rejected?: number;

  /**  */
  referral_earn?: number;

  /**  */
  cash_to_balance?: number;

  /**  */
  balance?: number;

  /**  */
  created?: Date;

  /**  */
  updated?: Date;

  /**  */
  time?: Date;

  /**  */
  id?: string;
}

export interface FinanceCutoffViewPagedList {
  /**  */
  data?: FinanceCutoffView[];

  /**  */
  page_index?: number;

  /**  */
  page_size?: number;

  /**  */
  total_count?: number;

  /**  */
  total_pages?: number;

  /**  */
  has_previous_page?: boolean;

  /**  */
  has_next_page?: boolean;
}

export interface GoogleMerchant {
  /**  */
  status?: GoogleMerchantStatus;

  /**  */
  authentication?: string;

  /**  */
  merchant_id?: number;
}

export interface OptionValue {
  /**  */
  property_name?: string;

  /**  */
  option_property_name?: string;

  /**  */
  mappings?: ValueMapping[];

  /**  */
  sorts?: SortMapping[];

  /**  */
  sequence?: number;
}

export interface PPageAssignee {
  /**  */
  id?: string;

  /**  */
  assign_type?: AssignType;

  /**  */
  publishers?: Publisher[];
}

export interface PPagePublished {
  /**  */
  pbase_id?: string;

  /**  */
  page_note?: string;

  /**  */
  pbase_title?: string;

  /**  */
  pbase_label?: string;

  /**  */
  amount_fee?: number;

  /**  */
  percentage_fee?: number;

  /**  */
  announcement_bar?: string;

  /**  */
  top_description?: string;

  /**  */
  short_description?: string;

  /**  */
  middle_description?: string;

  /**  */
  bottom_description?: string;

  /**  */
  shipping_policy?: string;

  /**  */
  qa_policy?: string;

  /**  */
  return_policy?: string;

  /**  */
  images?: string[];

  /**  */
  shipping_name?: string;

  /**  */
  shippings?: Shipping[];

  /**  */
  facebook_content?: FaceBookContent;

  /**  */
  option_values?: OptionValue[];

  /**  */
  variants?: PPageVariant[];

  /**  */
  reviews?: Review[];

  /**  */
  upsell?: boolean;

  /**  */
  upsell_info?: UpsellSetting;

  /**  */
  setting?: RatingSetting;

  /**  */
  published_date?: Date;

  /**  */
  assignee?: PPageAssignee;

  /**  */
  version?: number;

  /**  */
  yesterday_performance?: Performance;

  /**  */
  product_version_status?: string;

  /**  */
  owner_email?: string;

  /**  */
  owner_name?: string;

  /**  */
  graphic_designer_email?: string;

  /**  */
  graphic_designer_name?: string;

  /**  */
  video_editor_email?: string;

  /**  */
  video_editor_name?: string;

  /**  */
  id?: string;

  /**  */
  created_by?: string;

  /**  */
  updated_by?: string;

  /**  */
  created_at?: Date;

  /**  */
  updated_at?: Date;
}

export interface PPageVariant {
  /**  */
  id?: string;

  /**  */
  pbase_id?: string;

  /**  */
  pbase_variant_id?: string;

  /**  */
  image?: string;

  /**  */
  compare_price?: number;

  /**  */
  default_price?: number;

  /**  */
  product_cost?: number;

  /**  */
  packaging_cost?: number;

  /**  */
  shipping_cost?: number;

  /**  */
  properties?: PropertyVariant[];
}

export interface PageVersionManagerQuery {
  /**  */
  from?: string;

  /**  */
  to?: string;

  /**  */
  providers?: ProviderAdSources[];
}

export interface PageVersionStatViewModel {
  /**  */
  id?: string;

  /**  */
  page_id?: string;

  /**  */
  first_image?: string;

  /**  */
  product_label?: string;

  /**  */
  version_number?: number;

  /**  */
  version_status?: SellPageVersionStatus;

  /**  */
  version_note?: string;

  /**  */
  page_note?: string;

  /**  */
  is_active_version?: boolean;

  /**  */
  revenue?: number;

  /**  */
  view?: number;

  /**  */
  add_to_cart?: number;

  /**  */
  init_checkout?: number;

  /**  */
  you_take?: number;

  /**  */
  tax_amount?: number;

  /**  */
  purchase?: number;

  /**  */
  cr?: number;

  /**  */
  cr1?: number;

  /**  */
  cr2?: number;

  /**  */
  aov?: number;

  /**  */
  view_revenue?: number;

  /**  */
  net_revenue?: number;

  /**  */
  view_value?: number;
}

export interface Performance {
  /**  */
  date?: Date;

  /**  */
  revenue?: number;

  /**  */
  orders?: number;

  /**  */
  cash_to_balance?: number;
}

export interface ProblemDetails {
  /**  */
  type?: string;

  /**  */
  title?: string;

  /**  */
  status?: number;

  /**  */
  detail?: string;

  /**  */
  instance?: string;
}

export interface ProductRequest {
  /**  */
  title?: string;

  /**  */
  links?: string[];

  /**  */
  information?: string;

  /**  */
  image?: string;

  /**  */
  submitted_time?: Date;

  /**  */
  status?: ProductRequestStatus;

  /**  */
  notes?: string;

  /**  */
  reviewer_id?: string;

  /**  */
  seller_name?: string;

  /**  */
  seller_email?: string;

  /**  */
  seller_email_noti?: string;

  /**  */
  id?: string;

  /**  */
  created_by?: string;

  /**  */
  updated_by?: string;

  /**  */
  created_at?: Date;

  /**  */
  updated_at?: Date;
}

export interface ProductRequestRQ {
  /**  */
  links?: string[];

  /**  */
  information?: string;
}

export interface ProductViewInList {
  /**  */
  id?: string;

  /**  */
  pbase_id?: string;

  /**  */
  page_note?: string;

  /**  */
  pbase_title?: string;

  /**  */
  pbase_label?: string;

  /**  */
  images?: string[];

  /**  */
  suggest_price?: number;

  /**  */
  product_cost?: number;

  /**  */
  fulfillment_cost?: number;

  /**  */
  percent_payment_fee?: number;

  /**  */
  fix_payment_fee?: number;

  /**  */
  percent_selless_fee?: number;

  /**  */
  fix_selless_fee?: number;

  /**  */
  shipping_fee?: number;

  /**  */
  facebook_content?: FaceBookContent;

  /**  */
  sell_pages?: number;

  /**  */
  aov?: number;

  /**  */
  base_cost?: number;

  /**  */
  estimated_tax?: number;

  /**  */
  sellable?: boolean;

  /**  */
  available?: boolean;
}

export interface ProductViewInListPagedList {
  /**  */
  data?: ProductViewInList[];

  /**  */
  page_index?: number;

  /**  */
  page_size?: number;

  /**  */
  total_count?: number;

  /**  */
  total_pages?: number;

  /**  */
  has_previous_page?: boolean;

  /**  */
  has_next_page?: boolean;
}

export interface Promotion {
  /**  */
  id?: string;

  /**  */
  code?: string;

  /**  */
  type?: promotion_type;

  /**  */
  value?: number;

  /**  */
  apply?: string;

  /**  */
  to?: string[];

  /**  */
  valid?: number;

  /**  */
  effective?: number;

  /**  */
  priority?: number;

  /**  */
  description?: string;

  /**  */
  title?: string;

  /**  */
  auto?: boolean;
}

export interface PropertyVariant {
  /**  */
  name?: string;

  /**  */
  value?: string;
}

export interface Publisher {
  /**  */
  id?: string;

  /**  */
  email?: string;

  /**  */
  name?: string;
}

export interface PublisherChangePassword {
  /**  */
  old_password: string;

  /**  */
  new_password: string;
}

export interface PublisherInfo {
  /**  */
  id?: string;

  /**  */
  status?: publisher_status;

  /**  */
  type?: string;

  /**  */
  channel?: publisher_channel;

  /**  */
  phone?: string;

  /**  */
  first_name?: string;

  /**  */
  last_name?: string;

  /**  */
  address?: string;

  /**  */
  city?: string;

  /**  */
  zip?: string;

  /**  */
  country?: string;

  /**  */
  timezone?: string;

  /**  */
  passport_no?: string;

  /**  */
  email?: string;

  /**  */
  nmail?: string;

  /**  */
  image_passports?: string[];

  /**  */
  fee_system_percent?: number;

  /**  */
  fee_system_value?: number;

  /**  */
  fee_payment_percent?: number;

  /**  */
  fee_payment_value?: number;

  /**  */
  hold_percent?: number;

  /**  */
  hold_days?: number;

  /**  */
  payout_auto?: boolean;

  /**  */
  payout_paypalex?: string;

  /**  */
  payout_payoneer?: string;

  /**  */
  payout_default?: payout_default;

  /**  */
  experience?: experience;

  /**  */
  investment?: investment;

  /**  */
  personal_document_type?: personal_document_type;

  /**  */
  front_personal_document_image?: string;

  /**  */
  back_personal_document_image?: string;

  /**  */
  own_ecommerce_business?: own_ecommerce_business;

  /**  */
  type_ecommerce_business?: type_ecommerce_business[];

  /**  */
  type_payment?: type_payment[];

  /**  */
  total_order_last_month?: total_order_last_month;

  /**  */
  never_login?: boolean;

  /**  */
  location_ref?: string;

  /**  */
  intercom_user_hash?: string;

  /**  */
  meta_data?: publisher_meta_data;

  /**  */
  team?: string;
}

export interface PublisherRegister {
  /**  */
  email: string;

  /**  */
  password: string;

  /**  */
  phone?: string;

  /**  */
  first_name: string;

  /**  */
  last_name: string;

  /**  */
  address?: string;

  /**  */
  city?: string;

  /**  */
  zip?: string;

  /**  */
  country?: string;

  /**  */
  condition_id?: string;

  /**  */
  timezone?: string;

  /**  */
  experience?: experience;

  /**  */
  investment?: investment;

  /**  */
  own_ecommerce_business?: own_ecommerce_business;

  /**  */
  type_ecommerce_business?: type_ecommerce_business[];

  /**  */
  type_payments?: type_payment[];

  /**  */
  total_order_last_month?: total_order_last_month;

  /**  */
  location_ref?: string;
}

export interface PublisherUpdateInfo {
  /**  */
  id?: string;

  /**  */
  nmail?: string;

  /**  */
  phone?: string;

  /**  */
  first_name: string;

  /**  */
  last_name: string;

  /**  */
  address?: string;

  /**  */
  city?: string;

  /**  */
  zip?: string;

  /**  */
  country?: string;

  /**  */
  timezone?: string;

  /**  */
  passport_no?: string;

  /**  */
  image_passports?: string[];

  /**  */
  personal_document_type?: personal_document_type;

  /**  */
  front_personal_document_image?: string;

  /**  */
  back_personal_document_image?: string;

  /**  */
  meta_data?: publisher_meta_data;
}

export interface PublisherUpdatePayout {
  /**  */
  id: string;

  /**  */
  payout_auto?: boolean;

  /**  */
  payout_paypalex?: string;

  /**  */
  payout_payoneer?: string;

  /**  */
  payout_default?: payout_default;
}

export interface RatingSetting {
  /**  */
  number_female?: number;

  /**  */
  number_male?: number;

  /**  */
  ratio_rating?: number;
}

export interface ResponseTrafficConfigSellpageV2 {
  /**  */
  deactivated?: number[];

  /**  */
  activated?: number[];
}

export interface Review {
  /**  */
  id?: string;

  /**  */
  email?: string;

  /**  */
  name?: string;

  /**  */
  rating?: number;

  /**  */
  title?: string;

  /**  */
  description?: string;

  /**  */
  file_evidences?: string[];

  /**  */
  properties?: PropertyVariant[];
}

export interface SaleSellpageWarningModel {
  /**  */
  id?: string;

  /**  */
  sellpage_domain?: string;
}

export interface SellPageSetting {
  /**  */
  color_primary?: string;

  /**  */
  color_secondary?: string;

  /**  */
  color_text?: string;

  /**  */
  color_background?: string;

  /**  */
  has_menu?: boolean;

  /**  */
  variant_display?: Display;

  /**  */
  has_cart?: boolean;

  /**  */
  show_announcement_bar?: boolean;

  /**  */
  announcement_bar?: string;

  /**  */
  abandon_checkout?: boolean;

  /**  */
  facebook_url?: string;

  /**  */
  instagram_url?: string;

  /**  */
  twitter_url?: string;

  /**  */
  facebook_pixel_ids?: string;

  /**  */
  facebook_content_id?: string;

  /**  */
  google_conversion_id?: string;

  /**  */
  google_conversion_label?: string;

  /**  */
  tiktok_pixel_ids?: string;

  /**  */
  snapchat_pixel_ids?: string;

  /**  */
  pinterest_pixel_ids?: string;

  /**  */
  show_compared_price?: boolean;

  /**  */
  show_countdown?: boolean;

  /**  */
  show_social_proof?: boolean;

  /**  */
  show_congratulation?: boolean;

  /**  */
  show_trusting_icons?: boolean;

  /**  */
  show_instock?: boolean;

  /**  */
  show_inhomepage?: boolean;

  /**  */
  show_accepted_payment?: boolean;

  /**  */
  show_checkout_footer?: boolean;

  /**  */
  visiting_people?: number;

  /**  */
  countdown_time?: string;

  /**  */
  countdown_text?: string;

  /**  */
  purchased_orders?: number;

  /**  */
  sync_to_ggshopping?: boolean;

  /**  */
  hide_clearance?: boolean;
}

export interface SellPageV2ViewInDetail {
  /**  */
  id?: string;

  /**  */
  is_multil_store?: boolean;

  /**  */
  code?: number;

  /**  */
  store_id?: string;

  /**  */
  pbase_id?: string;

  /**  */
  pbase_label?: string;

  /**  */
  pbase_title?: string;

  /**  */
  status?: SellPageStatus;

  /**  */
  gg_shop_status?: GoogleMerchantStatus;

  /**  */
  gg_shop_active_version_number?: number;

  /**  */
  sort_number?: number;

  /**  */
  setting?: SellPageSetting;

  /**  */
  published_date?: number;

  /**  */
  publisher_id?: string;

  /**  */
  store?: Store;

  /**  */
  slug?: string;

  /**  */
  cash_in_balance?: number;

  /**  */
  orders?: number;

  /**  */
  can_delete?: boolean;
}

export interface SellPageVersionViewInDetail {
  /**  */
  id?: string;

  /**  */
  store_id?: string;

  /**  */
  store_url?: string;

  /**  */
  pbase_id?: string;

  /**  */
  pbase_label?: string;

  /**  */
  pbase_title?: string;

  /**  */
  ppage_id?: string;

  /**  */
  page_note?: string;

  /**  */
  version_number?: number;

  /**  */
  total_version?: number;

  /**  */
  version_note?: string;

  /**  */
  announcement_bar?: string;

  /**  */
  title?: string;

  /**  */
  top_description?: string;

  /**  */
  short_description?: string;

  /**  */
  middle_description?: string;

  /**  */
  bottom_description?: string;

  /**  */
  status?: SellPageStatus;

  /**  */
  images?: string[];

  /**  */
  shipping_name?: string;

  /**  */
  shippings?: Shipping[];

  /**  */
  facebook_content?: FaceBookContent;

  /**  */
  variants?: VariantInView[];

  /**  */
  option_values?: OptionValue[];

  /**  */
  reviews?: Review[];

  /**  */
  promotion?: Promotion;

  /**  */
  upsell?: boolean;

  /**  */
  upsell_info?: UpsellSetting;

  /**  */
  publisher_id?: string;

  /**  */
  rating_setting?: RatingSetting;

  /**  */
  percent_selless_fee?: number;

  /**  */
  fix_selless_fee?: number;

  /**  */
  percent_payment_fee?: number;

  /**  */
  fix_payment_fee?: number;
}

export interface SellPageVersionViewInList {
  /**  */
  id?: string;

  /**  */
  pbase_id?: string;

  /**  */
  page_note?: string;

  /**  */
  pbase_title?: string;

  /**  */
  first_image?: string;

  /**  */
  suggest_price?: number;

  /**  */
  percent_payment_fee?: number;

  /**  */
  fix_payment_fee?: number;

  /**  */
  percent_selless_fee?: number;

  /**  */
  fix_selless_fee?: number;

  /**  */
  shipping_fee?: number;

  /**  */
  aov?: number;

  /**  */
  base_cost?: number;

  /**  */
  estimated_tax?: number;
}

export interface SellPageViewInDetail {
  /**  */
  id?: string;

  /**  */
  is_multil_store?: boolean;

  /**  */
  code?: number;

  /**  */
  store_id?: string;

  /**  */
  pbase_id?: string;

  /**  */
  pbase_label?: string;

  /**  */
  pbase_title?: string;

  /**  */
  ppage_id?: string;

  /**  */
  page_note?: string;

  /**  */
  title?: string;

  /**  */
  top_description?: string;

  /**  */
  short_description?: string;

  /**  */
  middle_description?: string;

  /**  */
  bottom_description?: string;

  /**  */
  status?: SellPageStatus;

  /**  */
  gg_shop_status?: GoogleMerchantStatus;

  /**  */
  images?: string[];

  /**  */
  shipping_name?: string;

  /**  */
  shippings?: Shipping[];

  /**  */
  facebook_content?: FaceBookContent;

  /**  */
  variants?: VariantInView[];

  /**  */
  option_values?: OptionValue[];

  /**  */
  reviews?: Review[];

  /**  */
  promotion?: Promotion;

  /**  */
  upsell?: boolean;

  /**  */
  upsell_info?: UpsellSetting;

  /**  */
  sort_number?: number;

  /**  */
  setting?: SellPageSetting;

  /**  */
  published_date?: number;

  /**  */
  publisher_id?: string;

  /**  */
  store?: Store;

  /**  */
  slug?: string;

  /**  */
  percent_selless_fee?: number;

  /**  */
  fix_selless_fee?: number;

  /**  */
  percent_payment_fee?: number;

  /**  */
  fix_payment_fee?: number;

  /**  */
  cash_in_balance?: number;

  /**  */
  orders?: number;

  /**  */
  can_delete?: boolean;

  /**  */
  rating_setting?: RatingSetting;
}

export interface SellPageViewInList {
  /**  */
  id?: string;

  /**  */
  is_multil_store?: boolean;

  /**  */
  pbase_title?: string;

  /**  */
  pbase_label?: string;

  /**  */
  page_note?: string;

  /**  */
  images?: string[];

  /**  */
  domain?: SellessDomain;

  /**  */
  revenue?: number;

  /**  */
  orders?: number;

  /**  */
  percent_selless_fee?: number;

  /**  */
  fix_selless_fee?: number;

  /**  */
  cash_in_balance?: number;

  /**  */
  status?: SellPageStatus;

  /**  */
  slug?: string;

  /**  */
  can_delete?: boolean;

  /**  */
  ads_spend?: number;
}

export interface SellPageViewInListPagedList {
  /**  */
  data?: SellPageViewInList[];

  /**  */
  page_index?: number;

  /**  */
  page_size?: number;

  /**  */
  total_count?: number;

  /**  */
  total_pages?: number;

  /**  */
  has_previous_page?: boolean;

  /**  */
  has_next_page?: boolean;
}

export interface SellessDomain {
  /**  */
  publisher_id?: string;

  /**  */
  store_id?: string;

  /**  */
  status?: domain_status;

  /**  */
  name?: string;

  /**  */
  expired?: Date;

  /**  */
  dns_date?: Date;

  /**  */
  auto_renew?: boolean;

  /**  */
  price?: number;

  /**  */
  annual_fee?: number;

  /**  */
  paid_amount?: number;

  /**  */
  is_primary?: boolean;

  /**  */
  is_default?: boolean;

  /**  */
  facebook_code?: string;

  /**  */
  pbase_id?: string;

  /**  */
  store_histories?: domain_history[];

  /**  */
  id?: string;

  /**  */
  created_by?: string;

  /**  */
  updated_by?: string;

  /**  */
  created_at?: Date;

  /**  */
  updated_at?: Date;
}

export interface SellpageVersionsAddResponseView {
  /**  */
  sellpage_versions?: SellPageVersionViewInList[];

  /**  */
  pbase_label?: string;
}

export interface Shipping {
  /**  */
  id?: string;

  /**  */
  tag?: string;

  /**  */
  applied_variants?: string[];

  /**  */
  shipping_rules?: ShippingRule[];
}

export interface ShippingRule {
  /**  */
  id?: string;

  /**  */
  unit_name?: page_settings_shipping_type;

  /**  */
  amount?: number;

  /**  */
  quantity?: number;

  /**  */
  shipping_price?: number;

  /**  */
  shipping_rule_method?: ShippingRuleMethod;
}

export interface SortMapping {
  /**  */
  origin_value?: string;

  /**  */
  sequence?: number;
}

export interface Store {
  /**  */
  name?: string;

  /**  */
  page_title?: string;

  /**  */
  description?: string;

  /**  */
  favicon_url?: string;

  /**  */
  logo_url?: string;

  /**  */
  logo_width?: number;

  /**  */
  announcement_bar?: string;

  /**  */
  google_analytic_ids?: string[];

  /**  */
  google_verification_id?: string;

  /**  */
  pinterest_verification_id?: string;

  /**  */
  google_merchant?: GoogleMerchant;

  /**  */
  show_address?: boolean;

  /**  */
  show_phone?: boolean;

  /**  */
  show_covi?: boolean;

  /**  */
  show_abount_us?: boolean;

  /**  */
  show_company_info?: boolean;

  /**  */
  is_multil_store?: boolean;

  /**  */
  domains?: SellessDomain[];

  /**  */
  publisher_id?: string;

  /**  */
  store_address?: string;

  /**  */
  id?: string;

  /**  */
  created_by?: string;

  /**  */
  updated_by?: string;

  /**  */
  created_at?: Date;

  /**  */
  updated_at?: Date;
}

export interface TokenFacebookAddRQ {
  /**  */
  token?: string;

  /**  */
  name?: string;

  /**  */
  profile_picture?: string;
}

export interface TrafficConfig {
  /**  */
  page_version_id?: string;

  /**  */
  is_enabled?: boolean;
}

export interface UpdateSellPage {
  /**  */
  id?: string;

  /**  */
  title?: string;

  /**  */
  top_description?: string;

  /**  */
  short_description?: string;

  /**  */
  middle_description?: string;

  /**  */
  bottom_description?: string;

  /**  */
  images?: string[];

  /**  */
  shipping_name?: string;

  /**  */
  upsert_shippings?: Shipping[];

  /**  */
  delete_shipping_ids?: string[];

  /**  */
  delete_shipping_rule_ids?: string[];

  /**  */
  upsert_variants?: UpdateVariant[];

  /**  */
  upsert_reviews?: Review[];

  /**  */
  delete_review_ids?: string[];

  /**  */
  order_reviews?: string[];

  /**  */
  promotion?: Promotion;

  /**  */
  upsell?: boolean;

  /**  */
  upsell_info?: UpsellSetting;

  /**  */
  setting?: SellPageSetting;

  /**  */
  sort_number?: number;

  /**  */
  store?: Store;

  /**  */
  slug?: string;

  /**  */
  upsert_rating_setting?: RatingSetting;
}

export interface UpdateSellPageV2 {
  /**  */
  setting?: SellPageSetting;

  /**  */
  sort_number?: number;

  /**  */
  store?: Store;

  /**  */
  slug?: string;
}

export interface UpdateSellPageVersion {
  /**  */
  version_note?: string;

  /**  */
  title?: string;

  /**  */
  announcement_bar?: string;

  /**  */
  top_description?: string;

  /**  */
  short_description?: string;

  /**  */
  middle_description?: string;

  /**  */
  bottom_description?: string;

  /**  */
  images?: string[];

  /**  */
  shipping_name?: string;

  /**  */
  upsert_shippings?: Shipping[];

  /**  */
  delete_shipping_ids?: string[];

  /**  */
  delete_shipping_rule_ids?: string[];

  /**  */
  upsert_variants?: UpdateVariant[];

  /**  */
  upsert_reviews?: Review[];

  /**  */
  delete_review_ids?: string[];

  /**  */
  order_reviews?: string[];

  /**  */
  promotion?: Promotion;

  /**  */
  upsell?: boolean;

  /**  */
  upsell_info?: UpsellSetting;

  /**  */
  upsert_rating_setting?: RatingSetting;
}

export interface UpdateTrafficConfigSellpageV2 {
  /**  */
  configs?: TrafficConfig[];
}

export interface UpdateVariant {
  /**  */
  id?: string;

  /**  */
  image?: string;

  /**  */
  compare_price?: number;

  /**  */
  default_price?: number;
}

export interface UpsellSetting {
  /**  */
  discount_percent?: number;

  /**  */
  initiate_discount_percent?: number;
}

export interface ValueMapping {
  /**  */
  origin_value?: string;

  /**  */
  option_value?: string;
}

export interface VariantInView {
  /**  */
  id?: string;

  /**  */
  pbase_variant_id?: string;

  /**  */
  ppage_variant_id?: string;

  /**  */
  image?: string;

  /**  */
  compare_price?: number;

  /**  */
  default_price?: number;

  /**  */
  product_cost?: number;

  /**  */
  packaging_cost?: number;

  /**  */
  shipping_cost?: number;

  /**  */
  allow_to_sell?: boolean;

  /**  */
  properties?: PropertyVariant[];
}

export interface ad_publisher_performance_view {
  /**  */
  order?: number;

  /**  */
  hold_amount?: number;

  /**  */
  total_sell_page?: number;

  /**  */
  active_sell_page?: number;
}

export interface admin_config {
  /**  */
  addresses?: string[];

  /**  */
  created: Date;

  /**  */
  created_by?: string;

  /**  */
  updated: Date;

  /**  */
  updated_by?: string;

  /**  */
  id?: string;
}

export interface cart_item {
  /**  */
  quantity?: number;

  /**  */
  shipping_rule?: string;

  /**  */
  page_id?: string;

  /**  */
  page_version?: number;

  /**  */
  discount_code?: string;

  /**  */
  discount_type?: string;

  /**  */
  discount_value?: string;

  /**  */
  discount_amount?: number;

  /**  */
  created: Date;

  /**  */
  updated: Date;

  /**  */
  compare_price?: number;

  /**  */
  default_price?: number;

  /**  */
  variant_data?: string;

  /**  */
  pbase_variant_id?: string;

  /**  */
  ppage_variant_id?: string;

  /**  */
  pbase_id?: string;

  /**  */
  ppage_id?: string;

  /**  */
  title?: string;

  /**  */
  product_family?: string;

  /**  */
  product_line?: string;

  /**  */
  product_code?: string;

  /**  */
  product_name?: string;

  /**  */
  product_side_number?: string;

  /**  */
  slug?: string;

  /**  */
  collection_id?: string;

  /**  */
  image_url?: string;

  /**  */
  properties?: property_variant[];

  /**  */
  id?: string;
}

export interface customer_base {
  /**  */
  first_name?: string;

  /**  */
  last_name?: string;

  /**  */
  email?: string;

  /**  */
  phone?: string;

  /**  */
  created: Date;

  /**  */
  created_by?: string;

  /**  */
  updated: Date;

  /**  */
  updated_by?: string;

  /**  */
  id?: string;
}

export interface domain_history {
  /**  */
  start_time?: Date;

  /**  */
  end_time?: Date;

  /**  */
  publisher_id?: string;

  /**  */
  domain?: string;

  /**  */
  store_id?: string;

  /**  */
  description?: string;
}

export interface fb_account_view {
  /**  */
  id?: string;

  /**  */
  fb_user_id?: string;

  /**  */
  fb_account_status?: fb_account_status;

  /**  */
  fb_name?: string;

  /**  */
  fb_profile_pic?: string;

  /**  */
  fb_adaccounts?: fb_adaccount_view[];
}

export interface fb_adaccount_view {
  /**  */
  fb_adaccount_id?: string;

  /**  */
  fb_adaccount_status?: FbAdAccountStatus;

  /**  */
  fb_adaccount_name?: string;

  /**  */
  fb_adaccount_timezone_name?: string;

  /**  */
  fb_adaccount_created_time?: Date;
}

export interface performance_view {
  /**  */
  order?: number;

  /**  */
  revenue?: number;

  /**  */
  total_cost?: number;

  /**  */
  aov?: number;

  /**  */
  cost_product?: number;

  /**  */
  cost_fulfillment?: number;

  /**  */
  avg?: number;

  /**  */
  taken_amount?: number;

  /**  */
  hold_amount?: number;

  /**  */
  unhold_amount?: number;

  /**  */
  cash_to_balance?: number;

  /**  */
  tax_amount?: number;

  /**  */
  total_cost_include_tax?: number;
}

export interface po_view {
  /**  */
  customer?: customer_base;

  /**  */
  cart_items?: cart_item[];

  /**  */
  avg?: number;

  /**  */
  cart_id?: string;

  /**  */
  warning_fraud_flag?: boolean;

  /**  */
  warning_fraud_reason?: string;

  /**  */
  warning_address_flag?: boolean;

  /**  */
  warning_address_reason?: string;

  /**  */
  cost_product?: number;

  /**  */
  cost_fulfillment?: number;

  /**  */
  cost_total?: number;

  /**  */
  cost_product_final?: number;

  /**  */
  cost_fulfillment_final?: number;

  /**  */
  cost_total_final?: number;

  /**  */
  cost_product_origin?: number;

  /**  */
  cost_fulfillment_origin?: number;

  /**  */
  cost_total_origin?: number;

  /**  */
  fee_abandoned?: number;

  /**  */
  fee_payment?: number;

  /**  */
  fee_selless?: number;

  /**  */
  hold_amount?: number;

  /**  */
  taken_amount?: number;

  /**  */
  hold_released?: Date;

  /**  */
  hold_status?: publisher_hold_status;

  /**  */
  created: Date;

  /**  */
  updated: Date;

  /**  */
  code: string;

  /**  */
  amount?: number;

  /**  */
  items_amount?: number;

  /**  */
  shipping_rule_type?: string;

  /**  */
  shipping_code?: string;

  /**  */
  shipping_amount?: number;

  /**  */
  shipping_value?: string;

  /**  */
  discount_code?: string;

  /**  */
  discount_value?: string;

  /**  */
  discount_amount?: number;

  /**  */
  discount_type?: promotion_type;

  /**  */
  tax_amount?: number;

  /**  */
  tax_engine?: string;

  /**  */
  shipping_email?: string;

  /**  */
  shipping_phone?: string;

  /**  */
  shipping_last_name?: string;

  /**  */
  shipping_first_name?: string;

  /**  */
  shipping_address1?: string;

  /**  */
  shipping_address2?: string;

  /**  */
  shipping_postal_code?: string;

  /**  */
  shipping_city?: string;

  /**  */
  shipping_state?: string;

  /**  */
  shipping_country?: string;

  /**  */
  shipping_country_code?: string;

  /**  */
  billing_same_shipping?: boolean;

  /**  */
  billing_email?: string;

  /**  */
  billing_phone?: string;

  /**  */
  billing_last_name?: string;

  /**  */
  billing_first_name?: string;

  /**  */
  billing_address1?: string;

  /**  */
  billing_address2?: string;

  /**  */
  billing_postal_code?: string;

  /**  */
  billing_city?: string;

  /**  */
  billing_state?: string;

  /**  */
  billing_country?: string;

  /**  */
  billing_country_code?: string;

  /**  */
  source_abandoned?: abandoned_source;

  /**  */
  source_abandoned_code?: string;

  /**  */
  source_ip?: string;

  /**  */
  source_ua?: string;

  /**  */
  source_rid?: string;

  /**  */
  source_referer?: string;

  /**  */
  source_referer_data?: string;

  /**  */
  source_country?: string;

  /**  */
  source_url?: string;

  /**  */
  source_url_domain?: string;

  /**  */
  source_url_slug?: string;

  /**  */
  source_url_querystring?: any | null;

  /**  */
  source_url_querystring_id?: string;

  /**  */
  source_url_querystring_utm_campaign?: string;

  /**  */
  source_url_querystring_utm_content?: string;

  /**  */
  source_url_querystring_utm_medium?: string;

  /**  */
  source_url_querystring_utm_source?: string;

  /**  */
  source_url_querystring_utm_seller?: string;

  /**  */
  source_ads?: string;

  /**  */
  publisher_id?: string;

  /**  */
  publisher_ads_id?: string;

  /**  */
  publisher_type?: string;

  /**  */
  publisher_team?: string;

  /**  */
  store_id?: string;

  /**  */
  store_domain?: string;

  /**  */
  page_id?: string;

  /**  */
  page_version?: number;

  /**  */
  page_slug?: string;

  /**  */
  page_title?: string;

  /**  */
  pbase_id?: string;

  /**  */
  ppage_id?: string;

  /**  */
  product_family?: string;

  /**  */
  product_line?: string;

  /**  */
  product_code?: string;

  /**  */
  product_name?: string;

  /**  */
  product_type?: ProductType;

  /**  */
  payment_paid?: Date;

  /**  */
  payment_failure?: string;

  /**  */
  payment_gateway_id?: string;

  /**  */
  payment_transaction_id?: string;

  /**  */
  payment_card_first4?: string;

  /**  */
  payment_card_last4?: string;

  /**  */
  payment_provider?: string;

  /**  */
  payment_merchant_name?: string;

  /**  */
  payment_merchant_email?: string;

  /**  */
  payment_flow?: string;

  /**  */
  opt_out?: boolean;

  /**  */
  id?: string;
}

export interface po_viewPagedList {
  /**  */
  data?: po_view[];

  /**  */
  page_index?: number;

  /**  */
  page_size?: number;

  /**  */
  total_count?: number;

  /**  */
  total_pages?: number;

  /**  */
  has_previous_page?: boolean;

  /**  */
  has_next_page?: boolean;
}

export interface property_variant {
  /**  */
  name?: string;

  /**  */
  value?: string;
}

export interface publisher_meta_data {
  /**  */
  user_guide?: user_guide;

  /**  */
  referral?: referral;
}

export interface referral {
  /**  */
  code?: string;

  /**  */
  by?: string;

  /**  */
  percent?: number;

  /**  */
  duration?: number;

  /**  */
  mode?: boolean;

  /**  */
  percented_refer?: number;

  /**  */
  durationed_refer?: number;
}

export interface user_guide {
  /**  */
  is_showed?: boolean;
}

export enum AdProviders {
  'Organic' = 'Organic',
  'Facebook' = 'Facebook',
  'Pinterest' = 'Pinterest',
  'Google' = 'Google'
}

export enum AssignType {
  'all' = 'all',
  'someone' = 'someone',
  'None' = 'None'
}

export enum BudgetLevel {
  'CAMPAIGN' = 'CAMPAIGN',
  'ADSET' = 'ADSET'
}

export enum BudgetType {
  'DAILY' = 'DAILY',
  'LIFETIME' = 'LIFETIME'
}

export enum Display {
  'Button' = 'Button',
  'Dropdown' = 'Dropdown'
}

export enum FbAdAccountStatus {
  'Active' = 'Active',
  'Disabled' = 'Disabled',
  'Unsettled' = 'Unsettled',
  'PendingReview' = 'PendingReview',
  'InGracePeriod' = 'InGracePeriod',
  'PendingClosure' = 'PendingClosure',
  'TemporarilyUnavailable' = 'TemporarilyUnavailable'
}

export enum FbAdEntityStatus {
  'ACTIVE' = 'ACTIVE',
  'PAUSED' = 'PAUSED',
  'DELETED' = 'DELETED',
  'ARCHIVED' = 'ARCHIVED'
}

export enum GoogleMerchantStatus {
  'Draft' = 'Draft',
  'Processing' = 'Processing',
  'Failed' = 'Failed',
  'Connected' = 'Connected'
}

export enum GroupBy {
  'CAMPAIGN' = 'CAMPAIGN',
  'ADSET' = 'ADSET',
  'AD' = 'AD'
}
export type ProblemDetailsWithAdditionalProperties = ProblemDetails & { [additionalProperties: string]: any | null };
export enum ProductRequestStatus {
  'INREVIEW' = 'INREVIEW',
  'REJECTED' = 'REJECTED',
  'PENDING' = 'PENDING',
  'APPROVED' = 'APPROVED'
}

export enum ProductSearchType {
  'all' = 'all',
  'running' = 'running'
}

export enum ProductType {
  'QUANTITY' = 'QUANTITY',
  'VARIANT' = 'VARIANT'
}

export enum ProviderAdSources {
  'Facebook' = 'Facebook',
  'Tiktok' = 'Tiktok',
  'Pinterest' = 'Pinterest',
  'Google' = 'Google',
  'Organic' = 'Organic'
}

export enum SellPageStatus {
  'draft' = 'draft',
  'processing' = 'processing',
  'published' = 'published',
  'banned' = 'banned',
  'trade_mark' = 'trade_mark',
  'inactive' = 'inactive',
  'delete' = 'delete'
}

export enum SellPageVersionStatus {
  'draft' = 'draft',
  'processing' = 'processing',
  'published' = 'published',
  'delete' = 'delete'
}

export enum ShippingRuleMethod {
  'lt' = 'lt',
  'gt' = 'gt',
  'ge' = 'ge',
  'le' = 'le',
  'eq' = 'eq'
}

export enum UpdateType {
  'CAMPAIGN' = 'CAMPAIGN',
  'ADSET' = 'ADSET',
  'AD' = 'AD'
}

export enum UploadType {
  'gallery' = 'gallery',
  'description' = 'description',
  'reviews' = 'reviews',
  'logo' = 'logo',
  'favicon' = 'favicon',
  'facebookads' = 'facebookads',
  'identity' = 'identity',
  'videomp4' = 'videomp4'
}

export enum abandoned_source {
  'SMS' = 'SMS',
  'EMAIL' = 'EMAIL',
  'APP' = 'APP',
  'MANY_CHAT' = 'MANY_CHAT',
  'UNKNOWN' = 'UNKNOWN'
}

export enum condition_status {
  'ACTIVE' = 'ACTIVE',
  'INACTIVE' = 'INACTIVE'
}

export enum domain_renew_action {
  'NOACTION' = 'NOACTION',
  'ONEYEAR' = 'ONEYEAR'
}

export enum domain_status {
  'PROCESSING' = 'PROCESSING',
  'FAILED' = 'FAILED',
  'ACTIVE' = 'ACTIVE',
  'INACTIVE' = 'INACTIVE',
  'CONNECTED' = 'CONNECTED',
  'EXPIRED' = 'EXPIRED'
}

export enum experience {
  'NO_EXPERIENCE' = 'NO_EXPERIENCE',
  'LESSS_THAN_SIX_MONTH' = 'LESSS_THAN_SIX_MONTH',
  'SIX_TO_TWELVE_MONTH' = 'SIX_TO_TWELVE_MONTH',
  'ONE_TO_THREE_YEAR' = 'ONE_TO_THREE_YEAR',
  'MORE_THAN_THREE_YEAR' = 'MORE_THAN_THREE_YEAR'
}

export enum fb_account_status {
  'ACTIVE' = 'ACTIVE',
  'DISABLED' = 'DISABLED',
  'DISCONNECTED' = 'DISCONNECTED',
  'FAILED' = 'FAILED'
}

export enum investment {
  'LESS_THAN_TWO_THOUSAND' = 'LESS_THAN_TWO_THOUSAND',
  'TWO_TO_FIVE_THOUSAND' = 'TWO_TO_FIVE_THOUSAND',
  'FIVE_TO_TEN_THOUSAND' = 'FIVE_TO_TEN_THOUSAND',
  'MORE_THAN_TEN_THOUSAND' = 'MORE_THAN_TEN_THOUSAND'
}

export enum own_ecommerce_business {
  'YES' = 'YES',
  'NO' = 'NO'
}

export enum page_settings_shipping_type {
  'AMOUNT' = 'AMOUNT',
  'QUANTITY' = 'QUANTITY'
}

export enum payout_default {
  'PAYPALEX' = 'PAYPALEX',
  'PAYONEER' = 'PAYONEER'
}

export enum personal_document_type {
  'identity_card' = 'identity_card',
  'passport' = 'passport',
  'driver_license' = 'driver_license'
}

export enum promotion_type {
  'SHIP' = 'SHIP',
  'PERCENT' = 'PERCENT',
  'FIXED' = 'FIXED'
}

export enum publisher_channel {
  'UNKNOWN' = 'UNKNOWN',
  'IHO' = 'IHO',
  'ORGANIC' = 'ORGANIC',
  'FACEBOOK' = 'FACEBOOK',
  'GOOGLE' = 'GOOGLE',
  'SELLESS' = 'SELLESS'
}

export enum publisher_hold_status {
  'ACTIVE' = 'ACTIVE',
  'COMPLETED' = 'COMPLETED'
}

export enum publisher_status {
  'NEW' = 'NEW',
  'ACTIVE' = 'ACTIVE',
  'INACTIVE' = 'INACTIVE',
  'REJECTED' = 'REJECTED'
}

export enum sell_page_create_type {
  'single' = 'single',
  'new_store' = 'new_store',
  'existing_store' = 'existing_store'
}

export enum total_order_last_month {
  'LESS_THAN_500' = 'LESS_THAN_500',
  'FIVE_HUNDRED_TO_2500' = 'FIVE_HUNDRED_TO_2500',
  'TWO_THOUSANDS_FIVE_HUNDRED_TO_5000' = 'TWO_THOUSANDS_FIVE_HUNDRED_TO_5000',
  'MORE_THAN_5000' = 'MORE_THAN_5000'
}

export enum type_ecommerce_business {
  'DROP_SHIPPING' = 'DROP_SHIPPING',
  'POD' = 'POD',
  'BRAND' = 'BRAND'
}

export enum type_payment {
  'PAYPAL' = 'PAYPAL',
  'STRIPE' = 'STRIPE',
  'OTHER' = 'OTHER'
}
