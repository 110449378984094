import React, { ReactElement } from 'react'

import { Collapse } from './Collapse'
import { Icon } from './svg/Icon'
import css from 'styled-jsx/css'
import { iconPath } from 'com-ui/utilities/imagePath'

export interface IAccordionTab extends React.AllHTMLAttributes<HTMLElement> {
  header?: ReactElement | string
  enableClickHeader?: boolean
  indicator?: ReactElement | null
  closeIndicator?: ReactElement | null
  indicatorLeft?: boolean
  open?: boolean
  setOpen?: (open: boolean) => void
}

const initIndicator = <Icon src={iconPath('chevron_down_regular')} size="24px" />

export const AccordionTab = ({
  header = '',
  enableClickHeader = true,
  indicator = initIndicator,
  closeIndicator = indicator ? React.cloneElement(indicator, { className: 'up' }) : null,
  indicatorLeft = false,
  open = false,
  setOpen,
  className,
  children
}: IAccordionTab) => {
  if (!React.isValidElement(header)) {
    header = <div className={'fs-sub2'}>{header}</div>
  }
  return (
    <div className={`wrapper ${className || ''} ${open ? 'open' : ''} ${indicatorLeft ? 'left' : ''}`}>
      <div
        className={`header ${enableClickHeader ? 'cursor-pointer' : ''}`}
        onClick={() => enableClickHeader && setOpen?.(!open)}
      >
        <span className="header-title">{header}</span>
        <span className={`indicator`} onClick={() => !enableClickHeader && setOpen?.(!open)}>
          {open ? closeIndicator : indicator}
        </span>
      </div>
      <Collapse open={open} className={`content`}>
        {typeof children !== 'string' && children}
        {typeof children === 'string' && <div dangerouslySetInnerHTML={{ __html: children }}></div>}
      </Collapse>
      <style jsx>{accordionTabStyle}</style>
    </div>
  )
}

const accordionTabStyle = css`
  .header {
    display: flex;
  }
  .header-title {
    display: flex;
    align-items: center;
  }
  .indicator {
    align-items: center;
    display: inline-flex;
    margin-left: auto;
    --aspect-ratio: 100%;
    transition: transform var(--transition);
    cursor: pointer;
    padding: 8px;
    :global(.up) {
      transform: scale(1, -1);
    }
  }

  .left {
    .header {
      flex-direction: row-reverse;
      justify-content: flex-end;
    }

    .indicator {
      margin-left: unset;
    }
  }
`
