import styled from 'styled-components'

export type IHeaderText = {
  Tag?: string
  fontSize?: string
  lineHeight?: string
  [key: string]: any
  color?: string 
}

function Index({ Tag, lineHeight, ...props }: IHeaderText) {
  const RenderTag = (Tag || 'span') as keyof JSX.IntrinsicElements
  return <RenderTag {...props}>{props.children}</RenderTag>
}

const HeaderText = styled(Index)`
  font-family: var(--font-header);
  font-weight: 600;
  font-size: ${(props: any) => props?.fontSize}px;
  line-height: ${(props: any) => props?.lineHeight}px;
  color: ${(props: any) => props?.color};
`

export default HeaderText
