import { namespaceConfig } from 'fast-redux'

type domainState = {
  renew: boolean
}

const DEFAULT_STATE: domainState = { renew: false }

const { action, getState } = namespaceConfig('domain', DEFAULT_STATE)

export const getDomain = (state: any) => getState(state)

export const getRenewState = (state: any) => getDomain(state).renew

export const initDomain = action('initDomain', function (state: domainState, renew: boolean) {
  return { ...state, renew }
})

export const updateRenew = action('updateRenew', function (state: domainState, renew: boolean) {
  return { ...state, renew }
})
