import { format, isValid } from 'date-fns'

import css from 'styled-jsx/css'

export const DATE_FORMAT = 'dd-MM-yyyy'
export const MASK_FORMAT = '99-99-9999'
export const DELIMITER = ' | '
export const MIN_DATE = new Date(new Date().setFullYear(1980, 0, 1))
export const MAX_DATE = new Date(new Date().setFullYear(2050, 0, 1))

export interface InputDateCommonProps {
  minDate?: Date
  maxDate?: Date
  className?: string
  placeholder?: string
  position?: 'bottom left' | 'bottom right' | 'top left' | 'top right' | 'auto'
  pickerProps?: any
  inputProp?: any
  focus?: boolean
}

export interface InputDateProps extends InputDateCommonProps {
  date?: Date | null | undefined
  onChange?: (date?: Date) => any
}

export interface InputDateRangeProps extends InputDateCommonProps {
  startDate?: Date | undefined
  endDate?: Date | undefined
  onChange?: (startDate?: Date, endDate?: Date) => any
}

export const dateFromISO = (iso?: string) => {
  if (!iso) {
    return null
  }
  try {
    return new Date(iso)
  } catch (error) {
    return null
  }
}

export const dateFormat = (value: string | Date | undefined | null, dateFormat?: string) => {
  if (!value) {
    return ''
  }
  if (typeof value == 'string') {
    try {
      value = new Date(value)
    } catch (error) {
      return ''
    }
  }
  if (!isValid(value)) {
    return ''
  }
  return format(value, dateFormat ? dateFormat : DATE_FORMAT)
}

export const inputDateStyle = css`
  .input-date-container {
    position: relative;
    user-select: none;
    :global(.suffix),
    :global(.prefix) {
      padding: 8px;
    }
    .date-wrapper {
      position: absolute;
      z-index: -1;
      opacity: 0;
      pointer-events: none;
      transition: height var(--transition), opacity var(--transition), transform var(--transition);
      transform: translateY(-10px);
      &.open {
        opacity: 1;
        pointer-events: all;
        z-index: 10;
        transform: none;
      }
      &.bottom {
        top: 100%;
      }
      &.top {
        bottom: 100%;
      }
      &.left {
        left: 0;
      }
      &.right {
        right: 0;
      }
    }
    :global(.p-inputtext) {
      width: 100%;
      padding: 8px 0 8px 16px;
      font-weight: 500;
      font-size: 13px;
      line-height: 24px;
      color: var(--gray-8);
    }
  }
`
