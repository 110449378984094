import { useEffect, useRef, useState } from 'react'

import { Calendar } from 'react-date-range'
import CrLabel from './cr-label'
import { CrTextField } from './text-field'
import { IconButton } from '@fluentui/react'
import format from 'date-fns/format'
import styled from 'styled-components'
import { useId } from '@fluentui/react-hooks'

interface ICrDateEditor {
  onChange?: (value: Date | null) => void
  value?: Date | null
  removable?: boolean
  [key: string]: any
}

//@ts-ignore
export default function CrDateEditor(props: ICrDateEditor) {
  const datePickerId = useId('datePickerId')
  const ref = useRef(null)
  const [showPicker, setShowPicker] = useState(false)
  const [date, setDate] = useState(props.value ?? (props.removable ? null : new Date()))

  useEffect(() => {
    function handleContains(event: React.MouseEvent<HTMLDivElement, MouseEvent> | any) {
      const container: any = ref.current
      const contain = container && container.contains(event.target)
      if (!contain) {
        setShowPicker(false)
      }
    }
    document && document.addEventListener('mousedown', handleContains)
    return () => {
      document && document.removeEventListener('mousedown', handleContains)
    }
  })

  const onChange = (_date: any) => {
    setDate(_date)
    props.onChange?.(_date)
    setShowPicker(false)
  }

  const onRemove = () => {
    setDate(null)
    props.onChange?.(null)
  }

  return (
    <CrLabel {...{ className: props.className, label: props.label, htmlFor: datePickerId }}>
      <DateContainer ref={ref}>
        <div className="date-input" onClick={(_e) => setShowPicker(!showPicker)}>
          <CrTextField
            {...{
              ...props,
              label: '',
              value: date ? format(date, 'dd-MM-yyyy') : '',
              placeholder: 'dd-MM-yyyy',
              onChange: () => null
            }}
          />
          {props.removable && (
            <IconButton
              className="delete-btn"
              iconProps={{ iconName: 'dismiss_24_filled' }}
              onClick={(event) => {
                event.stopPropagation()
                onRemove()
              }}
            />
          )}
        </div>
        {showPicker && (
          <div className="date-picker">
            <Calendar
              direction="vertical"
              showSelectionPreview={true}
              scroll={{ enabled: true, calendarHeight: 220 }}
              showMonthAndYearPickers={false}
              weekdayDisplayFormat="EEEEE"
              weekStartsOn={1}
              {...props}
              /*// @ts-ignore */
              date={date}
              onChange={onChange}
            />
          </div>
        )}
      </DateContainer>
    </CrLabel>
  )
}

export const DateContainer = styled.div`
  position: relative;
  .date-input {
    position: relative;
    input {
      cursor: pointer;
    }
    .delete-btn {
      position: absolute;
      right: 8px;
      top: 4px;
      display: none;

      i {
        color: var(--gray-6);
      }

      &:hover {
        background-color: transparent;
      }
    }
    &:hover {
      .delete-btn {
        display: block;
      }
    }
  }

  .date-picker {
    box-shadow: var(--depth-8);
    position: absolute;
    display: inline-block;
    top: 100%;
    left: 0;
    z-index: 10;
    .rdrInputRanges {
      display: none;
    }
    .rdrDefinedRangesWrapper {
      width: 100px;
    }
    .rdrStaticRange {
      border-bottom: none;
    }
    .rdrStaticRangeLabel {
      padding: 10px;
      line-height: 1;
    }
    .rdrDateDisplayWrapper {
      display: none;
    }
    .rdrMonth {
      width: 250px;
    }
    .rdrMonthAndYearWrapper {
      height: 40px;
      padding-top: 0;
    }
    .rdrNextPrevButton {
      background: none;
    }
    .rdrWeekDays {
      padding: 5px 15px 5px 12px;
    }
    .rdrWeekDay {
      line-height: 1;
      &:nth-child(6) {
        color: var(--secondary-100);
      }
      &:nth-child(7) {
        color: var(--danger-50);
      }
    }
    .rdrDay:not(.rdrDayPassive).rdrDayWeekend .rdrDayNumber span {
      color: var(--secondary-100);
    }
    .rdrDay:not(.rdrDayPassive).rdrDayEndOfWeek .rdrDayNumber span {
      color: var(--danger-50);
    }
    .rdrDay:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span,
    .rdrDay:not(.rdrDayPassive) .rdrStartEdge ~ .rdrDayNumber span,
    .rdrDay:not(.rdrDayPassive) .rdrEndEdge ~ .rdrDayNumber span,
    .rdrDay:not(.rdrDayPassive) .rdrSelected ~ .rdrDayNumber span {
      color: var(--white-100);
    }
    .rdrStaticRange:hover .rdrStaticRangeLabel,
    .rdrStaticRange:focus .rdrStaticRangeLabel {
      background: var(--secondary-30);
    }
  }
`
