import styled from 'styled-components'
import { Label, mergeStyleSets } from '@fluentui/react'

export default function CrLabel(props: any) {
  return (
    <CrLabelContainer className={props.className}>
      {props.label && (
        <Label required={props.required} htmlFor={props.htmlFor} styles={labelStyles}>
          {props.label}
        </Label>
      )}
      {props.children}
      {props.errorMessage && (
        <div className="error-message" role="alert">
          <span data-automation-id="error-message">{props.errorMessage}</span>
        </div>
      )}
    </CrLabelContainer>
  )
}

const labelStyles = mergeStyleSets({
  root: {
    color: 'var(--gray-7)',
    background: 'none'
  }
})

const CrLabelContainer = styled.div`
  input {
    font-family: var(--font);
  }
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }

  .error-message {
    animation-name: css-1, css-14;
    animation-duration: 0.367s;
    animation-timing-function: cubic-bezier(0.1, 0.9, 0.2, 1);
    animation-fill-mode: both;
    -webkit-font-smoothing: antialiased;
    font-size: 12px;
    color: var(--danger-100);
    margin: 0px;
    padding-top: 5px;
  }
`
