import { Button, FileUploader } from 'com-ui2/controls'
import { MotionDndProvider, useMotionDndContext } from 'com-ui/libraries/motion-dnd/dnd-context'
import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react'

import { DragItem } from 'com-ui/libraries/motion-dnd/types'
import { GalleryViewer } from './GalleryViewer'
import { Icon } from '../'
import { equalArray, throttle } from 'com-ui2/utilities'
import { iconPath } from 'com-ui/utilities/imagePath'
import { motion } from 'framer-motion'
import styled from 'styled-components'
import update from 'immutability-helper'
import { useMotionDrag } from 'com-ui/libraries/motion-dnd/useMotionDrag'
import { useMotionDrop } from 'com-ui/libraries/motion-dnd/useMotionDrop'

interface IImageBox {
  uniqueKey: number
  order: number
  url?: string
  preview?: string
  type: 'active' | 'uploading' | 'uploaded' | 'remain' | 'error'
  className?: string
  selected?: boolean
  remain?: number
  removeImage?: (url?: string) => void
}

interface ISortableImageBox extends IImageBox {
  sortBoxes: (dragIndex: number, hoverIndex: number) => void
  dropCallBack: () => void
  handleClick?: (imageUrl: string) => void
  selected?: boolean
}

interface IImageGallery {
  images: string[]
  isMultipleUpload?: boolean
  onImageUploadChanged?: (files: File[]) => void
  onImageRemoved?: (imageUrl?: string) => void
  onImagesSorted?: (image: string[]) => void
  className?: string
  uploading?: boolean
  direction?: 'vertical' | 'horizontal'
}

interface ViewerOptions {
  // initialImages: string[]
  initialSelectedImages: string[]
}

function initiateBoxList(images: string[]) {
  const boxList: IImageBox[] = []

  if (!images || images.length === 0) {
    return boxList
  }

  for (let i = 0; i < images.length; i++) {
    if (i < 4) {
      const imageBox: IImageBox = {
        uniqueKey: Math.floor(Math.random() * 100000000),
        order: i + 1,
        url: images[i],
        type: i === 3 ? 'remain' : 'active',
        className: i < 2 ? `item-big` : i === 3 ? 'item-remain' : '',
        remain: i === 3 ? images.length - 3 : undefined
      }
      boxList.push(imageBox)
    } else {
      break
    }
  }
  return boxList
}

function reorderBoxes(boxes: IImageBox[], startOrder: number, hasBigItems = true) {
  return boxes.map((box, i) => {
    return {
      ...box,
      order: startOrder + i,
      ...(hasBigItems && { className: startOrder + i < 3 ? `item-big` : undefined })
    }
  })
}

function useGallery(
  images: string[],
  initiateBoxList: (images: string[]) => IImageBox[],
  hasBigItems = true
): [IImageBox[], Dispatch<SetStateAction<IImageBox[]>>, (dragIndex: number, hoverIndex: number) => void] {
  const [imageBoxes, setImageBoxes] = useState(initiateBoxList(images))
  useEffect(() => {
    if (
      !equalArray(
        images,
        imageBoxes.map((image) => image.url ?? '')
      )
    )
      setImageBoxes(initiateBoxList(images))
  }, [images])
  const sortBoxes = (dragIndex: number, hoverIndex: number) => {
    const dragBox = { ...imageBoxes[dragIndex] }
    const sortedBoxes = [...imageBoxes]
    if (dragIndex < hoverIndex) {
      //move down
      let slicedBoxes = imageBoxes.slice(dragIndex + 1, hoverIndex + 1)
      slicedBoxes.push(dragBox)
      slicedBoxes = reorderBoxes(slicedBoxes, dragBox.order, hasBigItems)
      sortedBoxes.splice(dragIndex, hoverIndex + 1 - dragIndex, ...slicedBoxes)
    } else {
      //move up
      const hoverBox = { ...imageBoxes[hoverIndex] }
      let slicedBoxes = imageBoxes.slice(hoverIndex, dragIndex)
      slicedBoxes = [dragBox, ...slicedBoxes]
      slicedBoxes = reorderBoxes(slicedBoxes, hoverBox.order, hasBigItems)
      sortedBoxes.splice(hoverIndex, dragIndex + 1 - hoverIndex, ...slicedBoxes)
    }
    setImageBoxes(
      update(imageBoxes, {
        $set: sortedBoxes
      })
    )
  }

  return [imageBoxes, setImageBoxes, sortBoxes]
}

function useSortableImageBox(props: ISortableImageBox) {
  const { sortBoxes, order, type, dropCallBack } = props
  const canDropRef = useRef<boolean>(false)
  useEffect(() => {
    canDropRef.current = true
  }, [])

  const { isMoving, setIsMoving } = useMotionDndContext()

  const { isDragging, onDragStart, onDragEnd } = useMotionDrag({
    item: { type: 'IMAGE_BOX', id: props.uniqueKey, order },
    canDrag: () => {
      return type === 'active'
    },
    end: dropCallBack
  })

  const { isHovering, dropRef, onHoverStart, onHoverEnd, onHover, onDrop } = useMotionDrop({
    accept: 'IMAGE_BOX',
    canDrop: (item?: DragItem) => {
      return item?.type === 'IMAGE_BOX' && type === 'active' && canDropRef.current
    },
    hover: (item: DragItem | undefined) => {
      // if (!ref.current || item?.type !== 'active') {
      if (isMoving || !canDropRef.current || item?.type !== 'IMAGE_BOX' || type !== 'active') {
        return
      }
      const dragIndex = item.order - 1
      const hoverIndex = order - 1

      if (dragIndex === hoverIndex) {
        return
      }

      canDropRef.current = false
      setIsMoving(true)
      sortBoxes(dragIndex, hoverIndex)

      setTimeout(() => {
        item.order = hoverIndex + 1
      }, 100)
      setTimeout(() => {
        canDropRef.current = true
        setIsMoving(false)
      }, 200)
    }
    // hoverEnd: () => {
    //   canDropRef.current = true
    // }
  })

  return { isDragging, onDragStart, onDragEnd, isHovering, dropRef, onHoverStart, onHoverEnd, onHover, onDrop }
}

function useViewer({ initialSelectedImages }: ViewerOptions) {
  // const [images, setImages] = useState(initialImages)
  const [selectedImages, setSelectedImages] = useState(initialSelectedImages)

  return { selectedImages, setSelectedImages }
}

export const ImageGallerySellPage = ({
  images,
  isMultipleUpload,
  onImageUploadChanged: onImageUploaded,
  onImageRemoved,
  onImagesSorted,
  className,
  uploading = false,
  direction = 'horizontal'
}: IImageGallery) => {
  const [imageBoxes, setImageBoxes, sortBoxes] = useGallery(images, initiateBoxList)
  const [isShowModal, setIsShowModal] = useState(false)
  const [galleryViewerImages, setGalleryViewerImages] = useState<string[]>(images)
  const viewer = useViewer({
    // initialImages: images,
    initialSelectedImages: []
  })

  const handleClick = (imageUrl: string) => {
    viewer.setSelectedImages([imageUrl])
    setIsShowModal(true)
  }

  useEffect(() => {
    setGalleryViewerImages(images)
  }, [isShowModal, images])

  const reOrderImage = () => {
    const imagesBox = imageBoxes.map((box) => box.url ?? '')
    return images.map((x, i) => (imagesBox.includes(x) ? imagesBox[i] : x))
  }

  return (
    <StyledGallery className={className}>
      <MotionDndProvider>
        <motion.div className={`grid mb-3${direction === 'vertical' ? ' square' : ''}`}>
          {onImageUploaded && (
            <StyledImageBox className="square item-big">
              <StyledImageBoxContent className="square-content">
                <div className="uploaded-container">
                  <FileUploader
                    placeholder={uploading ? 'Uploading' : 'Upload image'}
                    height="100%"
                    multiple={isMultipleUpload}
                    enableUploadSameFileTwice
                    onFilesChanged={onImageUploaded}
                    acceptFileType=".jpg,.jpeg,.png,.tiff,.webp,.gif,.mp4"
                  />
                </div>
              </StyledImageBoxContent>
            </StyledImageBox>
          )}
          {imageBoxes.map((imageBox) => (
            <SortableImageBox
              key={imageBox.uniqueKey}
              sortBoxes={sortBoxes}
              removeImage={onImageRemoved}
              dropCallBack={() => {
                onImagesSorted && onImagesSorted(reOrderImage())
              }}
              handleClick={handleClick}
              {...imageBox}
            />
          ))}
        </motion.div>
        <GalleryViewer
          uploading={uploading}
          visible={isShowModal}
          onHide={() => setIsShowModal(false)}
          images={galleryViewerImages}
          {...{
            isMultipleUpload,
            onImageUploadChanged: onImageUploaded,
            onImageRemoved
          }}
          onImagesSorted={(images: string[]) => {
            setImageBoxes(initiateBoxList(images))
            onImagesSorted?.(images)
          }}
          selectedImages={viewer.selectedImages}
          onSelectionChange={viewer.setSelectedImages}
        />
      </MotionDndProvider>
    </StyledGallery>
  )
}

const SortableImageBox = (props: ISortableImageBox) => {
  const { className, handleClick, url } = props
  const {
    isDragging,
    onDragStart,
    onDragEnd,
    // isHovering,
    // dropRef,
    onHoverStart,
    onHoverEnd,
    onHover
    // onDrop
  } = useSortableImageBox(props)

  const handleOnPan = (e: MouseEvent | TouchEvent | PointerEvent, info: any) => {
    const gridInDialog = document.querySelector('.grid-in-dialog')
    if (gridInDialog) {
      console.log(e)
      if (info.point.y - 20 < ((gridInDialog as any).getBoundingClientRect().top || 0)) {
        ;(gridInDialog as any).scrollBy({
          top: -300,
          left: 0,
          behavior: 'smooth'
        })
      }
      if (info.point.y + 20 > ((gridInDialog as any).getBoundingClientRect().bottom || 0)) {
        ;(gridInDialog as any).scrollBy({
          top: 300,
          left: 0,
          behavior: 'smooth'
        })
      }
    }
  }
  const handleOnPanThrottle = throttle(handleOnPan, 300)
  return (
    <StyledImageBox
      layout
      // ref={ref}
      onPan={handleOnPanThrottle}
      drag={props.type === 'active'}
      dragConstraints={{ left: 0, right: 0, top: 0, bottom: 0 }}
      dragElastic={1}
      onDragStart={onDragStart}
      onDragEnd={onDragEnd}
      onHoverStart={onHoverStart}
      onHoverEnd={onHoverEnd}
      onPointerMove={onHover}
      whileHover={{
        scale: 1.03,
        boxShadow: '0px 0px 0px rgba(0,0,0,0.15)'
      }}
      whileTap={{
        scale: 1.12,
        boxShadow: '0px 5px 5px rgba(0,0,0,0.1)'
      }}
      style={{
        zIndex: isDragging ? 3 : 1,
        ...(isDragging && {
          pointerEvents: 'none'
        })
      }}
      className={`square ${className ? className : ''}`}
      onClick={() => {
        handleClick && handleClick(url || '')
      }}
    >
      {renderImageBoxContent(props)}
    </StyledImageBox>
  )
}

function renderImageBoxContent(props: IImageBox) {
  const { order, url, type, remain, removeImage, selected } = props
  return (
    <StyledImageBoxContent className="square-content">
      {type === 'active' && (
        <div className={`image-container ${selected ? 'selected' : ''}`}>
          {url?.includes('.mp4') ? (
            <video preload="true" loop muted autoPlay width="100%" height="100%">
              <source src={url} type="video/mp4" />
            </video>
          ) : (
            <img src={url} alt={url} className="image" draggable="false" />
          )}
          <span className="order">{order}</span>
          {removeImage && (
            <Button
              className="remove-btn"
              onClick={(ev) => {
                ev.stopPropagation()
                removeImage(url)
              }}
            >
              <Icon src={iconPath('delete_regular')} fill="var(--white-100)" size="20px" />
            </Button>
          )}
        </div>
      )}
      {type === 'remain' && (
        <div className={`image-container`}>
          {url?.includes('.mp4') ? (
            <video preload="true" loop muted autoPlay width="100%" height="100%">
              <source src={url} type="video/mp4" />
            </video>
          ) : (
            <img src={url} alt={url} className="image" draggable="false" />
          )}
          <span className="remain">{remain}+</span>
        </div>
      )}
    </StyledImageBoxContent>
  )
}

const StyledImageBox = styled(motion.div)`
  cursor: pointer;

  &.square {
    position: relative;
    box-sizing: border-box;
    height: 0;
    padding-top: 100%;
    grid-column: span 5;
    grid-row: span 5;
  }

  &.item-big {
    grid-column: span 10;
    grid-row: span 10;
  }

  &.item-remain {
    .image {
      filter: brightness(80%);
    }
  }
`

const StyledImageBoxContent = styled.div`
  &.square-content {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
  }

  .uploaded-container {
    width: 100%;
    height: 100%;
  }

  .image-container {
    width: 100%;
    height: 100%;
    border: 2px solid var(--indigo-2);
    border-radius: var(--round-8);
    transition: var(--transition);
    &.selected {
      border: 3px solid var(--primary-100);
      &:hover {
        border: 3px solid var(--primary-100);
      }
    }

    &:hover {
      border: 2px solid var(--indigo-3);
      .order {
        background-color: var(--secondary-100);
      }
      button.button_.remove-btn {
        display: flex;
      }
    }
  }

  .image {
    border-radius: var(--round-8);
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .order {
    position: absolute;
    top: 10px;
    left: 10px;
    width: 24px;
    height: 24px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    color: var(--white-100);
    background-color: var(--secondary-100);
    border-radius: 50%;
    padding: 10px;
    transition: var(--transition);
  }

  button.button_.remove-btn {
    background-color: rgba(255, 56, 96, 0.6);
    border: 0;
    outline: none;
    color: var(--white-100);
    position: absolute;
    top: 10px;
    right: 10px;
    min-width: 0;
    width: 30px;
    height: 30px;
    display: none;
    padding: 0;

    &:hover {
      background-color: rgba(255, 56, 96, 1);
    }
  }

  .remain {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-family: var(--font-header);
    font-size: calc(2vw + 0.5rem);
    color: var(--white-100);
  }
`

const StyledGallery = styled.div`
  .grid {
    display: grid;
    grid-template-columns: repeat(35, minmax(0, 1fr));
    grid-template-rows: repeat(1, minmax(0, 1fr));
    grid-gap: 4px;

    &.square {
      grid-template-columns: repeat(35, minmax(0, 1fr));
      grid-template-rows: repeat(1, minmax(0, 1fr));
    }

    @media (max-width: 768px) {
      grid-template-columns: repeat(4, minmax(0, 1fr));
      grid-template-rows: repeat(4, minmax(0, 1fr));
    }
  }
`
