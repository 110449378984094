import { useState } from 'react'
import { Avatar } from './Avatar'
import { NameAvatar } from './NameAvatar'

export interface SmartAvatarProps {
  name?: string
  src?: string
  size?: 16 | 20 | 24 | 28 | 32 | 48 | 64
}

export const SmartAvatar = ({ name, src, size }: SmartAvatarProps) => {
  const [showAvatar, setShowAvatar] = useState<boolean>(true)
  const onLoadAvatarError = () => {
    setShowAvatar(false)
  }
  return showAvatar ? (
    <Avatar src={src || ''} onError={onLoadAvatarError} size={size} />
  ) : (
    <NameAvatar name={name || ''} length={2} size={size} />
  )
}
