import { AxiosDefault, privateRouters } from 'com-ui2/utilities'
import { ExtProtectRoute, withAuthCore } from 'com-ui/hocs'
import { PublisherProps, setPublisher } from 'src/containers/settings/store'

import { Store } from 'redux'

const _axios = AxiosDefault()

const getPublisher = async (store: Store) => {
  let publisher = (store.getState()?.publisher || {}) as PublisherProps
  let apiStatus = null
  if (!publisher?.id) {
    try {
      const result = await _axios.get(`/cms/shop/public/publisher_info`)
      apiStatus = result.status
      publisher = {
        ...(result?.data || {}),
        apiStatus
      }
      result?.data && (await store.dispatch(setPublisher(publisher)))
    } catch (error) {
      apiStatus = error?.response?.status
      console.log(error)
      publisher = {
        ...(publisher || {}),
        apiStatus
      }
    }
  }
  return publisher
}

const extProtectRoute: ExtProtectRoute = async ({ store, router, pathname }) => {
  let redirect = null
  if (!store || !router) {
    redirect
  }
  const roles = store?.getState?.().profile?.roles
  // roles = null -> user is not active
  if (roles && !roles.includes('publisher') && privateRouters(pathname)) {
    return '/logout'
  }
  const publisher: PublisherProps = await getPublisher(store)
  const status = publisher?.apiStatus ?? null
  if (privateRouters(pathname)) {
    if (status == 404) {
      // not have profile
      redirect = '/sellers/signup-1'
    } else if (status == 201) {
      redirect = ''
    }
  } else if (pathname == '/sellers/signup-1' || pathname == '/sellers/signup-2') {
    // already signup
    if (status == 200) {
      redirect = '/'
    } else if (status == 201) {
      redirect = ''
    }
  }
  return redirect
}

export const withAuthPublisher = withAuthCore({ extProtectRoute })
