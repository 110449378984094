import { useEffect, useMemo, useState } from 'react'

import { isRequire, validateField } from 'com-ui2/hooks'

import { InputText } from 'com-ui2/controls'
import { ValidateControlProps } from './ValidateControl'
import css from 'styled-jsx/css'
import { ReactNode } from 'react'

export interface SwitchControlProps extends ValidateControlProps {
  editting?: boolean
  align?: 'center' | 'top'
  col?: number
  classLabel?: string
  labelChidrent?: ReactNode
}

export const SwitchControl = (props: SwitchControlProps) =>
  useMemo(() => <Control {...props} />, [props.value, props.editting, props.require])

function Control({
  title,
  placeholder,
  value,
  onChange,
  editor,
  editting,
  align = 'center',
  require,
  requireType = 'star',
  className,
  col = 6,
  validators = [],
  classLabel,
  labelChidrent
}: SwitchControlProps) {
  const [isTouched, setTouched] = useState(false)
  validators = require ? [isRequire(), ...validators] : validators
  const error = isTouched ? validateField(value, title, validators) : null
  const defaultEditor = <InputText value={value ?? ''} onChange={onChange} placeholder={placeholder} />

  useEffect(() => {
    setTouched(false)
  }, [editting])

  return (
    <div
      className={`row
      ${align === 'center' ? 'align-items-center' : ''}
      ${!editting ? 'my-4' : 'mb-2'}
      ${className ? className : ''}`}
    >
      <div className={`col-${col}`}>
        <div className={`text--capitalize ${classLabel || 'body-2b'}`}>
          {labelChidrent ? (
            <div className="d-flex align-items-center">
              {title}
              {!!labelChidrent && labelChidrent}
            </div>
          ) : (
            title
          )}
          {!require && requireType === 'optional' ? <span className="text--primary ml-1">(optional)</span> : null}
          {require && requireType === 'star' ? '*' : null}
          {editting && error && <div className="error-message">{error}</div>}
        </div>
      </div>
      <div className={`col-${12 - col}`}>
        {!editting ? (
          <div className="pl-3 text--gray">{value}</div>
        ) : (
          <div className={`${error ? 'error-control' : ''}`} onBlur={() => setTouched(true)}>
            {editor || defaultEditor}
          </div>
        )}
      </div>
      <style jsx>{style}</style>
    </div>
  )
}

const style = css`
  .error-control {
    border: 1px solid var(--red-500);
    border-radius: 4px;
    :global(.p-inputgroup),
    :global(.p-inputgroup.focus),
    :global(.p-dropdown),
    :global(.p-multiselect),
    :global(.p-inputnumber),
    :global(.p-inputmask),
    :global(.p-inputtext),
    :global(.input-country),
    :global(.input-phone) {
      &,
      &:hover,
      &:focus,
      &:active {
        box-shadow: none;
        border: none;
      }
    }
  }
`
