import { nameColor } from 'com-ui2/utilities'

export interface NameAvatarProps {
  name: string
  length?: 1 | 2
  backgroundColor?: string
  size?: 16 | 20 | 24 | 28 | 32 | 48 | 64
  className?: string
  borderRadius?: string
}

export const NameAvatar = ({
  name,
  length = 1,
  backgroundColor = nameColor(name),
  size = 32,
  className,
  borderRadius = '50%'
}: NameAvatarProps) => {
  const shortName = length == 1 ? name[0] : shortenName(name)
  return (
    <div className={`name-avatar ${className || ''}`}>
      <span>{shortName}</span>
      <style jsx>
        {`
          .name-avatar {
            height: ${size}px;
            width: ${size}px;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            background-color: ${backgroundColor};
            border-radius: ${borderRadius};
            color: var(--white-100);
            font-weight: 600;
            text-transform: uppercase;
          }
        `}
      </style>
    </div>
  )
}

const shortenName = (name: string) =>
  name
    .split(' ')
    ?.map((e: string, i: number, a: string[]) => (i == 0 || i == a.length - 1 ? e[0] : ''))
    ?.join('')
