import { UploadType } from 'src/services'
import getConfig from 'next/config'

export enum PageType {
  sellpages = 'sellpages',
  products = 'products',
  stores = 'stores',
  publisher = 'documents/publisher',
  sellpageVersions = 'sellpage_versions'
}

const apiBasePath = `${getConfig().publicRuntimeConfig.apiBasePath}file/cms/view?path=`
const videoBasePath = `${getConfig().publicRuntimeConfig.cdnBasePathMp4}/t/c/v/`

const getRootImagePath = (type: PageType, id: string) => `${apiBasePath}${type}/${id}/`

export const sellPageImageBuilder = (
  sellpageId: string,
  imageSource: string,
  imageType?: UploadType,
  pageType = PageType.sellpages
) =>
  imageSource && sellpageId
    ? `${getRootImagePath(pageType, sellpageId)}${imageType ? `${imageType}/` : ''}${
        imageType
          ? imageSource.startsWith(`${imageType}/`)
            ? imageSource.replace(`${imageType}/`, '')
            : imageSource
          : imageSource
      }`
    : ''

export const sellPageExtractImageSource = (
  sellpageId: string,
  imageSource?: string,
  uploadType?: UploadType,
  pageType: PageType = PageType.sellpages
) =>
  imageSource
    ? imageSource.replace(`${getRootImagePath(pageType, sellpageId)}${uploadType ? `${uploadType}/` : ''}`, '')
    : ''

export const videoBuilderSource = (imageSource: string) => {
  return `${videoBasePath}${imageSource.replaceAll(videoBasePath, '')}` || ''
}

export const extractVideoSource = (imageSource: string) => {
  return imageSource?.replace(videoBasePath, '') || ''
}

export const productImageBuilder = (productId: string, imageSource: string, imageType?: UploadType) =>
  // imageSource && productId ? `${apiBasePath}products/${productId}/${imageSource}` : ''
  imageSource && productId
    ? `${getRootImagePath(PageType.products, productId)}${imageType ? `${imageType}/` : ''}${
        imageType
          ? imageSource.startsWith(`${imageType}/`)
            ? imageSource.replace(`${imageType}/`, '')
            : imageSource
          : imageSource
      }`
    : ''

export const publisherImageBuilder = (storeId: string, imageSource: string, imageType?: UploadType) =>
  // imageSource && productId ? `${apiBasePath}products/${productId}/${imageSource}` : ''
  imageSource && storeId
    ? `${getRootImagePath(PageType.publisher, storeId)}${imageType ? `${imageType}/` : ''}${
        imageType
          ? imageSource.startsWith(`${imageType}/`)
            ? imageSource.replace(`${imageType}/`, '')
            : imageSource
          : imageSource
      }`
    : ''
