import { applyMiddleware, compose, createStore } from 'redux'

import { composeWithDevTools } from 'redux-devtools-extension'
import { createWrapper } from 'next-redux-wrapper'
import { rootReducer } from 'fast-redux'
import thunkMiddleware from 'redux-thunk'
import getConfig from 'next/config'

export const AppAction = {
  RESET_APP: 'RESET_APP'
}
const debugRedux = process.browser ? localStorage.getItem('debugRedux') : false
const isProduction = getConfig().publicRuntimeConfig.defaultDomain?.includes('.com')
const appReducer = (state: any, action: any) => {
  switch (action.type) {
    // case HYDRATE:
    //   return {
    //     ...state, // use previous state
    //     ...action.payload // apply delta from hydration
    //   }
    case AppAction.RESET_APP:
      return {}
    default:
      return rootReducer(state, action)
  }
}

const initStore = () => {
  const composerEnhancer = !debugRedux && isProduction ? compose : composeWithDevTools({ trace: true, traceLimit: 25 })
  return createStore(appReducer, composerEnhancer(applyMiddleware(thunkMiddleware)))
}

export const withRedux = (component: React.ReactNode) => createWrapper(initStore).withRedux(component)
