import { PanInfo } from 'framer-motion'
import { useState } from 'react'
import { useMotionDndContext } from './dnd-context'
import { DragSetting, MotionDragEvent } from './types'

export function useMotionDrag({ item, canDrag = () => true, begin = () => null, end = () => null }: DragSetting) {
  const { setDraggingItem } = useMotionDndContext()
  const [isDragging, setDragging] = useState(false)

  const onDragStart = (event?: MotionDragEvent, info?: PanInfo) => {
    if (canDrag()) {
      setDragging(true)
      setDraggingItem(item)
      begin(event, info)
    }
  }

  const onDragEnd = (event?: MotionDragEvent, info?: PanInfo) => {
    setDragging(false)
    end(event, info)
    // hoveringItem && drop && drop(hoveringItem) //Drop Action
    setDraggingItem(null)
  }

  return { isDragging, onDragStart, onDragEnd }
}
