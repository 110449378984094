import { FormEvent, useEffect, useState } from 'react'
import { IInputGroup, InputGroup, InputText } from 'com-ui2/controls'

import { Icon } from 'com-ui2/components'
import { InputTextProps } from 'primereact/inputtext'
import { iconPath } from 'com-ui/utilities/imagePath'

interface InputSearchProps extends InputTextProps {
  inputSubmit?: () => void
  inputGroupProps?: IInputGroup
}

export function InputSearch({
  className,
  placeholder,
  onChange,
  inputSubmit,
  inputGroupProps,
  ...props
}: InputSearchProps) {
  const [value, setValue] = useState(props.value || '')
  const _onChange = (e: FormEvent<HTMLInputElement>) => {
    const val = (e.target as any).value
    setValue((_val) => (_val === val ? _val : val))
    onChange?.(e)
  }
  useEffect(() => {
    if (value !== props.value) {
      setValue(props.value ?? '')
    }
  }, [props.value])
  return (
    <>
      <InputGroup
        suffix={<Icon src={iconPath('search_regular')} size="24px" fill="var(--gray-7)" onClick={inputSubmit} />}
        prefixDelimiter={false}
        suffixDelimiter={false}
        className={`input-search-wrapper ${className ?? ''}`}
        error={inputGroupProps?.error}
        title={inputGroupProps?.title}
      >
        <InputText
          {...props}
          placeholder={placeholder}
          onChange={_onChange}
          value={value}
          onKeyUp={(e) => e.key === 'Enter' && inputSubmit?.()}
        />
      </InputGroup>
      <style jsx>
        {`
          :global(.input-search-wrapper .suffix) {
            padding-left: 0;
          }
        `}
      </style>
    </>
  )
}
