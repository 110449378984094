import React, { useState } from 'react'
import css from 'styled-jsx/css'
import { Icon } from 'com-ui2/components'
import { iconPath } from 'com-ui/utilities/imagePath'
import { CrModal } from 'com-ui/components/modal'
import { mergeStyleSets } from '@fluentui/react'
import { CrButton } from 'com-ui/controls'

import { YoutubeEmbed } from './YoutubeEmbed'

const functionConvertTimeToMinutes = (time: number) => {
  const minutes = Math.floor(time / 60)
  const minute = minutes < 10 ? `0${minutes}` : minutes
  const seconds = time - minutes * 60 < 10 ? `0${time - minutes * 60}` : time - minutes * 60

  return `${minute}:${seconds}`
}

export const LearningItem = (props: any) => {
  const { learning } = props

  const [isModalOpen, setIsModalOpen] = useState(false)

  const [fill, setFill] = useState('var(--gray-7)')

  const hideModal = () => {
    setIsModalOpen(false)
  }

  const handleOpenModal = () => {
    setIsModalOpen(true)
  }

  return (
    <div>
      <div
        className="box-item subtitle-2"
        onClick={handleOpenModal}
        onMouseOver={() => setFill('var(--primary-100)')}
        onMouseOut={() => setFill('var(--gray-7)')}
        onFocus={() => void 0}
        onBlur={() => void 0}
      >
        <div className="icon-and-title">
          <div className="icon-play mr-3 d-flex align-items-center">
            <Icon src={iconPath('play_circle_regular')} className="icon" size="32px" fill={fill} />
          </div>

          <div>{learning?.title}</div>
        </div>

        <div>{functionConvertTimeToMinutes(learning?.time)}</div>
      </div>
      <CrModal
        isOpen={isModalOpen}
        contentStyle={ModalStyle}
        onClose={hideModal}
        onDismiss={hideModal}
        isBlocking={false}
        title=""
      >
        <YoutubeEmbed embedId={learning?.link} width="560" height="315" />
        <CrButton
          onClick={hideModal}
          style={{
            color: 'var(--white-100)',
            border: 'none',
            backgroundColor: 'transparent',
            position: 'fixed',
            top: '52px',
            right: '52px'
          }}
        >
          <Icon src={iconPath('dismiss_filled')} size="24px" fill="var(--white-100)" />
        </CrButton>
      </CrModal>
      <style jsx>{LearningItemStyle}</style>
    </div>
  )
}

const LearningItemStyle = css`
  .box-item {
    background: #ffffff;
    box-shadow: 0px 3.2px 7.2px rgba(101, 115, 129, 0.13);
    border-radius: 8px;
    color: var(--gray-8);

    width: 100%;
    height: 80px;
    padding: 24px 18px;

    display: flex;
    justify-content: space-between;
    align-items: center;

    cursor: pointer;
  }

  .box-item:hover {
    color: var(--primary-100);
  }

  .icon-and-title {
    display: flex;
    align-items: center;
  }

  :global(.ms-Overlay) {
    background-color: rgba(0, 0, 0, 0.8);
  }
  :global(.ms-Overlay--dark) {
    background-color: rgba(0, 0, 0, 0.8);
  }
`

const ModalStyle = mergeStyleSets({
  body: {
    fontSize: 16,
    lineHeight: 24,
    padding: 0
  },
  container: {
    // width: '80vw',
    minWidth: 1000,
    maxWidth: 'none',
    borderRadius: 0
  },
  footer: {
    justifyContent: 'center',
    marginTop: 32
  }
})
