import { ProductViewInList } from 'src/services'
import React from 'react'
import css from 'styled-jsx/css'
import { formatUSD } from 'com-ui/utilities/currency'

interface CostDetailProps {
  product: ProductViewInList
  paymentFee: Number
  sellessIoFee: Number
  isAccTax?: boolean
}
export default function CostDetail(props: CostDetailProps) {
  const { product, paymentFee, sellessIoFee, isAccTax } = props
  const sellesIOFeeValue = `(${product?.percent_selless_fee || 0}% + ${formatUSD(
    product.fix_selless_fee?.toFixed(2) || 0
  )})`
  const paymentFeeValue = `(${product?.percent_payment_fee || 0}% + ${formatUSD(
    product?.fix_payment_fee?.toFixed(2) || 0
  )})`
  return (
    <div className="d-flex flex-column content-dialog">
      {/* <div className="content-dialog__item d-flex">
        <span className="body-2b">Product Cost:</span>
        <strong className="body-2">{`${formatUSD(product.product_cost?.toFixed(2) || 0)}`}</strong>
      </div>
      <div className="content-dialog__item d-flex">
        <span className="body-2b">Fulfillment Cost:</span>
        <strong className="body-2">{`${formatUSD(product?.fulfillment_cost?.toFixed(2) || 0)}`}</strong>
      </div> */}
      <span className="body-2b pl-2">Calculated based on AOV</span>
      <div className="content-dialog__item d-flex">
        <span className="body-2b">Base cost</span>
        <strong className="body-2">{`${(((product?.base_cost || 0) / (product.aov || 0)) * 100).toFixed(2)}%`}</strong>
      </div>
      {isAccTax && (
        <div className="content-dialog__item d-flex">
          <span className="body-2b">Estimated sales tax</span>
          <strong className="body-2">{`${formatUSD((product?.estimated_tax || 0).toFixed(2)) || 0}`}</strong>
        </div>
      )}
      <div className="content-dialog__item d-flex">
        <span className="body-2b">Payment Fee:</span>
        <span>
          <span className="caption">{paymentFeeValue}</span>
          <strong className="body-2">{`${formatUSD(paymentFee?.toFixed(2) || 0)} `}</strong>
        </span>
      </div>
      <div className="content-dialog__item d-flex">
        <span className="body-2b">Selless fee:</span>
        <span>
          <span className="caption">{sellesIOFeeValue}</span>
          <strong className="body-2">{`${formatUSD(sellessIoFee?.toFixed(2) || 0)} `}</strong>
        </span>
      </div>
      <style jsx>{styleDialog}</style>
    </div>
  )
}

const styleDialog = css`
  .content-dialog {
    width: 320px;
    font-size: 14px;
    font-weight: 400;

    &__item {
      padding: 4px 8px;
      justify-content: space-between;
      strong {
        color: var(--blue-300);
      }
      .caption {
        color: var(--indigo-7);
        margin-right: 8px;
      }
    }
  }
`
