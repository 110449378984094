import * as React from 'react'

import { IButtonStyles, IIconProps, IModalProps, IProcessedStyleSet, Modal, mergeStyleSets } from '@fluentui/react'

import { CrActionButton } from 'com-ui/controls/action-button'
import HeaderText from './header-text'

// Long content
const contentStyles = mergeStyleSets({
  container: {
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'stretch',
    maxWidth: 720,
    minHeight: 'unset',
    borderRadius: 'var(--round-16)',
    '.ms-Dialog-actions': {
      marginTop: 32
    }
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    position: 'relative',
    marginBottom: 16,

    h4: {
      display: 'flex',
      alignItems: 'center',
      lineHeight: 40
    }
  },
  body: {
    flex: '4 4 auto',
    padding: 48,
    p: {
      textAlign: 'justify'
    }
  },
  footer: {
    marginTop: 32,
    display: 'flex'
  }
})
const closeButtonStyles: IButtonStyles = {
  root: {
    position: 'absolute',
    right: 0,
    color: 'unset',
    minWidth: 'unset',

    i: {
      fontSize: 24,
      lineHeight: 24
    }
  }
}

interface IModal extends IModalProps {
  title: string | JSX.Element | undefined
  onClose: () => void
  footerButton?: JSX.Element | null
  contentStyle?: IProcessedStyleSet<{ container?: any; body?: any; header?: any; footer?: any }>
  closeBtnStyle?: IButtonStyles
}

export interface ICrModal {
  isModalOpen: boolean
  showModal: () => void
  hideModal: () => void
}

export const CrModal: React.FC<IModal> = ({ ...props }) => {
  const cancelIcon: IIconProps = { iconName: 'dismiss_24_filled' }

  function getClassName(type: 'container' | 'header' | 'body' | 'footer') {
    const cntStyle = props.contentStyle
    if (cntStyle && cntStyle[type]) {
      return `${contentStyles[type]} ${cntStyle[type]}`
    }

    return contentStyles[type]
  }

  return (
    <>
      <Modal {...props} containerClassName={getClassName('container')}>
        <div className={getClassName('body')}>
          {props.title ? (
            <div className={getClassName('header')}>
              <HeaderText Tag="h4" fontSize="24">
                {props.title}
              </HeaderText>
              <CrActionButton
                className="p-0"
                styles={props.closeBtnStyle || closeButtonStyles}
                iconProps={cancelIcon}
                ariaLabel="Close popup modal"
                onClick={props.onClose}
                sizing="small"
              />
            </div>
          ) : null}
          {props.children}
          {props.footerButton && <div className={getClassName('footer')}>{props.footerButton}</div>}
        </div>
      </Modal>
    </>
  )
}
