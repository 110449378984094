import { Dialog, DialogProps } from 'primereact/dialog'
import {
  IImageBox,
  IImageGallery,
  SortableImageBox,
  StyledImageBox,
  StyledImageBoxContent,
  useGallery
} from './ImageGallery'

import { FileUploader } from 'com-ui2/controls'
import React, { useEffect, useRef } from 'react'
import css from 'styled-jsx/css'
import { motion } from 'framer-motion'
import styled from 'styled-components'

export interface IGalleryViewer extends DialogProps, IImageGallery {
  title?: string
  selectionMode?: 'single' | 'multiple'
  selectedImages?: string[]
  onSelectionChange: (selectedImages: string[]) => void
  uploading?: boolean
}

export function initiateBoxList(images: string[]): IImageBox[] {
  if (!images || images.length === 0) {
    return []
  }

  return images.map((image, i) => {
    return {
      uniqueKey: Math.floor(Math.random() * 100000000),
      order: i + 1,
      url: image,
      type: 'active',
      className: '',
      remain: undefined
    }
  })
}

export const GalleryViewer: React.FC<IGalleryViewer> = (props) => {
  const [imageBoxes, , sortBoxes] = useGallery(props.images, initiateBoxList, false)
  const videoRef = useRef<HTMLVideoElement | null>(null)

  const handleClick = (imageUrl: string) => {
    props?.onSelectionChange?.([imageUrl])
  }

  let previewImage = props?.selectedImages && props.selectedImages.length > 0 ? props.selectedImages[0] : ''
  if (!props?.images.includes(previewImage)) {
    previewImage = props?.images.length ? props?.images[0] : ''
  }

  useEffect(() => {
    if (videoRef?.current && previewImage?.includes('.mp4')) {
      videoRef?.current?.load()
      videoRef?.current?.play()
    }
  }, [previewImage])

  return (
    <Dialog
      visible={props.visible}
      onHide={props.onHide}
      blockScroll={true}
      // isBlocking={false}
      header={props.title || 'Gallery'}
      contentStyle={modalStyle}
      contentClassName={className}
    >
      <StyledGalleryViewer>
        <StyledImageBox className="square">
          <StyledImageBoxContent className="square-content">
            <div className={`image-container position-relative`}>
              <div className="count-image">
                {(props?.images.indexOf(previewImage) || 0) + 1} / {props?.images.length || 0}
              </div>
              {previewImage.includes('.mp4') ? (
                <video preload="true" loop muted autoPlay width="100%" height="100%" ref={videoRef}>
                  <source src={previewImage} type="video/mp4" />
                </video>
              ) : (
                <img src={previewImage} alt={previewImage} className="image" draggable="false" />
              )}
            </div>
          </StyledImageBoxContent>
        </StyledImageBox>
        <StyledGrid>
          <motion.div className="grid grid-in-dialog">
            {props.onImageUploadChanged && (
              <StyledImageBox className="square">
                <StyledImageBoxContent className="square-content">
                  <div className="uploaded-container">
                    <FileUploader
                      placeholder={props?.uploading ? 'Uploading' : 'Upload image'}
                      height="100%"
                      multiple={props.isMultipleUpload}
                      enableUploadSameFileTwice
                      onFilesChanged={props.onImageUploadChanged}
                      acceptFileType=".jpg,.jpeg,.png,.tiff,.webp,.gif,.mp4"
                    />
                  </div>
                </StyledImageBoxContent>
              </StyledImageBox>
            )}
            {imageBoxes.map((imageBox) => (
              <SortableImageBox
                key={imageBox.uniqueKey}
                {...imageBox}
                sortBoxes={sortBoxes}
                removeImage={props.onImageRemoved}
                dropCallBack={() => {
                  props.onImagesSorted && props.onImagesSorted(imageBoxes.map((box) => box.url ?? ''))
                }}
                handleClick={handleClick}
                selected={imageBox.url ? props.selectedImages?.includes(imageBox.url) : false}
              />
            ))}
          </motion.div>
        </StyledGrid>
      </StyledGalleryViewer>
      {styles}
    </Dialog>
  )
}

const modalStyle = {
  fontSize: 16,
  lineHeight: '24px',
  width: '80vw',
  minWidth: 1000,
  maxWidth: 'none'
}

const { className, styles } = css.resolve`
  .p-dialog-content {
    display: block;
  }
  :global(.count-image) {
    position: absolute;
    bottom: 10px;
    left: 10px;
    background: var(--white-100);
    border: 1px solid var(--indigo-3);
    box-sizing: border-box;
    border-radius: 16px;
    font-weight: 600;
    font-size: 13px;
    line-height: 24px;
    color: var(--indigo-8);
    padding: 4px 16px;
  }
`

export const StyledGalleryViewer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-gap: 16px;
`

export const StyledGrid = styled.div`
  position: relative;
  box-sizing: border-box;
  height: 0;
  padding-top: 100%;

  .grid {
    display: grid;
    grid-template-columns: repeat(4, minmax(0, 1fr));
    grid-template-rows: min-content;
    grid-gap: 8px;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    overflow: hidden scroll;
    padding: 5px;
  }
`
