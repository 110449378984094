import { createContext, ReactNode, useContext, useState } from 'react'
import { DragItem, IMotionDndContext } from './types'

export const MotionDndContext = createContext<IMotionDndContext>({
  draggingItem: undefined,
  setDraggingItem: () => undefined,
  isMoving: false,
  setIsMoving: () => undefined
})

export const MotionDndProvider = ({ children }: { children: ReactNode }) => {
  const [draggingItem, setDraggingItem] = useState<DragItem | undefined>(undefined)
  const [isMoving, setIsMoving] = useState<boolean>(false)
  return (
    <MotionDndContext.Provider value={{ draggingItem, setDraggingItem, isMoving, setIsMoving }}>
      {children}
    </MotionDndContext.Provider>
  )
}

export const useMotionDndContext = () => {
  return useContext(MotionDndContext)
}
