import { namespaceConfig } from 'fast-redux'
import { user } from 'com-ui-biz/profile/services'

export interface ProfileProps extends user {
  loading?: boolean
  clientLastUpdate?: Date | number | string
  allowerPath?: { [path: string]: boolean }
}

const DEFAULT_STATE: ProfileProps = {}

const { action, getState } = namespaceConfig('profile', DEFAULT_STATE)

export const getProfileState = (state: ProfileProps): ProfileProps | undefined => getState(state)
export const getProfileLoadingState = (state: ProfileProps): boolean | undefined => getProfileState(state)?.loading
export const getProfileRolesState = (state: ProfileProps): string[] | undefined => getProfileState(state)?.roles
export const getLoginProviderState = (state: ProfileProps): string | undefined => getProfileState(state)?.provider
export const getAllowedPathState = (state: ProfileProps): { [path: string]: boolean } | undefined =>
  getProfileState(state)?.allowerPath

export const setProfile = action('setProfile', function (state: ProfileProps, profile?: user) {
  const nextState: ProfileProps = {
    ...state,
    ...(profile || {}),
    clientLastUpdate: new Date().getTime()
  }
  return nextState
})

export const setLoadingProfile = action('setLoadingProfile', function (state: ProfileProps, loading: boolean) {
  if (state.loading == loading) {
    return state
  }
  const nextState = {
    ...state,
    loading
  }
  return nextState
})

export const addAllowedPath = action('addAllowedPath', (state: ProfileProps, path: string) => {
  if (!path || state?.allowerPath?.[path]) return state
  const nextState = {
    ...state,
    allowerPath: {
      ...(state.allowerPath || {}),
      [path]: true
    }
  }
  return nextState
})

export const clearAllowedPath = action('clearAllowedPath', (state: ProfileProps) => {
  const nextState = {
    ...state,
    allowerPath: {}
  }
  return nextState
})
