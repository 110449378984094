import { AxiosDefault } from 'com-ui2/utilities'

export const downloadFileWithAxios = async (url: string, fileName: string) => {
  // Create downloadable link and download that file (https://gist.github.com/javilobo8/097c30a233786be52070986d8cdb1743)
  const file: Blob = await AxiosDefault()
    .get(url, { responseType: 'blob' })
    .then((response) => response.data)
  const href = URL.createObjectURL(file)
  const link = document.createElement('a')
  link.href = href
  link.download = fileName ?? 'download'

  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}
