import { CrTextViewer } from './text-field'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import styled from 'styled-components'
import useDebounce from '../hooks/useDebounce'
import useSchema from '../hooks/useSchema'

export default function CrField(props: Types.ICrField) {
  const { validator, schemaProps } = useMemo(() => useSchema(props.schema), [props.schema])
  props = { ...schemaProps, ...props }
  const [value, setValue] = useState(props.initValue)
  const [error, setError] = useState<string>('')
  const [editable, setEditable] = useState(props.editable ?? false)
  const debounceValue = useDebounce(value, 200)
  const delaySwitch = useDebounce(value, 5000)
  const viewerRef = useRef(null)

  useEffect(() => {
    function handleContains(event: React.MouseEvent<HTMLDivElement, MouseEvent> | any) {
      if (props.editable) {
        return
      }
      if (props.delayEditable) {
        return
      }
      const current: any = viewerRef.current
      const contain = current && current.contains(event.target)
      setEditable(contain)
    }
    document && document.addEventListener('mousedown', handleContains)
    document && document.addEventListener('focus', handleContains, true)
    return () => {
      document && document.removeEventListener('mousedown', handleContains)
      document && document.removeEventListener('focus', handleContains, true)
    }
  }, [props.editable])

  useEffect(() => {
    props?.setFormValue?.(debounceValue)
    setError(doValidate(validator, debounceValue))
  }, [debounceValue])

  useEffect(() => {
    setEditable(false)
  }, [delaySwitch])

  useEffect(() => {
    props?.setFormError?.(error)
  }, [error])

  useEffect(() => {
    setEditable(props.editable ?? false)
  }, [props.editable])

  const editorProps = {
    ...props,
    value,
    setValue,
    errorMessage: error
  }

  const viewerProps = {
    label: props.label,
    value,
    onClick: () => setEditable(true)
  }

  const defaultViewer = (
    <CrTextViewer label={props.label}>{Array.isArray(value) ? value.join(', ') : value}</CrTextViewer>
  )

  return useMemo(
    () => (
      <FieldContainer ref={viewerRef} tabIndex={0} /* onFocus={() => setEditable(true)} */>
        {editable ? (
          props?.editor?.(editorProps)
        ) : (
          <ViewerContainer onClick={() => setEditable(true)}>
            {props?.viewer?.(viewerProps) || defaultViewer}
          </ViewerContainer>
        )}
      </FieldContainer>
    ),
    [value, error, editable]
  )
}

function doValidate(validator: any, value: any) {
  if (!validator) return ''
  const valid = validator(value)
  if (valid) return ''
  const error = (validator?.errors || []).map((e: any) => e.message).join(' & ')
  return error
}

const FieldContainer = styled.div`
  transition: background-color 0.3s ease;
  padding: 5px 10px;
  &:focus {
    outline: none;
  }
  &:hover {
    background-color: #ebf0fe;
  }
`
const ViewerContainer = styled.div`
  cursor: pointer;
  * {
    pointer-events: none;
  }
`
