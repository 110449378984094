import { IGalleryViewer, StyledGalleryViewer, StyledGrid } from './GalleryViewer'
import React, { useEffect, useState } from 'react'
import { StyledImageBox, StyledImageBoxContent } from './ImageGallery'

import { Button } from 'com-ui2/controls'
import { Dialog } from 'primereact/dialog'

export interface ISelectBox {
  url: string
  selected?: boolean
  handleClick?: (imageUrl: string) => void
}

export const GallerySelector: React.FC<IGalleryViewer> = (props) => {
  const [selectedImages, setSelectedImages] = useState<string[]>([])

  const onImageClicked = (imageUrl: string) => {
    setSelectedImages([imageUrl])
  }

  const onConfirm = () => {
    props.onSelectionChange(selectedImages)
    props.onHide()
  }

  const previewImage = selectedImages && selectedImages.length > 0 ? selectedImages[0] : ''

  useEffect(() => {
    if (props.visible) {
      setSelectedImages(props.selectedImages ?? [])
    }
  }, [props.visible])

  return (
    <Dialog
      visible={props.visible}
      blockScroll={true}
      onHide={props.onHide}
      // isBlocking={false}
      header="Choose Image"
      contentStyle={modalStyle}
    >
      <StyledGalleryViewer>
        <StyledImageBox className="square">
          <StyledImageBoxContent className="square-content">
            <div className={`image-container`}>
              {previewImage.includes('.mp4') ? (
                <video loop muted autoPlay preload="true" width="100%" height="100%">
                  <source src={previewImage} type="video/mp4" />
                </video>
              ) : (
                <img src={previewImage} alt={previewImage} className="image" draggable="false" />
              )}
            </div>
          </StyledImageBoxContent>
        </StyledImageBox>
        <StyledGrid>
          <div className="grid">
            {props.images.map((image, i) => {
              if (image?.includes('.mp4')) return ''
              else
                return (
                  <ImageBox
                    key={i}
                    url={image}
                    handleClick={onImageClicked}
                    selected={image ? selectedImages?.includes(image) : false}
                  />
                )
            })}
          </div>
        </StyledGrid>
      </StyledGalleryViewer>
      <div className="d-flex justify-content-end">
        <Button type="primary" onClick={onConfirm}>
          Confirm
        </Button>
      </div>
    </Dialog>
  )
}

export const ImageBox = (props: ISelectBox) => {
  const { handleClick, url, selected } = props

  return (
    <StyledImageBox
      whileHover={{
        scale: 1.03,
        boxShadow: '0px 3px 3px rgba(0,0,0,0.15)'
      }}
      whileTap={{
        scale: 1.12,
        boxShadow: '0px 5px 5px rgba(0,0,0,0.1)'
      }}
      className={`square`}
      onClick={() => {
        handleClick && handleClick(url || '')
      }}
    >
      <StyledImageBoxContent className="square-content">
        <div className={`image-container ${selected ? 'selected' : ''}`}>
          {url?.includes('.mp4') ? (
            <video loop preload="true" muted autoPlay width="100%" height="100%">
              <source src={url} type="video/mp4" />
            </video>
          ) : (
            <img src={url} alt={url} className="image" draggable="false" />
          )}
        </div>
      </StyledImageBoxContent>
    </StyledImageBox>
  )
}

const modalStyle = {
  fontSize: 16,
  lineHeight: '24px',
  width: '80vw',
  minWidth: 1000,
  maxWidth: 'none',
  display: 'block'
}
