import SVG, { Props } from 'react-inlinesvg'

export interface IImgSvg extends Props {
  width?: string
  height?: string
}

export function ImgSvg({ src, width, height, className, ...props }: IImgSvg) {
  const style = {
    width,
    height
  }

  return (
    <>
      <SVG
        src={src}
        className={`${className || ''}`}
        uniquifyIDs={true}
        {...props}
        style={{ ...style, ...props.style }}
      />
    </>
  )
}
