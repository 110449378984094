import { useRef, useState } from 'react'

import css from 'styled-jsx/css'
import { useUpdateEffect } from '../hooks'

export interface ICollapse extends React.AllHTMLAttributes<HTMLElement> {
  open?: boolean
  initHeight?: string
}

export const Collapse = ({ open = false, initHeight = '0px', children, className }: ICollapse) => {
  const ref = useRef(null)
  const [height, setHeight] = useState(initHeight)
  useUpdateEffect(() => {
    const _height = (ref.current as any).scrollHeight || initHeight
    setHeight(`${_height}px`)
    const effectTime = open ? 350 : 30
    setTimeout(() => {
      setHeight(open ? 'auto' : initHeight)
    }, effectTime)
  }, [open])
  return (
    <>
      <div
        className={`wrapper ${className || ''}`}
        ref={ref}
        style={{ height, opacity: open ? 1 : 0, visibility: open ? 'visible' : 'hidden' }}
      >
        {children}
        <style jsx>{collapseStyle}</style>
      </div>
    </>
  )
}

const collapseStyle = css`
  .wrapper {
    visibility: hidden;
    opacity: 0;
    height: 0;
    overflow: hidden;
    transition: height var(--t-normal), opacity var(--t-normal), visibility var(--t-normal);
  }
`
