import { Loading, PublishStatus } from 'com-ui2/components'
import { OrdersService, cart_item, po_view, promotion_type } from 'src/services'
import { forwardRef, useImperativeHandle, useState } from 'react'
import { useCheckAccTax } from 'src/hooks/useCheckUser'
import { Dialog } from 'primereact/dialog'
import HeaderText from 'com-ui/components/header-text'
import { config, getProductLink } from 'src/helpers'
import css from 'styled-jsx/css'
import { formatUSD } from 'com-ui/utilities/currency'
import { timeUS } from 'com-ui2/utilities/time'
import { useUpdateEffect } from 'com-ui2/hooks'
import { displayInTimeZone } from 'com-ui/utilities/time'

const OrderDetail = forwardRef((_props, ref) => {
  const [isLoading, setIsLoading] = useState(false)
  const [orderId, setOrderId] = useState<string | null>(null)
  const [visible, setVisible] = useState(false)
  const [order, setOrder] = useState<po_view | null>(null)

  useUpdateEffect(() => {
    if (orderId) {
      setIsLoading(true)
      OrdersService.order({ id: orderId || '' })
        .then((result) => {
          setOrder(result)
        })
        .catch(() => onHide())
        .finally(() => setIsLoading(false))
    }
  }, [orderId])

  const productBvfLink = getProductLink(order?.store_domain, order?.page_slug)

  const isAccTax = useCheckAccTax()

  const onHide = () => {
    setVisible(false)
    setOrder(null)
    setOrderId(null)
  }

  useImperativeHandle(ref, () => ({
    openModal(orderId?: string) {
      setOrderId(orderId || null)
      setVisible(true)
    }
  }))

  const ShippingAddress = () => (
    <>
      <span className="text">
        {[
          `${order?.shipping_address1 ?? ''}${order?.shipping_address2 ? ` ${order?.shipping_address2}` : ''}`,
          order?.shipping_state,
          order?.shipping_city,
          order?.shipping_postal_code
        ].join(', ')}
      </span>
      <span className="text">{order?.shipping_country}</span>
    </>
  )

  const BillingAddress = () =>
    order?.billing_address1 || order?.billing_city || order?.billing_state || order?.billing_postal_code ? (
      <>
        <span className="text">
          {[
            `${order?.billing_address1 ?? ''}${order?.billing_address2 ? ` ${order?.billing_address2}` : ''}`,
            order?.billing_state,
            order?.billing_city,
            order?.billing_postal_code
          ].join(', ')}
        </span>
        <span className="text">{order?.billing_country}</span>
      </>
    ) : (
      <ShippingAddress />
    )

  const Header = () => (
    <div className="d-flex align-items-center">
      <HeaderText Tag="h5" fontSize="24" style={{ color: 'var(--gray-8)' }}>
        {order?.code ? `#${order?.code}` : ''}
      </HeaderText>
      <div className="py-2 ml-3">
        {order && (
          <PublishStatus className="mr-2 border-raidus-4" size={'small'} showDot={false} color={'success'}>
            Paid
          </PublishStatus>
        )}
        {!!order?.source_abandoned && (
          <PublishStatus className="border-raidus-4" size={'small'} showDot={false} color={'warning'}>
            Abandoned
          </PublishStatus>
        )}
      </div>
    </div>
  )
  const getDiscountType = (discountType: promotion_type, discountValue: string) => {
    switch (discountType) {
      case promotion_type.PERCENT:
        return ` (${discountValue || 0}%)`
      case promotion_type.SHIP:
        return ` (Free shipping)`
      default:
        return ''
    }
  }

  return (
    <>
      <Dialog
        blockScroll={true}
        header={Header}
        className="dialog position-relative"
        style={{ width: '640px' }}
        onHide={onHide}
        visible={visible}
        draggable={false}
        resizable={false}
      >
        <div className="dialog__content">
          <Loading isLoading={isLoading}></Loading>
          {order && (
            <div className="d-flex flex-column w-100">
              <div className="d-flex align-items-center">
                <div className="mr-auto d-flex">
                  <span className="body-2b">From:</span>
                  <a
                    href={`https://${productBvfLink}`}
                    title={`https://${productBvfLink}`}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <div className="body-2 text pl-2 text-link">{productBvfLink}</div>
                  </a>
                </div>
                <div className="ml-auto">
                  <span className="body-2b">Paid at:</span>
                  <span className="body-2 text pl-2">{timeUS(order?.payment_paid).fromServer().format()}</span>
                  <span className="text pl-3">({displayInTimeZone(new Date(), 'O')})</span>
                </div>
              </div>
              <div className="product-wrapper mt-3">
                <div className="product-content">
                  {order.cart_items?.map((item: cart_item) => (
                    <Product product={item} key={item.id}></Product>
                  ))}
                </div>
              </div>
              <div className="row mt-3">
                <div className="col">
                  <div className="info">
                    <span className="body-2b">Customer</span>
                    <span className="text">
                      {order?.customer?.first_name} {order?.customer?.last_name}
                    </span>
                  </div>
                  <div className="info">
                    <span className="body-2b">Shipping Address</span>
                    <ShippingAddress />
                  </div>
                  <div className="info">
                    <span className="body-2b">Billing Address</span>
                    <BillingAddress />
                  </div>
                </div>
                <div className="col pl-5">
                  <div className="invoice">
                    <span className="mr-auto body-2 text">Subtotal</span>
                    <span className="ml-auto body-2b">{formatUSD(order?.items_amount || 0)}</span>
                  </div>
                  <div className="invoice">
                    <span className="mr-auto body-2 text">{`${order.shipping_code}`}</span>
                    <span className="ml-auto body-2b">{formatUSD(order?.shipping_amount || 0)}</span>
                  </div>
                  <div className="invoice">
                    <span className="mr-auto body-2 text">
                      Discount
                      {getDiscountType(order?.discount_type || promotion_type.FIXED, order?.discount_value || '')}
                    </span>
                    <span className="ml-auto body-2b">-{formatUSD(order?.discount_amount?.toFixed(2) || 0)}</span>
                  </div>
                  <div className="invoice highlight">
                    <span className="mr-auto body-2">Total</span>
                    <span className="ml-auto body-2b">{formatUSD(order?.amount || 0)}</span>
                  </div>
                  <div className="invoice">
                    <span className="mr-auto body-2 text">Paid by customer</span>
                    <span className="ml-auto body-2b">{formatUSD(order?.amount || 0)}</span>
                  </div>
                  <span className="caption text-des">
                    Pay via {order?.payment_provider === 'PAYPALEX' ? 'Paypal' : 'Credit'}
                  </span>
                  {!!order.source_abandoned && (
                    <div className="invoice">
                      <span className="mr-auto body-2 text">Converted fee</span>
                      <span className="ml-auto body-2b">$0.70</span>
                    </div>
                  )}
                  {isAccTax && (
                    <div className="invoice">
                      <span className="mr-auto body-2 text">Tax value</span>
                      <span className="ml-auto body-2b">{formatUSD((order?.tax_amount || 0).toFixed(2))}</span>
                    </div>
                  )}

                  <div className="invoice">
                    <span className="mr-auto body-2 text">You take</span>
                    <span className="ml-auto body-2b">{formatUSD(order?.taken_amount || 0)}</span>
                  </div>
                  <div className="invoice">
                    <span className="mr-auto body-2 text">Hold</span>
                    <span className="ml-auto body-2b hold-value">{formatUSD(order?.hold_amount || 0)}</span>
                  </div>
                  <span className="caption text-des">
                    Release Date:
                    <span className="pl-2">
                      {order?.hold_released && timeUS(order?.hold_released).fromServer().format()}
                    </span>
                  </span>
                </div>
              </div>
            </div>
          )}
        </div>
        <style jsx>{style}</style>
      </Dialog>
    </>
  )
})

interface ProductProps {
  product: cart_item
}
const Product = ({ product }: ProductProps) => (
  <div className="mt-3 product row row no-gutters">
    <div className="col-9 mr-auto d-flex align-items-center">
      <div className="image-wrapper mr-3">
        <img src={`${config.cdnBasePath}${product.image_url}`} alt={product.product_name}></img>
      </div>
      <div className="d-flex flex-column">
        <span className="body-2b">{product.product_name}</span>
        <span className="text body-2">{product.properties?.map((value) => value.value).join(' / ')}</span>
      </div>
    </div>
    <div className="col-3 ml-auto body-1b d-flex align-items-center justify-content-end">
      {product.quantity} x <span className="pl-3">{formatUSD(product?.default_price || '')}</span>
    </div>
    <style jsx>{styleProduct}</style>
  </div>
)

const styleProduct = css`
  .product {
    display: flex;
  }
  .text {
    color: var(--indigo-8);
  }

  .image-wrapper {
    height: 48px;
    width: 48px;
    /* border: 1px solid var(--indigo-3); */
    /* border-radius: 8px; */

    img {
      border: 1px solid var(--indigo-3);
      box-sizing: border-box;
      border-radius: 8px;
      cursor: pointer;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
`

const style = css`
  .text {
    color: var(--indigo-8);
  }

  .dialog {
    &__content {
      min-height: 200px;
    }
  }

  .hold-value {
    color: var(--orange-400);
  }

  .text-link {
    width: 300px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  .product-wrapper {
    border: 1px solid var(--indigo-3);
    border-width: 1px 0 1px 0;
  }
  .product-content {
    margin-top: 16px;
    margin-bottom: 16px;
    max-height: 250px;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
  }
  .info {
    margin-bottom: 16px;
    display: flex;
    flex-direction: column;
  }
  .invoice {
    display: flex;
    align-items: center;
    padding-top: 8px;

    &.highlight {
      color: var(--blue-400);
    }
  }
  .text-des {
    color: var(--indigo-7);
  }
`

export default OrderDetail
