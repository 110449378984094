import Card from 'com-ui/components/card'
import HeaderText from 'com-ui/components/header-text'
import css from 'styled-jsx/css'

interface IStatisticItemProps {
  label: string
  icon?: any
  value: string
  description?: string | number
  className?: string
}

export const StatisticItem = (props: IStatisticItemProps) => {
  return (
    <div className="statistic-card">
      <Card className={`d-flex flex-column ${props?.className}`} padding="16px 24px">
        <div className="d-flex align-items-center">
          <span className="body-2 text">{props.label}</span>
          {props?.icon}
        </div>
        <HeaderText Tag="h6" fontSize="20" className="value" title="$">
          {props.value}
        </HeaderText>
        {props.description && <div className="text--primary text--xs">{props.description}</div>}
      </Card>
      <style jsx>{style}</style>
    </div>
  )
}

const style = css`
  .statistic-card {
    height: 100%;
    .text {
      color: var(--indigo-8);
    }

    :global(.value) {
      color: var(--indigo-9);
      font-weight: 600;
      display: inline-flex;
      align-items: center;
      &:before {
        content: attr(title);
        font-size: 16px;
        margin: 0 6px 0 0;
      }
    }
  }
`
