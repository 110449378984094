import React from 'react'
import css from 'styled-jsx/css'

interface VideoYoutubeProps {
  embedId: string
  width?: string
  height?: string
}

export const YoutubeEmbed = (props: VideoYoutubeProps) => {
  const { embedId, width, height } = props
  return (
    <div className="video-responsive">
      <iframe
        width={`${width}`}
        height={`${height}`}
        src={`${embedId}`}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title="YouTube video player"
      />
      <style jsx>{YoutubeStyle}</style>
    </div>
  )
}

const YoutubeStyle = css`
  .video-responsive {
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
    height: 0;
  }

  .video-responsive iframe {
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    position: absolute;
  }
`
