import React, { Dispatch, ReactNode, SetStateAction, createContext, useEffect, useState, useMemo } from 'react'
import { Header, Sidebar } from '@shared'
import { putGlobalState, useGlobalState } from 'com-ui2/hooks'

import css from 'styled-jsx/css'
import { useRouter } from 'next/router'
import getLogRocket from 'src/components/logrocket/logRocket'
import { collapseRouter, isAndroidIos } from 'src/helpers'
import { useSelector } from 'react-redux'
import { getPublisherApiStatusState, getPublisherState } from 'src/containers/settings/store'
import UnderReview from 'src/signup/UnderReview'
import { getProfileState } from 'com-ui-biz/profile/stores/store'
import { Icon } from 'com-ui2/components'
import { iconPath } from 'com-ui/utilities/imagePath'
// import { isFullSessionPublisher } from 'src/helpers/logRocket'
// import { getProfileState } from 'com-ui-biz/profile/stores/store'
// import { useSelector } from 'react-redux'
import getConfig from 'next/config'
import { useIntercom } from 'react-use-intercom'
import { AxiosDefault } from 'com-ui2/utilities'
import { publisher_channel } from 'src/services'
import { DialogWelcome } from './DialogWelcome'
import { DialogWelcomeMobile } from './DialogWelcomeMobile'
import Head from 'next/head'

interface ICmsLayout {
  children?: ReactNode
}
interface ISidebarContext {
  active: boolean
  setActive: Dispatch<SetStateAction<boolean>>
}
export const HEADER_LAYOUT = 'HEADER_LAYOUT'
putGlobalState(HEADER_LAYOUT, null)

export const sidebarContext = createContext<ISidebarContext>({
  active: false,
  setActive: () => null
})
const SidebarProvider = sidebarContext.Provider

export default function CmsLayout({ children }: ICmsLayout) {
  const [sidebarActive, setSidebarActive] = useState(false)
  const [headerLayout, setHeaderLayout] = useGlobalState(HEADER_LAYOUT)
  // const user_id = useSelector(getProfileState)?.id
  const router = useRouter()
  const { logRocket } = getLogRocket()
  const isCollapse = collapseRouter.includes(router.pathname) || false
  const publisherStatus = useSelector(getPublisherApiStatusState)
  const publisherInfo = useSelector(getProfileState)
  const publisher = useSelector(getPublisherState)
  const INTERCOM_APP_ID = getConfig().publicRuntimeConfig.intercom_id || ''
  const isIHO = useSelector(getPublisherState)?.type === publisher_channel.IHO
  const isMobile = isAndroidIos()
  const [activeUser, setActiveUser] = useGlobalState('_local_active_user_')

  const is_showed_guide = useSelector(getPublisherState)?.meta_data?.user_guide?.is_showed
  // const [isCheckIntercom, setIsCheckIntercom] = useState(false)
  let isCheckIntercom = false
  const { boot } = useIntercom()

  const _axios = AxiosDefault({
    timeout: 59000
  })
  const fetchIntercom = async () => {
    if (isIHO || publisherStatus == 201) {
      isCheckIntercom = true
      return
    }
    const data = new FormData()
    data.append('app_id', INTERCOM_APP_ID)
    try {
      await _axios.post('https://api-iam.intercom.io/messenger/web/ping', data)
      boot({
        email: publisher?.email || '',
        userHash: publisher?.intercom_user_hash || ''
      })
      isCheckIntercom = true
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    fetchIntercom()
    setInterval(() => {
      if (isCheckIntercom) return
      fetchIntercom()
    }, 60000)
  }, [])

  const [isShowPopup, setIsShowPopup] = useState(true)
  const handleHidePopup = () => setIsShowPopup(false)
  useEffect(() => {
    logRocket?.init()
    logRocket?.identify({
      email: publisherInfo?.email,
      firstName: publisherInfo?.first_name,
      lastName: publisherInfo?.last_name
    })
  })
  useEffect(() => {
    if (!is_showed_guide && (!activeUser || activeUser !== `showed${publisher?.id || ''}`)) {
      setActiveUser('active')
    }
  }, [publisherStatus, is_showed_guide])

  useEffect(() => {
    setHeaderLayout(null)
  }, [router.pathname])
  const isBackgroundWhite = router.pathname.includes('/edit') && router.pathname.startsWith('/sell-pages')
  return (
    <>
      {isMobile &&
        useMemo(
          () =>
            activeUser !== 'active' ? (
              <>
                <Head>
                  <meta content="width=1440, initial-scale=1, maximum-scale=1.0, user-scalable=1" name="viewport" />
                </Head>
              </>
            ) : (
              <>{publisherStatus !== 201 && <DialogWelcomeMobile />}</>
            ),
          [activeUser]
        )}
      <SidebarProvider value={{ active: sidebarActive, setActive: setSidebarActive }}>
        <div className={`app ${isBackgroundWhite ? 'bg-white' : ''}`}>
          {publisherStatus !== 201 && <Sidebar />}
          {publisherStatus !== 201 && !isMobile && <DialogWelcome />}
          <div className="sidebar-overlay" onClick={() => setSidebarActive(false)} />
          <div className={`main ${isCollapse ? 'main-full' : ''}`}>
            {headerLayout ? headerLayout : <Header />}
            {publisherStatus !== 201 ? <div className="container-fluid">{children}</div> : <UnderReview />}
            {isShowPopup && isMobile && (
              <div className="suggest-popup mobile container">
                <p className="suggest-popup__content">For the best experience, we recommend using Selless on desktop</p>
                <div className="suggest-popup__close" onClick={handleHidePopup}>
                  <Icon src={iconPath('dismiss_filled')} className="input-icon" size="16px" fill="var(--indigo-7)" />
                </div>
              </div>
            )}
          </div>
          <style jsx global>
            {style}
          </style>
        </div>
      </SidebarProvider>
    </>
  )
}

const style = css.global`
  $headerHeight: 120px;
  $sidebarWidth: 224px;

  .app {
    background-color: var(--indigo-2);
  }
  /* .container-fluid {
    padding: 0 32px;
  } */
  .sidebar {
    ~ .header {
      margin-left: $sidebarWidth;
    }

    ~ .main {
      margin-left: $sidebarWidth;
      min-height: calc(100vh);
      transition: 0.3s;
      &.main-full {
        margin-left: 50px;
      }
    }
  }
  :global(.grecaptcha-badge) {
    display: none !important;
  }
  .suggest-popup {
    display: flex;
    z-index: 1;
    filter: drop-shadow(1px 4px 25px rgba(0, 0, 0, 0.15));
    background: #ffffff;
    border-radius: 4px;
    padding: 12px 16px;
    border-top: 4px solid #01aab0;
    position: fixed;
    top: 32px;
    z-index: 9999;
    left: 0px;
    right: 0px;
    margin: auto;
    max-width: 500px;
    width: 95%;
  }
  .suggest-popup__close {
    width: 24px;
    color: black;
    margin-left: auto;
  }
  .suggest-popup p {
    margin: 0;
    color: #232731;
    margin-right: 12px;
  }
  @media screen and (max-width: 1023px) {
    .header--primary {
      font-size: 1.25rem;
    }

    .domain--primary {
      font-size: 1rem;
      align-items: center;

      a {
        color: #646d84;
      }

      .domain__icon {
        margin-left: auto;
      }
    }

    .sidebar {
      transform: translateX(-240px);
      border-top-right-radius: 16px;
      border-bottom-right-radius: 16px;
      border-right: 1px solid #ececec;
      z-index: 99999;

      ~ .header,
      ~ .custom-header,
      ~ .main {
        margin-left: 0;
      }

      ~ .main {
        padding-top: 20px;
      }

      ~ .sidebar-overlay {
        display: none;
        position: fixed;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        z-index: 99998;
        background-color: rgba(0, 0, 0, 0.2);
      }

      &.active {
        transform: translateX(0);

        ~ .sidebar-overlay {
          display: block;
        }
      }
    }
  }
`
