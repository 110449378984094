import 'com-ui2/styles/index.scss'
import 'src/styles/index.scss'

import App, { AppProps } from 'next/app'
import { Router, useRouter } from 'next/router'
import { privateRouters, withToast } from 'com-ui2/utilities'

import CmsLayout from 'src/layout/cms.layout'
import NProgress from 'nprogress' //nprogress module
import { getProfileState } from 'com-ui-biz/profile/stores/store'
import { initSentry } from 'src/libs/sentry'
import { useSelector } from 'react-redux'
import { withAuthPublisher } from 'src/hocs'
import { withRedux } from 'com-ui/hocs'
import { initializeGlobalState } from 'com-ui2/hooks'
import React from 'react'
import { IntercomProvider } from 'react-use-intercom'
import getConfig from 'next/config'

const INTERCOM_APP_ID = getConfig().publicRuntimeConfig.intercom_id || ''
const isDev = process.env.NODE_ENV != 'production'
// Register Sentry
!isDev && initSentry()

// Show progress bar on switching route
NProgress.configure({ showSpinner: false })
Router.events.on('routeChangeStart', () => NProgress.start())
Router.events.on('routeChangeComplete', () => NProgress.done())
Router.events.on('routeChangeError', () => NProgress.done())

if (process.browser) {
  initializeGlobalState()
}
// @ts-ignore
function MyApp({ Component, pageProps, err }: AppProps) {
  const router = useRouter()
  const profileId = useSelector(getProfileState)?.id

  return privateRouters(router.pathname) && profileId ? (
    <>
      <IntercomProvider appId={INTERCOM_APP_ID}>
        <CmsLayout>
          <Component {...pageProps} err={err} />
        </CmsLayout>
      </IntercomProvider>
    </>
  ) : (
    <Component {...pageProps} err={err} />
  )
}

MyApp.getInitialProps = async (ctx: any) => {
  const appProps = App.getInitialProps && (await App.getInitialProps(ctx))
  return appProps
}

export default withRedux(withToast(withAuthPublisher(MyApp)))
