import css from 'styled-jsx/css'

export type StatusColor =
  | 'success'
  | 'warning'
  | 'error'
  | 'info'
  | 'disabled'
  | 'purple'
  | 'black'
  | 'white'
  | 'orange'
  | 'red'

export type StatusSize = 'small' | 'default'
export interface PublishStatusProps extends Types.PlainObject {
  color?: StatusColor
  className?: string
  showDot?: boolean
  size?: StatusSize
  hasBorder?: boolean
  hasBackground?: boolean
  solidColor?: boolean
}

export function PublishStatus({
  color,
  className,
  size = 'default',
  showDot = true,
  hasBorder = false,
  hasBackground = true,
  solidColor = false,
  ...props
}: PublishStatusProps) {
  return (
    <>
      <div
        {...props}
        className={`publish-status
          ${size}
          ${className ? className : ''}
          ${color ? color : ''}
          ${showDot ? 'show-dot' : ''}
          ${hasBorder ? 'has-border' : ''}
          ${size === 'small' ? 'caption' : 'body-2'}
          ${solidColor ? 'solid-color' : ''}
          ${hasBackground ? '' : 'no-background'}`}
      >
        {props.children}
      </div>
      <style jsx>{styles}</style>
    </>
  )
}

const styles = css`
  .publish-status {
    display: inline-flex;
    align-items: center;
    font-size: 13px;
    padding: 4px 12px;
    color: var(--primary-100);
    background-color: var(--primary-30);
    border-radius: 8px;
    text-transform: capitalize;
    user-select: none;
    &.show-dot {
      padding: 4px 12px;
      border-radius: 4px;
      &:before {
        content: '•';
        font-size: 30px;
        margin-top: -4px;
        margin-right: 6px;
        line-height: 0;
      }
    }
    &.small {
      padding: 0 10px;
      font-size: 10px;
      &:before {
        font-size: 18px;
        margin-top: -2px;
        margin-right: 4px;
      }
    }
    &.solid-color {
      border-radius: 20px;
    }
    &.warning {
      color: var(--orange-500);
      background-color: var(--orange-50);
      &.has-border {
        color: var(--orange-600);
        border: 1px solid var(--orange-500);
      }
      &.solid-color {
        color: var(--white-100);
        background-color: var(--orange-300);
      }
    }
    &.info {
      color: var(--blue-600);
      background-color: var(--blue-50);
      &.has-border {
        color: var(--blue-2);
        border: 1px solid var(--blue-2);
      }
      &.solid-color {
        color: var(--white-100);
        background-color: var(--blue-300);
      }
    }
    &.error {
      color: var(--red-400);
      background-color: var(--red-50);
      &.has-border {
        border: 1px solid var(--red-400);
      }
      &.solid-color {
        color: var(--white-100);
        background-color: var(--red-400);
      }
    }
    &.red {
      color: var(--white-100);
      background-color: var(--red-400);
      &.has-border {
        border: 1px solid var(--white-100);
      }
      &.solid-color {
        color: var(--white-100);
        background-color: var(--red-400);
      }
    }
    &.disabled {
      color: var(--indigo-8);
      background-color: var(--indigo-3);
      &.has-border {
        border: 1px solid var(--indigo-6);
      }
      &.solid-color {
        color: var(--indigo-8);
        background-color: var(--indigo-3);
      }
    }
    &.success {
      color: var(--green-600);
      background-color: var(--green-50);
      &.has-border {
        border: 1px solid var(--green-600);
      }
      &.solid-color {
        color: var(--white-100);
        background-color: var(--green-300);
      }
    }
    &.purple {
      color: var(--purple-600);
      background-color: var(--purple-50);
      &.has-border {
        border: 1px solid var(--purple-600);
      }
      &.solid-color {
        color: var(--white-100);
        background-color: var(--purple-300);
      }
    }
    &.orange {
      color: var(--red-200);
      background-color: var(--indigo-3);
      &.has-border {
        border: 1px solid var(--indigo-6);
      }
      &.solid-color {
        color: var(--red-200);
        background-color: var(--indigo-3);
      }
    }
    &.black {
      color: var(--white-100);
      background-color: var(--indigo-10);
      &.has-border {
        color: var(--gray-8);
        border: 1px solid var(--gray-7);
      }
      &.solid-color {
        color: var(--white-100);
        background-color: var(--indigo-10);
      }
    }
    &.white {
      color: var(--indigo-10);
      background-color: var(--white-100);
      &.has-border {
        color: var(--indigo-10);
        border: 1px solid var(--indigo-10);
      }
      &.solid-color {
        color: var(--indigo-10);
        background-color: var(--white-100);
      }
    }
    &.no-background {
      background-color: unset;
    }
  }
`
