import { PublishStatus, StatusColor } from 'com-ui2/components'
import { SellPageViewInList, UploadType } from 'src/services'

import { Button } from 'com-ui2/controls/Button'
import Card from 'com-ui/components/card'
import { Icon } from 'com-ui2/components/svg/Icon'
import Link from 'next/link'
import React from 'react'
import css from 'styled-jsx/css'
import { differenceInDays } from 'date-fns'
import { sellPageImageBuilder } from 'src/helpers'
import { updateRenew } from 'src/stores/domain'
import { useDispatch } from 'react-redux'
import { useRouter } from 'next/router'
import { commonShared } from 'com-ui/utilities/iconPath'

// // import { differenceInDays } from 'date-fns'

interface IStoreCardItemProps {
  store: SellPageViewInList
}

interface IEntryProps {
  label: string
  value: JSX.Element
  className: string
}

export const StoreCardItem = (props: IStoreCardItemProps) => {
  const { store } = props
  const dispatch = useDispatch()
  const router = useRouter()
  const colors: { [key: string]: StatusColor } = {
    published: 'success',
    building: 'info',
    draft: 'warning'
  }

  const handleClickRenew = () => {
    router.push(`/sell-pages/${store.id}/edit`)
    dispatch(updateRenew(true))
  }
  return (
    <div className="store-item">
      <Card padding="0">
        <div className="image position-relative">
          <img
            src={sellPageImageBuilder(store?.id || '', store?.images?.[0] || '', UploadType.gallery)}
            alt={store?.id}
          />
          <PublishStatus color={colors[props.store.status || '']} className="status">
            {props.store.status}
          </PublishStatus>
        </div>
        <div className="content">
          <a href={`http://${store?.domain?.name}/${store?.slug}`} className="title" target="_blank" rel="noreferrer">
            <span className="domain-name" title={`${store?.domain?.name}/${store?.slug}`}>
              {store?.domain?.name}/${store?.slug}
            </span>
            {/* <HeaderText Tag="h6" fontSize="16">
              </HeaderText>
            </div> */}
            <Icon src={commonShared('open_in_new.svg')} className="icon" size="20px" />
          </a>
          <div className="expired">
            {!!(
              store &&
              store?.domain?.expired &&
              differenceInDays(store?.domain?.expired ? new Date(store?.domain?.expired) : new Date(), new Date()) <= 30
            ) && (
              <div className="d-flex text--xs text--waring align-items-center">
                <div className="info body-2b">
                  Domain expires in
                  {differenceInDays(store?.domain?.expired ? new Date(store?.domain?.expired) : new Date(), new Date())}
                  days
                </div>
                <div className="value ml-auto">
                  <Button type="neutralBorder" style={{ height: '20px' }} onClick={handleClickRenew}>
                    Renew
                  </Button>
                </div>
              </div>
            )}
            {/* {store && (
              <div className="d-flex text--xs text--waring align-items-center">
                <div className="info body-2b">Domain expires in 30 days </div>
                <div className="value ml-auto">
                  <Button type="neutralBorder" style={{ height: '20px' }} onClick={handleClickRenew}>
                    Renew
                  </Button>
                </div>
              </div>
            )} */}
          </div>
          <div className="mb-3">
            <Entry
              label="Revenue:"
              value={<span className="text--secondary">{store.revenue || 0}</span>}
              className="pb-1"
            />
            <Entry
              label="Orders:"
              value={<span className="text--secondary">{store.orders || 0}</span>}
              className="pb-1"
            />
            <Entry
              label="Selless.io fee:"
              value={
                <span className="text--secondary">
                  {store.percent_selless_fee || 0}% + ${store.fix_selless_fee?.toFixed(2) || 0}
                </span>
              }
              className="pb-1"
            />
            <Entry
              label="Cash to balance:"
              value={<span className="text--secondary">${store.cash_in_balance?.toFixed(2) || 0}</span>}
              className="pb-1"
            />
          </div>
          <div className="d-flex">
            <Link href={`/sell-pages/[id]/edit`} as={`sell-pages/${store.id}/edit`}>
              <a className="d-flex button">Edit</a>
            </Link>
            <Link href={`/sell-pages/[id]`} as={`/sell-pages/${store.id}`}>
              <a className="d-flex button is-primary">Performance</a>
            </Link>
          </div>
        </div>
      </Card>
      <style jsx>{storeItemStyle}</style>
    </div>
  )
}

export const Entry = ({ label, value, className }: IEntryProps) => {
  return (
    <div className={`d-flex text--xs ${className || ''}`}>
      <div className="info"> {label} </div>
      <div className="value ml-auto"> {value} </div>
      <style jsx>{entryStyle}</style>
    </div>
  )
}

const entryStyle = css`
  .info {
    color: var(--indigo-7);
  }
  .value {
    font-weight: 600;
  }
`

const storeItemStyle = css`
  .store-item {
    padding-bottom: 30px;
    height: 100%;
    position: relative;
    .content {
      padding: 24px;
    }
    .domain-name {
      width: 280px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      font-family: var(--font-header);
      font-weight: 600;
      font-size: 16px;
    }
    .expired {
      height: 25px;
      button {
        height: 20px;
      }

      .info {
        color: var(--warning-100);
      }
    }
    .title {
      color: var(--indigo-9);
      display: flex;
      justify-content: space-between;
      align-items: center;

      h6 {
        margin-right: 16px;
        word-break: break-word;
      }

      :global(.icon) {
        fill: var(--secondary-100);
      }

      &:hover {
        :global(.icon) {
          fill: var(--secondary-100);
        }
      }
    }
    .image {
      width: 100%;
      border-radius: 8px 8px 0 0;
      .status {
        position: absolute;
        top: 16px;
        left: 16px;
      }
      img {
        object-fit: cover;
        width: 100%;
        height: 240px;
      }
    }
    .button {
      height: 40px;
      justify-content: center;
      align-items: center;
      &:first-child {
        margin-right: 16px;
        flex-shrink: 2;
      }
      &.is-primary {
        background-color: var(--primary-100);
        border-color: transparent;
        color: var(--white-100);
        &:hover {
          background-color: var(--primary-100-dark);
        }
      }
    }
  }
`
