import { HTMLAttributes } from 'react'
import { RadioButton } from 'primereact/radiobutton'
import css from 'styled-jsx/css'
interface RadioItem {
  label: string
  key: string
  customRender?: (key?: string) => JSX.Element
  disabled?: boolean
}

interface IChoiceGroup {
  options: RadioItem[]
  value?: string
  onChange?: (selectedKey: string) => void
  name?: string
}

export const ChoiceGroup = ({
  options,
  value,
  onChange,
  className,
  ...props
}: IChoiceGroup & HTMLAttributes<HTMLDivElement>) => {
  const _onChange = (_val: any) => {
    onChange?.(_val)
  }

  return (
    <div className={`choice-group ${className ?? ''}`}>
      {options.map((item, index) => (
        <div className="p-field-radiobutton" key={item.key}>
          <RadioButton
            inputId={`${item.key}-${index}`}
            name={props.name}
            value={item.key}
            onChange={(e) => _onChange(e.value)}
            checked={value == item.key}
            disabled={item.disabled}
          />
          <label htmlFor={`${item.key}-${index}`}>{item.label}</label>
          {item.customRender && <div className="ml-2 w-100">{item.customRender(item.key)}</div>}
        </div>
      ))}
      <style jsx>{style}</style>
    </div>
  )
}

const style = css`
  :global(.p-field-radiobutton .p-radiobutton-box) {
    border: 2px solid var(--gray-6);
  }

  :global(.p-inputgroup .p-field-radiobutton .p-radiobutton .p-radiobutton-box) {
    width: 20px;
    height: 20px;
    margin: auto;
    border: 2px solid var(--gray-6);
  }
  :global(.p-field-radiobutton .p-radiobutton .p-radiobutton-box, .p-field-radiobutton
      .p-radiobutton
      .p-radiobutton-box) {
    width: 20px;
    height: 20px;
    margin: auto;
    border: 2px solid var(--gray-6);
  }
`
