import { Button } from 'com-ui2/controls'
import { Icon, Tooltip } from 'com-ui2/components'
import { ProductSearchType, ProductViewInList, UploadType } from 'src/services'
import React, { useEffect, useRef, useState } from 'react'
import { useCheckAccTax } from 'src/hooks/useCheckUser'
import Card from 'com-ui/components/card'
import CostDetail from 'src/components/products/CostDetail'
import PreviewProductDialog from '../products/PreviewProductDialog'
import css from 'styled-jsx/css'
import { iconPath } from 'com-ui/utilities/imagePath'
import { productImageBuilder, videoBuilderSource } from 'src/helpers'
import { updateProduct } from 'src/stores/product'
import { useDispatch } from 'react-redux'
import { formatUSD } from 'com-ui/utilities/currency'
import getConfig from 'next/config'
import { useResize } from 'com-ui/hooks'

interface IProductCardItemProps {
  product: ProductViewInList
  index: Number
  status: ProductSearchType
}

export const ProductCardItem = (props: IProductCardItemProps) => {
  const imgRef = useRef<any>(null)
  const { product } = props
  const [paymentFee, setPaymentFee] = useState(0)
  const [sellessIoFee, setSellessIoFee] = useState(0)
  const [takeMoney, setTakeMoney] = useState(0)
  const modalRef = useRef<any>()
  const [imgWidth, setImgWidth] = useState(null)
  const dispatch = useDispatch()
  useEffect(() => {
    setSellessIoFee(((product?.percent_selless_fee || 0) / 100) * (product.aov || 0) + (product?.fix_selless_fee || 0))
    setPaymentFee(((product?.percent_payment_fee || 0) / 100) * (product.aov || 0) + (product?.fix_payment_fee || 0))
    setTakeMoney(
      (product.aov || 0) -
        (product.base_cost || 0) - // base cost
        // (product.product_cost || 0) - // product cost
        // (product?.fulfillment_cost || 0) - // fulfillment cost
        (((product?.percent_payment_fee || 0) + (product?.percent_selless_fee || 0)) / 100) * (product.aov || 0) -
        (product?.fix_payment_fee || 0) -
        (product?.fix_selless_fee || 0)
    )
  }, [])
  function handleClickCreateSellPage() {
    dispatch(updateProduct(product))
  }
  const callBackResize = () => {
    if (imgRef?.current) {
      setImgWidth(imgRef.current.clientWidth || 0)
    }
  }
  useResize(callBackResize)

  const isAccTax = useCheckAccTax()

  useEffect(() => {
    if (imgRef?.current) {
      setImgWidth(imgRef.current.clientWidth || 0)
    }
  }, [imgRef])
  return (
    <div className="product-item">
      <Card padding="0">
        <div className="image" ref={imgRef} style={{ height: imgWidth ? `${imgWidth}px` : '268px' }}>
          {product.images?.[0]?.includes('.mp4') ? (
            <video loop muted autoPlay preload="true" width="100%" height="100%">
              <source src={videoBuilderSource(product.images?.[0])} type="video/mp4" />
            </video>
          ) : (
            <img
              style={{ height: imgWidth ? `${imgWidth}px` : '268px' }}
              src={productImageBuilder(product.id || '', product.images?.[0] ?? '', UploadType.gallery)}
              alt={product.pbase_title}
            />
          )}

          <div className="product-action">
            <Button
              type="neutralBorder"
              className="mt-3"
              size="medium"
              onClick={() => modalRef.current?.openModal(product.id)}
            >
              <Icon src={iconPath('megaphone_regular')} fill="var(--indigo-8)" className="icon mr-2" size="20px" />
              <span>View Ad Creatives</span>
            </Button>
            <Button
              type="neutralBorder"
              className="mt-3"
              size="medium"
              onClick={() =>
                window.open(`${getConfig().publicRuntimeConfig.previewSellPage}/?page=${product?.id}`, '_blank')
              }
            >
              <Icon src={iconPath('content_view_regular')} fill="var(--indigo-8)" className="icon mr-2" size="20px" />
              <span>Preview sellpage</span>
            </Button>
          </div>
        </div>
        <div
          className="content d-flex flex-column"
          style={{ height: `calc(100% - ${imgWidth ? `${imgWidth}px` : '268px'})` }}
        >
          <header className="body-2b">{`${product.pbase_label || ''} - ${product.pbase_title || ''}`}</header>
          <div className="mt-auto">
            <div className="w-100">
              <div className="page-note" title={product?.page_note}>
                {product?.page_note || ''}
              </div>
            </div>
            <div className="body-2 mt-2">
              <div className="d-flex align-items-center">
                <span className="info pr-2">Suggested retail price:</span>
                <span className="money">
                  {!product?.suggest_price ? 'n/a' : formatUSD((product?.suggest_price || 0).toFixed(2) || 0)}
                </span>
              </div>
            </div>
            <div className="body-2">
              <div className="d-flex align-items-center">
                <span className="info pr-2">Average order value (AOV):</span>
                {!product?.aov ? (
                  <span className="money">n/a</span>
                ) : (
                  <>
                    <Tooltip type="dark" target={`.aov-${product.id}`}>
                      This number is calculated based on Selless sales data
                    </Tooltip>
                    <span className={`money money__line aov-${product.id}`}>
                      {formatUSD((product?.aov || 0).toFixed(2) || 0)}
                    </span>
                  </>
                )}
              </div>
            </div>
            <div className="you-take mt-0">
              <div className="d-flex align-items-center">
                <span className="body-2 info pr-2">You take:</span>
                {!product?.aov ? (
                  <span className="money">n/a</span>
                ) : (
                  <>
                    <Tooltip type="dark" target={`.take-money-${product.id}`} className={`.take-money-${product.id}`}>
                      <CostDetail
                        paymentFee={paymentFee}
                        product={product}
                        sellessIoFee={sellessIoFee}
                        isAccTax={isAccTax}
                      />
                    </Tooltip>
                    <div className={`d-flex align-items-center take-money-${product.id}`}>
                      <span className="money money__line">{formatUSD(takeMoney.toFixed(2) || 0)}</span>
                    </div>
                    <span className="body-2 info pl-1">/ order</span>
                  </>
                )}
              </div>
            </div>
            {product.sellable && product.available ? (
              <Button type="neutralBorder" className="w-100 mt-3" size="medium" onClick={handleClickCreateSellPage}>
                <Icon src={iconPath('add_circle_regular')} fill="var(--indigo-8)" className="icon mr-2" size="20px" />
                <span>Create a sellpage</span>
              </Button>
            ) : (
              <Button type="neutral" className="w-100 mt-3" size="medium" disabled>
                <span>{!product.sellable ? 'Sold out' : 'Unavailable'}</span>
              </Button>
            )}
          </div>
          <PreviewProductDialog ref={modalRef} />
        </div>
      </Card>
      <style jsx>{style}</style>
    </div>
  )
}

const style = css`
  $imageHeight: 268px;
  .product-item {
    padding-bottom: 16px;
    height: 100%;
    header {
      color: var(--gray-8);
    }

    .info {
      color: var(--indigo-8);
    }

    .page-note {
      font-size: 12px;
      line-height: 24px;
      font-weight: 600;
      color: var(--indigo-8);
      background: var(--indigo-2);
      border-radius: 4px;
      padding: 0px 8px;
      width: fit-content;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }
    .content {
      padding: 8px 16px 16px 16px;
      height: calc(100% - 268px);
      .you-take {
        margin-top: 12px;
      }
    }
    .money {
      color: var(--blue-600);
      font-weight: 500;
      font-size: 13px;
      line-height: 16px;

      &__line {
        border-bottom: 1px dashed var(--blue-600);
      }
    }

    .image {
      width: 100%;
      position: relative;
      height: 268px;
      display: block;
      img {
        object-fit: cover;
        width: 100%;
        border-radius: 8px 8px 0px 0px;
        height: 268px;
        bottom: 0;
        left: 0;
        right: 0;
        top: 0;
      }
      .product-action {
        display: none;
        margin: auto;
        z-index: 1;
        position: absolute;
        border-radius: 8px 8px 0px 0px;
        background: rgba(93, 93, 93, 0.53);
        top: 0;
        right: 0;
        border: 0;
        left: 0;
        height: 100%;
        background-size: cover;
        width: 100%;
        :global(.button_) {
          width: 185px;
        }
      }

      &:hover {
        .product-action {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
        }
      }
    }
    :global(.p-inputgroup) {
      height: 32px;
      width: 100%;
      max-width: 100px;
    }

    :global(.p-inputtext) {
      text-align: right;
      padding: 0 8px;
    }
  }
`
