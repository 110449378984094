import { InputTextProps, InputText as PInputText } from 'primereact/inputtext'

import { Icon } from 'com-ui2/components'
import { iconPath } from 'com-ui/utilities/imagePath'
import { useRef } from 'react'

interface IInputText extends InputTextProps {
  dismissIcon?: boolean
}

export function InputText({ className, placeholder, value, onChange, dismissIcon = true, ...props }: IInputText) {
  const ref = useRef(null)
  const reset = () => {
    const target: any = ref.current
    target.value = ''
    onChange?.({ target: target } as any)
  }
  return (
    <div className={`input-text-wrapper ${className || ''}`}>
      <PInputText value={value} onChange={onChange} placeholder={placeholder} {...(props as any)} ref={ref} />
      {!!value && dismissIcon && (
        <div className="icon-wrapper">
          <Icon size="14px" src={iconPath('dismiss_regular')} fill="var(--gray-6)" onClick={reset} />
        </div>
      )}
      <style jsx>
        {`
          .input-text-wrapper {
            position: relative;
            :global(.p-inputtext) {
              width: 100%;
              &:focus,
              &:hover {
                & ~ .icon-wrapper {
                  display: inline-flex;
                }
              }
            }
          }
          .icon-wrapper {
            display: none;
            position: absolute;
            align-items: center;
            justify-content: center;
            width: 24px;
            height: 24px;
            top: 50%;
            transform: translateY(-50%);
            right: 4px;
            background-color: var(--white-100);
            &:hover {
              display: inline-flex;
            }
          }
        `}
      </style>
    </div>
  )
}
