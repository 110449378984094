import CrLabel from './cr-label'
import { IAllProps } from '@tinymce/tinymce-react'
import dynamic from 'next/dynamic'
import getConfig from 'next/config'
import styled from 'styled-components'
import { useId } from '@fluentui/react-hooks'

const Editor = dynamic(() => import('@tinymce/tinymce-react').then((mod: any) => mod.Editor), { ssr: false })

export interface ICrHtmlEditor extends Omit<Types.ICrEditor, 'value'>, Types.ISchema, IAllProps {
  onDirtyChanged?: (value: string) => void
}

const config: Partial<IAllProps> = {
  apiKey: getConfig().publicRuntimeConfig.tinymceApiKey,
  init: {
    min_height: 250,
    max_height: 600,
    resize: false,
    branding: false,
    relative_urls: false,
    menubar: false,
    statusbar: true,
    setup: (editor: any) => {
      editor.ui.registry.addContextToolbar('imagealignment', {
        predicate: (node: any) => {
          return node.nodeName.toLowerCase() === 'img'
        },
        items: 'alignleft aligncenter alignright',
        position: 'node',
        scope: 'node'
      })

      editor.ui.registry.addContextToolbar('textselection', {
        predicate: () => {
          return !editor.selection.isCollapsed()
        },
        items: 'bold italic underline',
        position: 'selection',
        scope: 'node'
      })
    },
    toolbar:
      'undo redo | formatselect | bold italic underline forecolor backcolor | \
      alignleft aligncenter alignright alignjustify | \
      outdent indent | bullist numlist | table link image media | removeformat | help | code fullscreen preview',
    toolbar_mode: 'wrap',
    plugins: [
      'advlist autolink lists link image charmap print preview anchor autoresize',
      'searchreplace visualblocks code fullscreen',
      'insertdatetime media table code help wordcount'
    ],
    content_style: `
      body {
        margin: 0 8px;
      }
    `
  }
}

export function CrHtmlEditor(props: ICrHtmlEditor) {
  const htmlEditorId = useId('htmlEditorId')

  const injectAttrs = (ref: any) => {
    const input: HTMLInputElement = ref?.elementRef?.current
    if (!input) return
    input.setAttribute('placeholder', props?.placeholder || '')
    input.setAttribute('id', htmlEditorId)
    input.setAttribute('type', 'number')
  }

  const onEditorChange = (a: string, editor: any) => {
    if (props.onDirtyChanged) {
      if (editor.isDirty()) props.onDirtyChanged(a)
    } else {
      props.onEditorChange && props.onEditorChange(a, editor)
    }
  }
  return (
    <CrLabel {...{ htmlFor: htmlEditorId, label: props.label, className: props.className }}>
      <EditorContainer className={props.errorMessage && 'error'}>
        {/*
          // @ts-ignore */}
        <Editor
          {...{
            ...config,
            ...props,
            value: props.value || '',
            ref: injectAttrs,
            onEditorChange,
            init: { ...config.init, ...props.init, file_picker_types: 'media' }
          }}
        />
      </EditorContainer>
    </CrLabel>
  )
}

const EditorContainer = styled.div`
  &.error {
    border-color: var(--danger-100);
  }
  .tox-tinymce {
    border: 1px solid var(--indigo-4);
    border-radius: 8px;
  }
  .tox {
    .tox-toolbar,
    .tox-toolbar__overflow,
    .tox-toolbar__primary {
      background: var(--white-100);
      border-bottom: 1px solid var(--indigo-4);
    }
    &:not([dir='rtl']) .tox-toolbar__group:not(:last-of-type) {
      border-right: 1px solid var(--indigo-4);
    }
    .tox-tbtn svg {
      fill: var(--gray-7);
    }
    .tox-statusbar__path {
      display: none;
    }
    .tox-statusbar {
      border-top: 0;
    }
    .tox-statusbar__wordcount {
      color: #9198a9;
      text-transform: lowercase;
    }
  }
  /*.tox .tox-tbtn {
    width: 20px;
    height: 20px;
  } */
`
