import { ILabelStyles, ITextFieldStyleProps, ITextFieldStyles, mergeStyleSets } from '@fluentui/react'

export const textFieldStyles = (props: ITextFieldStyleProps): Partial<ITextFieldStyles> => {
  const fieldGroup = {
    height: 'unset',
    backgroundColor: 'var(--white-100)',
    borderRadius: '4px'
  }
  const field = {
    fontSize: 13,
    lineHeight: 24,
    padding: '7px 16px',
    '&[readonly]': {
      cursor: 'no-drop'
    }
  }
  const labelStyles = (): Partial<ILabelStyles> => ({
    ...{
      root: {
        fontSize: 13,
        fontWeight: 600,
        lineHeight: 24,
        color: 'var(--gray-7)',
        padding: 0,
        ...(props.required && {
          '&::after': {
            color: 'var(--secondary-100)'
          }
        })
      }
    }
  })
  const fieldPrefixSuffix = {
    color: 'var(--gray-8)',
    padding: '7px 16px'
  }
  const prefix = {
    ...fieldPrefixSuffix,
    borderBottomLeftRadius: 'var(--round-4)',
    borderTopLeftRadius: 'var(--round-4)',
    borderRight: 'solid 1px var(--gray-3)'
  }
  const suffix = {
    ...fieldPrefixSuffix,
    borderBottomRightRadius: 'var(--round-4)',
    borderTopRightRadius: 'var(--round-4)',
    borderLeft: 'solid 1px var(--gray-3)'
  }
  const icon = {
    top: 8,
    bottom: 8,
    fontSize: 24,
    lineHeight: 24,
    color: 'var(--gray-7)'
  }

  const revealButton = {
    height: 40,
    width: 40,
    padding: '0 8px'
  }

  const revealIcon = {
    margin: 'unset',
    fontSize: 20,
    color: 'var(--gray-8)'
  }

  return {
    ...{
      fieldGroup,
      field,
      subComponentStyles: {
        label: labelStyles
      },
      prefix,
      suffix,
      icon,
      revealButton,
      revealIcon
    },
    ...(props.hasErrorMessage && {
      fieldGroup: {
        ...fieldGroup,
        ...{
          borderColor: 'var(--danger-30)'
        }
      }
    }),
    ...(props.hasIcon && {
      field: {
        ...field,
        ...{ paddingRight: 40 }
      }
    }),
    ...(props.disabled && {
      field: {
        ...field,
        ...{ cursor: 'no-drop' }
      }
    })
  }
}

export const readOnlyTextFieldStyles = mergeStyleSets({
  fieldGroup: {
    borderColor: 'var(--indigo-2)',
    ':after': {
      border: 'none'
    },
    ':hover': {
      borderColor: 'var(--indigo-2)'
    }
  }
})
