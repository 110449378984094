import { PublisherInfo } from 'src/services'
import { namespaceConfig } from 'fast-redux'

export interface PublisherProps extends PublisherInfo {
  loading: boolean
  apiStatus: number
}

const DEFAULT_STATE = {}

const { action, getState } = namespaceConfig('publisher', DEFAULT_STATE)

export const getPublisherState = (state: any): PublisherProps | undefined => getState(state)
export const getPublisherIdState = (state: any): string | undefined => getPublisherState(state)?.id
export const getPublisherLoadingState = (state: any): boolean | undefined =>
  (getPublisherState(state) as PublisherProps)?.loading
export const getPublisherApiStatusState = (state: any): number | undefined =>
  (getPublisherState(state) as PublisherProps)?.apiStatus

export const setPublisher = action('setPublisher', (state: PublisherInfo, data: PublisherInfo) => {
  if (!data) return state
  return { ...state, ...data }
})

export const updatePublisher = action('updatePublisher', (state: PublisherInfo, data: PublisherInfo) => {
  if (!data) return state
  return {
    ...state,
    ...data
  }
})

export const setLoadingPublisher = action('setLoadingPublisher', function (state: PublisherInfo, loading: boolean) {
  if ((state as PublisherProps).loading == loading) {
    return state
  }
  const nextState = {
    ...state,
    loading
  }
  return nextState
})
