import { getProfileState } from 'com-ui-biz/profile/stores/store'
import { useSelector } from 'react-redux'
import getConfig from 'next/config'
import { getPublisherState } from 'src/containers/settings/store'
import { getShopStoreProps } from 'src/stores/shop'
import { getPrimaryDomain } from 'src/helpers'
const isDev = getConfig().publicRuntimeConfig.env === 'dev'

export const useCheckEmail = () => {
  const emailAccepts = [
    'anhnh@upinus.com',
    'phuongnc2@upinus.com',
    'tungnt@upinus.com',
    'calvin.cpm@upinus.com',
    'ngan.vu2@crossian.com',
    'tuandm@upinus.com',
    'cuongvm@upinus.com'
  ]
  const email = useSelector(getProfileState)?.email || ''
  return emailAccepts?.includes(email)
}

export const useCheckAccTax = () => {
  // const emailAccepts = ['crossian.test.publisher3@gmail.com']
  // const idAccepts = [
  //   '3lhfd4duviyu0ttdic37h0kqh',
  //   '6otlsaxl3kotc0ggpn74iwnai',
  //   '193',
  //   '63we1t1731rujx0xv4bmh90sf',
  //   '194',
  //   'd23opsnyc8g11pke0bc24dts',
  //   '46',
  //   '76z6uhcoh26kbqszn69jdvkn8',
  //   '3gqryadmc1zz98me45g8h02br',
  //   '46hs370ihj6cbyqc5m7h8m246',
  //   '148'
  // ]
  // const emailInDev = useSelector(getProfileState)?.email || ''
  // const idInProd = useSelector(getProfileState)?.id || ''
  // const condition = isDev ? emailAccepts?.includes(emailInDev) : idAccepts?.includes(idInProd)
  return true
}

export const useCheckUser = () => {
  // const prefixEmail1 = '@crossian.com'
  // const prefixEmail2 = '@upinus.com'
  // const email = useSelector(getProfileState)?.email || ''
  // return email?.includes(prefixEmail1) || email?.includes(prefixEmail2)
  return true
}

export const useCheckABTestAcc = (): boolean => {
  const emailAccepts = getConfig()?.publicRuntimeConfig?.abContentAcc?.split(',') || []
  const email = useSelector(getProfileState)?.email || ''
  return !emailAccepts?.includes(email)
}

export const useCheckUserCommon = () => {
  const emailAccepts = ['phonglh@upinus.com', 'tungnt@upinus.com', 'phonglh@crossian.us', 'tungnt@crossian.us']
  const email = useSelector(getProfileState)?.email || ''
  return isDev || emailAccepts?.includes(email)
}

export const useCheckEmailPixel = () => {
  // const emailAccepts = [
  //   'hieu@upinus.com',
  //   'hieu@crossian.us',
  //   'anh.nguyenviet@crossian.com',
  //   'phuong.nc@crossian.com',
  //   'marketer01@crossian.com',
  //   'yendt@upinus.com',
  //   'yendt@crossian.us',
  //   'huy.phan@crossian.com',
  //   'tungnt@upinus.com',
  //   'tungnt@crossian.us',
  //   'phonglh@upinus.com',
  //   'phonglh@crossian.us',
  //   'tuyennh@upinus.com',
  //   'tuyennh@crossian.us',
  //   'tuyen.nguyen@crossian.com',
  //   'Tuyen.nguyen@crossian.com',
  //   'phong.le@crossian.com',
  //   'hoa.thinguyen@crossian.com',
  //   'anh.hoangduy@crossian.com',
  //   'nguyen.toaitruong@crossian.com',
  //   'tu.le@crossian.com',
  //   'tu.minhpham@crossian.com',
  //   'nampd@crossian.us'
  // ]
  // const email = useSelector(getProfileState)?.email || ''
  // return isDev || emailAccepts?.includes(email?.toLowerCase())
  return true
}

export const useCheckBVF = () => {
  return useSelector(getPublisherState)?.type == 'BVF'
}

export const useCheckRMKT = () => {
  return useSelector(getPublisherState)?.type == 'RMKT'
}

// export const useCheckRatingDomain = () => {
//   const domainAccepts = [
//     'noerror.onselless.dev',
//     'testshop.dev',
//     'teststore.dev',
//     '3plproducttest.onselless.dev',
//     'aromia.us',
//     'onavee.us',
//     'elegantaboutique.us',
//     'venuscharm.us',
//     'rosiaa.com',
//     'hansihandy.com',
//     'julidostore.us',
//     'pinkchloe.com',
//     'avacharm.com',
//     'devstore.onselless.com',
//     'dohova.com',
//     'eleganchic.com',
//     'calormas.com',
//     'denimblack.com',
//     'truecups.com',
//     'bevawear.com',
//     'monocharm.com',
//     'bloomydove.com',
//     'loomtext.com',
//     'maidenfit.com',
//     'lilasha.com',
//     'maracharm.com'
//   ]
//   const domains = useSelector(getShopStoreProps('domains'))
//   const primaryDomain = getPrimaryDomain(domains) || ''
//   return domainAccepts?.includes(primaryDomain)
//   // return true
// }

export const useCheckReviewDomain = () => {
  const domainAccepts = [
    'noerror.onselless.dev',
    'testshop.dev',
    'teststore.dev',
    '3plproducttest.onselless.dev',
    'testsite.onselless.com'
  ]
  const domains = useSelector(getShopStoreProps('domains'))
  const primaryDomain = getPrimaryDomain(domains) || ''
  return domainAccepts?.includes(primaryDomain)
}

export const useCheckUpsell = () => {
  const publisherAccepts = ['IHO', 'RMKT', 'BVF']
  const pubblisherType = useSelector(getPublisherState)?.type || ''
  return publisherAccepts?.includes(pubblisherType)
}

export const useCheckHttpool = () => {
  const domainAccepts = [
    'aromia.us',
    'onavee.us',
    'elegantaboutique.us',
    'venuscharm.us',
    'rosiaa.com',
    'hansihandy.com',
    'julidostore.us',
    'pinkchloe.com',
    'avacharm.com',
    'teeliny.us',
    'lovelie.com',
    'teststore.dev',
    'testshop.dev',
    '3plproducttest.onselless.dev',
    'eleganchic.com',
    'calormas.com',
    'denimblack.com',
    'truecups.com',
    'bevawear.com',
    'monocharm.com',
    'bloomydove.com',
    'loomtext.com',
    'maidenfit.com',
    'lilasha.com',
    'maracharm.com'
  ]
  const domains = useSelector(getShopStoreProps('domains'))
  const primaryDomain = getPrimaryDomain(domains) || ''
  return domainAccepts?.includes(primaryDomain)
}
