import { InputNumberProps as PInputNumberProps, InputNumber as PInputNumber } from 'primereact/inputnumber'

export interface InputNumberProps extends PInputNumberProps {
  buttonType?: 'show-button' | 'hide-button'
  borderType?: 'show-border' | 'hide-border'
}

export function InputNumber({
  className,
  buttonType = 'show-button',
  borderType = 'show-border',
  ...props
}: InputNumberProps) {
  return (
    <>
      <PInputNumber
        className={`${className || ''} ${buttonType} ${borderType}`}
        showButtons
        buttonLayout="horizontal"
        incrementButtonIcon="pi pi-plus"
        decrementButtonIcon="pi pi-minus"
        {...props}
      />
      <style jsx global>
        {`
          .p-inputnumber {
            background-color: var(--white-100);
            border-radius: 4px;
            border: 1px solid var(--indigo-2);
            align-items: center;
            display: flex;
            box-shadow: var(--depth-4);
            transition: border var(--transition), box-shadow var(--transition);
            &:hover,
            &.p-inputwrapper-focus {
              box-shadow: var(--depth-2);
              border: 1px solid var(--indigo-4);
            }
            &.hide-button {
              .p-button.p-inputnumber-button-down,
              .p-button.p-inputnumber-button-up {
                display: none;
              }
            }
            &.hide-border {
              &,
              &:focus,
              &:hover {
                border: none;
                box-shadow: none;
              }
            }
          }
          .p-inputnumber.p-inputwrapper-focus .p-inputnumber-input,
          .p-inputnumber .p-inputnumber-input {
            &,
            &:focus,
            &:hover {
              box-shadow: none;
              border-color: transparent;
              width: 100%;
            }
          }
          .p-button.p-inputnumber-button-down,
          .p-button.p-inputnumber-button-up {
            height: 32px;
            width: 28px;
            margin-right: 4px;
            color: var(--gray-8);
            background-color: var(--indigo-2);
            border: none;
            &:hover,
            &:focus,
            &:active {
              color: var(--gray-8);
              background-color: var(--indigo-4);
            }
          }
          .p-inputnumber-buttons-horizontal .p-button.p-inputnumber-button-down {
            order: 2;
            margin-left: 4px;
          }
        `}
      </style>
    </>
  )
}
