import { InputDateRangeProps, MAX_DATE, MIN_DATE } from './DateHelper'
import { Range, createStaticRanges, defaultStaticRanges } from 'react-date-range'
import { addDays, endOfDay, startOfDay } from 'date-fns'

import dynamic from 'next/dynamic'
import { isEqual } from 'date-fns'

const DateRangePicker = dynamic(() => import('react-date-range').then((mod: any) => mod.DateRangePicker), {
  ssr: false
})

export function PickerDateRange({
  startDate = new Date(),
  endDate = new Date(),
  minDate = MIN_DATE,
  maxDate = MAX_DATE,
  onChange,
  pickerProps
}: InputDateRangeProps) {
  const range: Range = {
    startDate: startDate,
    endDate: endDate,
    key: 'selection'
  }

  const _onChange = (event: any) => {
    const { startDate: nextStartDate, endDate: nextEndDate } = event.selection || {}
    if (!startDate || !endDate || !isEqual(startDate, nextStartDate) || !isEqual(endDate, nextEndDate)) {
      onChange?.(nextStartDate, nextEndDate)
    }
  }
  return (
    <div className="date-picker-container">
      <DateRangePicker
        ranges={[range]}
        minDate={minDate}
        maxDate={maxDate}
        onChange={_onChange}
        showSelectionPreview={true}
        direction="vertical"
        scroll={{ enabled: true }}
        editableDateInputs={false}
        dateDisplayFormat="dd-MM-yyyy"
        weekdayDisplayFormat="EEEEE"
        showMonthAndYearPickers={true}
        weekStartsOn={1}
        staticRanges={funcCustomRange(!!pickerProps?.customLifeTime)}
        {...pickerProps}
      />
    </div>
  )
}

const funcCustomRange = (customLifeTime?: boolean) => {
  return createStaticRanges([
    // @ts-ignore
    ...defaultStaticRanges.slice(0, 2),
    // @ts-ignore
    {
      label: 'Last 7 days',
      range: () => ({
        startDate: startOfDay(addDays(new Date(), -7)),
        endDate: endOfDay(new Date())
      })
    },
    // @ts-ignore
    {
      label: 'Last 14 days',
      range: () => ({
        startDate: startOfDay(addDays(new Date(), -14)),
        endDate: endOfDay(new Date())
      })
    },
    // @ts-ignore
    {
      label: 'Last 30 days',
      range: () => ({
        startDate: startOfDay(addDays(new Date(), -30)),
        endDate: endOfDay(new Date())
      })
    },
    // @ts-ignore
    ...defaultStaticRanges.slice(2),
    {
      label: 'Lifetime',
      // @ts-ignore
      isSelected: (range) => {
        return (
          range.startDate?.getTime() === new Date(0).getTime() &&
          range.endDate?.getTime() === endOfDay(new Date()).getTime()
        )
      },
      range: () => ({
        startDate: new Date(0),
        endDate: customLifeTime ? new Date(MAX_DATE) : endOfDay(new Date())
      })
    }
  ])
}
