import { ChangeEvent, useEffect, useState } from 'react'
import CrLabel from './cr-label'
import { FileDrop } from 'react-file-drop'
import { Icon } from '@fluentui/react'
import styled from 'styled-components'
import { useId } from '@fluentui/react-hooks'

export interface CrFileUploaderProps extends Types.PlainObject {
  label?: string
  width?: string
  height?: string
  multiple?: boolean
  enableUploadSameFileTwice?: boolean
  placeholder?: string
  onFilesChanged?: (files: File[]) => void
  acceptFileType?: string
  disabled?: boolean
}

export default function CrFileUploader({ enableUploadSameFileTwice = true, ...props }: CrFileUploaderProps) {
  const uploaderId = useId('uploaderId')
  const [isInit, setIsInit] = useState(false)

  const onChange = (event: ChangeEvent<HTMLInputElement>) => {
    !props.disabled && props?.onFilesChanged?.(getFileList(event.target.files))
  }

  const onDrop = (files: FileList | null) => {
    !props.disabled && props?.onFilesChanged?.(getFileList(files))
  }

  const onClick = (event: any) => {
    enableUploadSameFileTwice && (event.target.value = null)
  }

  useEffect(() => {
    setIsInit(true)
  }, [])

  return (
    <Container
      {...{
        width: props.width,
        height: props.height,
        htmlFor: uploaderId,
        className: `${props.className} ${props.disabled ? 'disabled' : ''}`
      }}
    >
      {isInit && (
        <FileDrop onDrop={onDrop}>
          <label>
            <span className="content">
              {props?.icon != false && <Icon iconName="arrow_up_circle_24_regular" className="icon" />}
              {props?.placeholder || 'Upload image'}
            </span>
            <input
              id={uploaderId}
              type="file"
              onChange={onChange}
              onClick={onClick}
              multiple={props.multiple}
              accept={props.acceptFileType}
            />
          </label>
        </FileDrop>
      )}
    </Container>
  )
}

const getFileList = (files: FileList | null): File[] => {
  if (!files || files.length === 0) return []

  const fileList: File[] = []
  for (let i = 0; i < files.length; i++) {
    const file = files.item(i)
    if (file) fileList.push(file)
  }
  return fileList
}

const Container = styled(CrLabel)`
  width: ${(props: CrFileUploaderProps) => props?.width || '100%'};
  height: ${(props: CrFileUploaderProps) => props?.height};
  display: flex;
  flex-direction: column;
  .file-drop {
    width: 100%;
    height: 100%;
    .file-drop-target {
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 3px dashed var(--indigo-3);
      background-color: var(--indigo-1);
      color: var(--indigo-7);
      border-radius: 8px;
      transition: var(--transition);
      cursor: pointer;
      &:hover,
      &.file-drop-dragging-over-target {
        color: var(--indigo-8);
        border-color: var(--indigo-6);
      }
    }

    label {
      cursor: pointer;
      height: 100%;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
    }

    .icon {
      font-size: 28px;
      color: var(--indigo-6);
      width: 100%;
    }

    .content {
      text-align: center;
    }

    input[type='file'] {
      display: none;
    }
  }
  &.disabled {
    opacity: 0.7;
    .file-drop .file-drop-target {
      pointer-events: none;
    }
  }
`
