import 'react-date-range/dist/styles.css'
import 'react-date-range/dist/theme/default.css'

import { DateRangePicker, createStaticRanges, defaultStaticRanges } from 'react-date-range'
import { addDays, endOfDay, startOfDay } from 'date-fns'
import { useEffect, useRef, useState } from 'react'

import CrLabel from './cr-label'
import { CrTextField } from './text-field'
import { DateContainer } from './cr-date.editor'
import format from 'date-fns/format'
import { useId } from '@fluentui/react-hooks'

export default function CrDateRangeEditor(props: any) {
  const datePickerId = useId('datePickerId')
  const ref = useRef(null)
  const [showPicker, setShowPicker] = useState(false)
  const [range, setRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: 'selection'
  })

  useEffect(() => {
    function handleContains(event: React.MouseEvent<HTMLDivElement, MouseEvent> | any) {
      const container: any = ref.current
      const contain = container && container.contains(event.target)
      if (!contain) {
        setShowPicker(false)
      }
    }
    document && document.addEventListener('mousedown', handleContains)
    return () => {
      document && document.removeEventListener('mousedown', handleContains)
    }
  })

  const onChange = (_date: any) => {
    setRange(_date.selection)
  }

  const value = `${format(range.startDate, 'dd-MM-yyyy')} > ${format(range.endDate, 'dd-MM-yyyy')}`

  return (
    <CrLabel {...{ ...props, htmlFor: datePickerId }}>
      <DateContainer ref={ref}>
        <div className="date-input" onClick={(_e) => setShowPicker(!showPicker)}>
          <CrTextField {...{ ...props, label: '', value, onChange: () => null }} />
        </div>
        {showPicker && (
          <div className="date-picker">
            <DateRangePicker
              ranges={[range]}
              onChange={onChange}
              showSelectionPreview={true}
              direction="vertical"
              scroll={{ enabled: true, calendarHeight: 220 }}
              editableDateInputs={true}
              dateDisplayFormat="dd-MM-yyyy"
              weekdayDisplayFormat="EEEEE"
              showMonthAndYearPickers={false}
              weekStartsOn={1}
              staticRanges={customRange}
            />
          </div>
        )}
      </DateContainer>
    </CrLabel>
  )
}

// @ts-ignore
const customRange = createStaticRanges([
  ...defaultStaticRanges.slice(0, 2),
  {
    label: 'Last 7 days',
    range: () => ({
      startDate: startOfDay(addDays(new Date(), -7)),
      endDate: endOfDay(new Date())
    })
  },
  {
    label: 'Last 14 days',
    range: () => ({
      startDate: startOfDay(addDays(new Date(), -14)),
      endDate: endOfDay(new Date())
    })
  },
  {
    label: 'Last 30 days',
    range: () => ({
      startDate: startOfDay(addDays(new Date(), -30)),
      endDate: endOfDay(new Date())
    })
  },
  ...defaultStaticRanges.slice(2)
])
