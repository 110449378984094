import App, { AppContext } from 'next/app'
import {
  AxiosDefault,
  getRouterType,
  privateRouters,
  profileHash,
  publicRouters,
  removeParam,
  showToast
} from 'com-ui2/utilities'
import { NextRouter, useRouter } from 'next/router'
import { ProfileProps, addAllowedPath, getAllowedPathState, setProfile } from 'com-ui-biz/profile/stores/store'
import { useSelector, useStore } from 'react-redux'

import { Loading } from 'com-ui2/components'
import { Store } from 'redux'
import getConfig from 'next/config'
import { useEffect } from 'react'
import { useMemo } from 'react'

declare global {
  interface Window {
    profileId: any
  }
}

export const isChangeLogin = (pathname: string, profileId: string | null | undefined) =>
  (privateRouters(pathname) && !profileId) || (!privateRouters(pathname) && profileId)

const _axios = AxiosDefault()

export const withAuthCore = (options?: { extProtectRoute: ExtProtectRoute }) => (WrappedComponent: any) => {
  const WithAuthWrapper = (props: any) => {
    const router = useRouter()
    const store = useStore()
    useEffect(() => {
      protectRoute(store, router, options?.extProtectRoute)
    }, [router.asPath])

    return <ProfileContainer>{WrappedComponent({ ...props })}</ProfileContainer>
  }

  WithAuthWrapper.getInitialProps = async (appContext: AppContext) => {
    const componentProps = WrappedComponent.getInitialProps && (await WrappedComponent.getInitialProps(appContext))
    const appProps = App.getInitialProps && (await App.getInitialProps(appContext))
    return { ...componentProps, ...appProps }
  }

  return WithAuthWrapper
}

const ProfileContainer = ({ children }: any) => {
  const router = useRouter()
  const pathname = router.asPath
  const routerType = getRouterType(pathname)
  const loading = useSelector(getAllowedPathState)?.[routerType] != true

  return useMemo(() => (loading == false ? children : <Loading isLoading={true} isFullWidth={true} />), [
    loading,
    children
  ])
}

const protectRoute = async (store?: Store, router?: NextRouter, extProtectRoute?: ExtProtectRoute) => {
  if (!store || !router) {
    return
  }
  const specialParams = ['logging_in', 'timeout', 'permission_insufficient']
  const pathname = removeParam(window.location.pathname + window.location.search, ...specialParams)
  const profile: ProfileProps | undefined = await getProfile(store, pathname)

  processSpecialParams(router, profile)

  let redirect = ''
  if (privateRouters(pathname) && !profile?.id) {
    // not have permission
    const returnUrl = !['/', '/404'].includes(pathname) ? `?return_url=${pathname}` : ''
    redirect = `/login${returnUrl}`
  } else if (publicRouters(pathname) && profile?.id) {
    // logged in
    redirect = router.query?.return_url?.toString() || '/'
  }

  if (profile?.id) {
    window.profileId = profileHash(profile)
    // check external rule
    redirect = (await extProtectRoute?.({ store, router, pathname, redirect })) ?? redirect
  }

  console.log('redirect', redirect)

  if (redirect === '/logout') {
    const originUrl = window.location.origin
    const logoutUrl = `${
      getConfig().publicRuntimeConfig.apiBasePath
    }auth/logout?redirect_uri=${originUrl}/login?permission_insufficient=true`
    ;(window as any).location.replace(logoutUrl)
    return
  }
  if (redirect) {
    await router.replace(redirect, undefined, { shallow: true })
  } else if (specialParams.some((p) => router.query[p])) {
    //login 3rd party success and remove param logging_in
    window.history.replaceState('', '', pathname) // change url without reloading
  }
  await store.dispatch(addAllowedPath(getRouterType(redirect || pathname)))
}

const getProfile = async (store: Store, pathname: string) => {
  let profile: ProfileProps | undefined = store.getState()?.profile
  if (isChangeLogin(pathname, profile?.id) || !privateRouters(pathname)) {
    try {
      const result = await _axios.get(`/auth/profile`)
      profile = result.data
      await store.dispatch(setProfile(profile))
    } catch (error) {
      console.log(error)
    }
  }
  return profile
}

const processSpecialParams = (router: NextRouter, profile?: ProfileProps) => {
  if (router.query.logging_in && !profile?.id) {
    //logging_in 3rd party but fail and back to login
    showToast({
      severity: 'error',
      summary: `Login fail`,
      detail: `User account is INACTIVE`,
      life: 5000
    })
  }

  if (router.query.timeout && !profile?.id) {
    //timeout and logout
    showToast({
      severity: 'error',
      summary: `Session timeout`,
      detail: `Please login again`,
      life: 5000
    })
  }

  if (router.query.permission_insufficient && !profile?.id) {
    //timeout and logout
    showToast({
      severity: 'error',
      summary: `You do not have sufficient privileges`,
      detail: `Please contact admin`,
      life: 5000
    })
  }
}

/**
 * return null if not redirect
 */
export type ExtProtectRoute = (params: {
  store: Store
  router: NextRouter
  pathname: string
  redirect: string
}) => Promise<string | null | undefined>
