import { useEffect, useRef, useState } from 'react'
import { SketchPicker } from 'react-color'
import css from 'styled-jsx/css'
import { InputText } from './InputText'

interface IColorEditor {
  value?: string
  onChange?: (value: string) => void
  className?: string
}

export function ColorInput(props: IColorEditor) {
  const [selectedColor, setSelectedColor] = useState(props.value)
  const ref = useRef(null)
  const [showPicker, setShowPicker] = useState(false)

  useEffect(() => {
    function handleContains(event: React.MouseEvent<HTMLDivElement, MouseEvent> | any) {
      const container: any = ref.current
      const contain = container && container.contains(event.target)
      if (!contain) {
        setShowPicker(false)
      }
    }
    document && document.addEventListener('mousedown', handleContains)
    return () => {
      document && document.removeEventListener('mousedown', handleContains)
    }
  }, [])

  useEffect(() => {
    selectedColor !== props.value && setSelectedColor(props.value)
  }, [props.value])

  return (
    <div className={`color-input ${props.className ?? ''}`} ref={ref}>
      <InputText value={selectedColor || ''} onClick={() => setShowPicker(!showPicker)} dismissIcon={false} />
      <span className="color-preview" style={{ backgroundColor: selectedColor || '' }}></span>
      {showPicker && (
        <div className="color-picker">
          <SketchPicker
            color={selectedColor}
            onChange={(e: any) => setSelectedColor(e.hex)}
            onChangeComplete={(e: any) => props?.onChange?.(e.hex ?? '')}
          />
        </div>
      )}
      <style jsx>{style}</style>
    </div>
  )
}

const style = css`
  .color-input {
    position: relative;
    :global(.input-text-wrapper input) {
      padding-left: 50px;
    }
    .color-preview {
      position: absolute;
      top: 50%;
      left: 12px;
      transform: translateY(-50%);
      display: inline-block;
      width: 32px;
      height: 20px;
      border-radius: 30px;
      border: 1px solid var(--indigo-3);
      margin-right: 5px;
      pointer-events: none;
    }
    .color-picker {
      position: absolute;
      display: inline-block;
      top: 100%;
      left: 0;
      z-index: 10;
    }
  }
  :global(.color-input .p-inputtext, .color-input .p-inputtext:enabled) {
    border-color: var(--white-100);
    box-shadow: none;
    &:hover {
      border: 1px solid var(--indigo-2);
      box-shadow: var(--depth-2);
    }
  }
`
