import { ToastMessage } from 'primereact/toast'
import { useEffect, useRef } from 'react'
import { Toast } from 'primereact/toast'
import App, { AppContext } from 'next/app'
declare global {
  interface Window {
    toastRef: any
  }
}

export const showToast = (message: ToastMessage | ToastMessage[]) => {
  if (process.browser) {
    window?.toastRef?.current?.show?.(message)
  }
}

export const clearToast = () => {
  if (process.browser) {
    window?.toastRef?.current?.clear?.()
  }
}

export const withToast = (component: any) => {
  const ToastWrapper = (props: any) => {
    const toastRef = useRef<any>()
    useEffect(() => {
      window.toastRef = toastRef
    }, [])
    return (
      <>
        {component?.(props)}
        <Toast ref={toastRef} baseZIndex={1101} />
      </>
    )
  }
  ToastWrapper.getInitialProps = async (appContext: AppContext) => {
    const componentProps = component.getInitialProps && (await component.getInitialProps(appContext))
    const appProps = App.getInitialProps && (await App.getInitialProps(appContext))
    return { ...componentProps, ...appProps }
  }
  return ToastWrapper
}
