import { ShippingRuleMethod } from 'src/services'
import getConfig from 'next/config'
import { getPublisherApiStatusState } from './../containers/settings/store'
import { useSelector } from 'react-redux'

const runtimeConfig = getConfig().publicRuntimeConfig

export const config = {
  cdnBasePath: runtimeConfig.cdnBasePath
}

export const getCmsRoutes = (): CMSTypes.RouteObj[] => {
  const publisherStatus = useSelector(getPublisherApiStatusState)
  return publisherStatus == 200 ? cmsRoutes : publisherStatus == 201 ? cmsRoutes.slice(-1) : []
}

const cmsRoutes: CMSTypes.RouteObj[] = [
  {
    href: '/sell-pages',
    icon: 'store_alt',
    text: 'Sellpages',
    description: '',
    iconDescription: ''
  },
  {
    href: '/products',
    icon: 'shopping_bag',
    text: 'Products',
    description: 'Products only available for sales in the US market.',
    iconDescription: ''
  },
  {
    href: '/orders',
    icon: 'shopping_cart',
    text: 'Orders',
    description: '',
    iconDescription: ''
  },
  {
    href: '/finances',
    icon: 'heavy_dollar_sign',
    text: 'Finances',
    description: '',
    iconDescription: ''
  },
  {
    href: '/settings',
    icon: 'settings_filled',
    text: 'Settings',
    description: '',
    iconDescription: ''
  },
  {
    href: '/learning',
    icon: 'book_open_filled',
    text: 'Learning',
    description: '',
    iconDescription: ''
  }
]

export const collapseRouter = ['/sell-pages/ads-manager/[platform]']

export const shippingRuleMethodText = {
  [ShippingRuleMethod.eq]: '=',
  [ShippingRuleMethod.ge]: '>=',
  [ShippingRuleMethod.gt]: '>',
  [ShippingRuleMethod.le]: '<=',
  [ShippingRuleMethod.lt]: '<'
}

export const CMS_TIME_FORMAT = 'MM-dd-yyyy HH:mm'
export const DATE_TIME = `yyyy-MM-dd'T'HH:mm:ss.SSS`

export const filterOptions = [
  {
    label: 'today',
    key: 'today'
  },
  {
    label: 'yesterday',
    key: 'yesterday'
  },
  {
    label: 'this Week',
    key: 'thisWeek'
  },
  {
    label: 'this Month',
    key: 'thisMonth'
  }
]
export const CMS_LOCAL_SIGN_UP = '_local_register_'
export const LOCAL_DATA_DOMAIN = '_local_data_domain_'
export const LOCAL_BLACK_DOMAIN = '_local_black_domain_'
