import { IDropdownStyles, mergeStyles } from '@fluentui/react'

const DROPDOWN_HEIGHT = 40
// const DROPDOWN_ITEM_HEIGHT = 36

export const dropdownStyles = (): Partial<IDropdownStyles> => {
  return {
    dropdown: {
      ':hover .ms-Dropdown-title': {
        borderColor: 'var(--primary-100)'
      },
      ':hover .ms-Dropdown-titleIsPlaceHolder': {
        borderColor: 'var(--primary-100)'
      },
      ':focus:after': {
        borderRadius: 'var(--round-4)'
      }
    },
    label: {
      padding: 0,
      lineHeight: 24,
      '::after': {
        color: 'var(--secondary-100)'
      }
    },
    title: {
      lineHeight: 24,
      height: 'unset',
      padding: '7px 28px 7px 16px'
    },
    caretDownWrapper: {
      height: DROPDOWN_HEIGHT,
      lineHeight: DROPDOWN_HEIGHT - 2
    }
  }
}

export const caretDownStyles = mergeStyles({
  fontSize: 24,
  lineHeight: DROPDOWN_HEIGHT - 2,
  color: 'var(--gray-6)'
})
