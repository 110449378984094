import { useCallback, useMemo, useState } from 'react'

import { Button } from 'com-ui2/controls'
import { Icon } from './svg/Icon'
import css from 'styled-jsx/css'
import { iconPath } from 'com-ui/utilities/imagePath'
import { useEffect } from 'react'
import { Dropdown } from 'primereact/dropdown'
import { useUpdateEffect } from 'com-ui2/hooks'

export interface PaginationOnChangeResponse {
  currentPage: number
  totalPage: number
  totalItem: number
  perPage: number
}

const initOptionPerPage = [10, 25, 50]

interface IPaginationProps {
  initialPage?: number
  totalItem: number
  perPage?: number
  isLoading?: boolean
  onChange: (response: PaginationOnChangeResponse) => void
  type?: 'short' | 'long'
  className?: string
  toPage?: number
  toFirstPage?: boolean
  optionPerPage?: number[]
}

export const Pagination = ({ perPage = 10, type = 'short', ...props }: IPaginationProps) => {
  const [currentPage, setCurrentPage] = useState(props.initialPage ?? 1)
  const [inputPerPage, setInputPerPage] = useState(perPage)

  const totalPage = useMemo(() => {
    return Math.ceil(props.totalItem / inputPerPage)
  }, [props, inputPerPage])

  useEffect(() => {
    setCurrentPage(props?.toPage || 1)
  }, [props?.toPage])

  useEffect(() => {
    setInputPerPage(perPage || 10)
  }, [perPage])

  useEffect(() => {
    if (props.toFirstPage) {
      setCurrentPage(1)
    }
  }, [props?.toFirstPage])

  const onPrevClicked = () => {
    const tempPrev = currentPage - 1
    const prevPage = tempPrev < 1 ? 1 : tempPrev
    setCurrentPage(prevPage)
  }

  const onNextClicked = () => {
    const tempNext = currentPage + 1
    const nextPage = tempNext > totalPage ? totalPage : tempNext
    setCurrentPage(nextPage)
  }

  // const onKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
  //   const key = event.key
  //   if (key === 'Enter') {
  //     handleOnPageChanged(inputValue)
  //   }
  // }
  useUpdateEffect(() => {
    props.onChange({
      currentPage,
      totalPage,
      totalItem: props.totalItem,
      perPage: inputPerPage
    })
  }, [inputPerPage, currentPage])

  const onInputChange = useCallback(
    (event: { originalEvent: Event; value: any }) => {
      const value = event.value
      setInputPerPage(value)
    },
    [inputPerPage]
  )

  // const handleOnPageChanged = (currentPage: number) => {
  //   if (!props.isLoading) {
  //     setCurrentPage(currentPage)
  //     props.onChange({
  //       currentPage,
  //       totalPage,
  //       totalItem: props.totalItem,
  //       perPage: inputPerPage
  //     })
  //   }
  // }

  if (!props.totalItem) return null

  return (
    <div className={`pagination ${props.className ?? ''} w-100`}>
      {type === 'long' && (
        <div className="items d-flex align-items-center ml-auto">
          <span className="text mr-3 body-2">Items per page:</span>
          {/* <input
            type="number"
            placeholder="Page..."
            onKeyDown={onKeyDown}
            value={inputValue}
            onChange={onInputChange}
            onFocus={(event) => event.target.select()}
            min={1}
            max={totalPage}
          /> */}
          <Dropdown
            className="in-bg-white"
            value={inputPerPage}
            options={(props.optionPerPage || initOptionPerPage).map((x) => ({ label: x, value: x }))}
            optionValue="value"
            onChange={onInputChange}
            style={{ width: '75px' }}
          />
        </div>
      )}
      <div className={`paging paging--${type} mr-auto align-items-center`}>
        {type === 'short' && (
          <span className="detail">
            {(currentPage - 1) * inputPerPage + 1} to{' '}
            {currentPage * inputPerPage > props.totalItem ? props.totalItem : currentPage * inputPerPage} of{` `}
            {props.totalItem}
          </span>
        )}
        <Button className="arrow-btn prev-btn mr-2" onClick={onPrevClicked} disabled={currentPage == 1}>
          <Icon src={iconPath('chevron_left_regular')} fill="var(--gray-6)" />
        </Button>
        <Button className="arrow-btn" onClick={onNextClicked} disabled={currentPage == totalPage}>
          <Icon src={iconPath('chevron_right_regular')} fill="var(--gray-6)" />
        </Button>
        {type === 'long' && (
          <span className="detail body-2">
            {(currentPage - 1) * inputPerPage + 1} -{' '}
            {currentPage * inputPerPage < props.totalItem ? currentPage * inputPerPage : props.totalItem} of{` `}
            {props.totalItem}
          </span>
        )}
      </div>

      <style jsx>{style}</style>
    </div>
  )
}

const style = css`
  .pagination {
    display: flex;
    .items {
      flex: auto;
    }
    :global(.arrow-btn) {
      background-color: var(--white-100);
      border: 1px solid var(--indigo-4);
      border-radius: 8px;
      color: var(--gray-6);
      padding: 6px;
      width: auto;
      height: auto;
      &:hover {
        background-color: var(--indigo-2);
      }
      &:disabled {
        background-color: var(--white-100);
        pointer-events: none;
        border: 2px solid #e0e3e9;
        :global(svg) {
          fill: var(--gray-4);
        }
      }
    }

    .paging {
      display: flex;
      align-items: center;

      &--short {
        .detail {
          margin-right: 16px;
        }

        :global(.prev-btn) {
          margin-right: 8px;
        }
      }

      &--long {
        .detail {
          margin-left: 16px;
          margin-right: 16px;
        }
      }
    }

    :global(.p-inputtext) {
      padding: 8px 0px 8px 12px;
    }
    :global(.p-dropdown.p-component) {
      box-shadow: none;
      border: 1px solid #e0e3e9;
      box-sizing: border-box;
    }
  }
`
