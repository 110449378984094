import { DefaultButton, IButtonProps, concatStyleSets } from '@fluentui/react'

import React from 'react'
import { getCrButtonStyles } from './cr-action-button.styles'

export type CrActionButtonType = 'iconLeft' | 'iconRight'

export type CrActionButtonSize = 'medium' | 'small'

export interface CrActionButtonProps extends IButtonProps {
  type?: CrActionButtonType
  sizing?: CrActionButtonSize
}

export const CrActionButton: React.FC<CrActionButtonProps> = ({ ...props }) => {
  return (
    <DefaultButton
      {...props}
      primary={props.type && ['primary', 'secondary'].includes(props.type)}
      styles={concatStyleSets(getCrButtonStyles(props), props.styles)}
    />
  )
}
