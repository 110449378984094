import { OptionValue } from 'src/services'

export function deepCloneObject(inObject: Object) {
  if (typeof inObject !== 'object' || inObject === null) {
    return inObject // Return the value if inObject is not an object
  }
  // Create an array or object to hold the values
  const outObject = Array.isArray(inObject) ? [] : {}

  for (const key in inObject) {
    // @ts-ignore
    const value = inObject[key]

    // Recursively (deep) copy for nested objects, including arrays
    // @ts-ignore
    outObject[key] = deepCloneObject(value)
  }

  return outObject
}
export const arrSortBy = (data: any[], key: string) => {
  return data.concat().sort(sortBy(key))
}

export const nonAccentVietnamese = (str: any) => {
  str = str.toLowerCase()
  str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, 'a')
  str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, 'e')
  str = str.replace(/ì|í|ị|ỉ|ĩ/g, 'i')
  str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, 'o')
  str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, 'u')
  str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, 'y')
  str = str.replace(/đ/g, 'd')
  // Some system encode vietnamese combining accent as individual utf-8 characters
  str = str.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, '') // Huyền sắc hỏi ngã nặng
  str = str.replace(/\u02C6|\u0306|\u031B/g, '') // Â, Ê, Ă, Ơ, Ư
  return str
}

const sortBy = (key: string) => {
  return (a: any, b: any) => {
    if (!a[key]) {
      return -1
    }
    if (!b[key]) {
      return 1
    }
    return a[key] > b[key] ? 1 : b[key] > a[key] ? -1 : 0
  }
}

export const uniq = (data: Array<number | string>) => {
  const distinct = (val: any, index: number, self: any) => self.indexOf(val) === index
  return data.filter(distinct)
}

export function arrRemove<T>(array?: T[], index?: number) {
  if (array) {
    if (index === undefined || index < 0) return array
    return [...array.slice(0, index), ...array.slice(index + 1)]
  }
  return array
}

export enum DiffMapperState {
  VALUE_CREATED = 'created',
  VALUE_UPDATED = 'updated',
  VALUE_DELETED = 'deleted',
  VALUE_UNCHANGED = 'unchanged'
}

export const deepDiffMapper = (obj1: any, obj2: any) => {
  const isFunction = (x: any) => {
    return Object.prototype.toString.call(x) === '[object Function]'
  }
  const isArray = (x: any) => {
    return Object.prototype.toString.call(x) === '[object Array]'
  }
  const isDate = (x: any) => {
    return Object.prototype.toString.call(x) === '[object Date]'
  }
  const isObject = (x: any) => {
    return Object.prototype.toString.call(x) === '[object Object]'
  }
  const isValue = (x: any) => {
    return !isObject(x) && !isArray(x)
  }

  const compareValues = (value1: any, value2: any) => {
    if (value1 === value2) {
      return DiffMapperState.VALUE_UNCHANGED
    }
    if (isDate(value1) && isDate(value2) && value1.getTime() === value2.getTime()) {
      return DiffMapperState.VALUE_UNCHANGED
    }
    if (value1 === undefined) {
      return DiffMapperState.VALUE_CREATED
    }
    if (value2 === undefined) {
      return DiffMapperState.VALUE_DELETED
    }
    return DiffMapperState.VALUE_UPDATED
  }

  const map = (obj1: any, obj2: any) => {
    if (isFunction(obj1) || isFunction(obj2)) {
      throw 'Invalid argument. Function given, object expected.'
    }
    if (isValue(obj1) || isValue(obj2)) {
      return {
        type: compareValues(obj1, obj2),
        data: obj1 === undefined ? obj2 : obj1
      }
    }

    const diff: any = {}
    for (const key in obj1) {
      if (isFunction(obj1[key])) {
        continue
      }

      let value2: any = undefined
      if (obj2[key] !== undefined) {
        value2 = obj2[key]
      }

      diff[key] = map(obj1[key], value2)
    }
    for (const key in obj2) {
      if (isFunction(obj2[key]) || diff[key] !== undefined) {
        continue
      }

      diff[key] = map(undefined, obj2[key])
    }

    return diff
  }

  return map(obj1, obj2)
}

export const getOptionValue = (options: OptionValue[], name: string, value: string) =>
  options.find((option) => option.property_name === name)?.mappings?.find((mapping) => mapping.origin_value === value)
    ?.option_value

export const isEmpty = (obj: any) => JSON.stringify(obj) === '{}'

export enum publisher_type {
  'IHO' = 'IHO',
  '3PS' = '3PS',
  'OSP' = 'OSP',
  'UDA' = 'UDA',
  'RMKT' = 'RMKT',
  'BVF' = 'BVF'
}

export const PAGE_SIZE = 10

export const isEqual = (value: any, other: any) => {
  // Get the value type
  const type = Object.prototype.toString.call(value)

  // If the two objects are not the same type, return false
  if (type !== Object.prototype.toString.call(other)) {
    return false
  }

  // If items are not an object or array, return false
  if (['[object Array]', '[object Object]'].indexOf(type) < 0) {
    return false
  }

  // Compare the length of the length of the two items
  const valueLen = type === '[object Array]' ? value.length : Object.keys(value).length
  const otherLen = type === '[object Array]' ? other.length : Object.keys(other).length
  if (valueLen !== otherLen) {
    return false
  }

  // Compare two items
  const compare = (item1: any, item2: any) => {
    // Get the object type
    const itemType = Object.prototype.toString.call(item1)

    // If an object or array, compare recursively
    if (['[object Array]', '[object Object]'].indexOf(itemType) >= 0) {
      if (!isEqual(item1, item2)) {
        return false
      }
    } else {
      // If the two items are not the same type, return false
      if (itemType !== Object.prototype.toString.call(item2)) {
        return false
      }

      // Else if it's a function, convert to a string and compare
      // Otherwise, just compare
      if (itemType === '[object Function]') {
        if (item1.toString() !== item2.toString()) {
          return false
        }
      } else {
        if (item1 !== item2) {
          return false
        }
      }
    }
  }

  // Compare properties
  if (type === '[object Array]') {
    for (let i = 0; i < valueLen; i++) {
      if (compare(value[i], other[i]) === false) {
        return false
      }
    }
  } else {
    for (const key in value) {
      if (Object.hasOwnProperty.call(value, key)) {
        if (compare(value[key], other[key]) === false) {
          return false
        }
      }
    }
  }

  // If nothing failed, return true
  return true
}

export function timeout(ms: number) {
  return new Promise((resolve) => setTimeout(resolve, ms))
}

export function isAndroidIos(): boolean {
  // alert(`${navigator.platform}`)
  // return (
  //   navigator.platform.toLocaleLowerCase().includes('android') ||
  //   navigator.platform.toLocaleLowerCase().includes('iphone')
  // )
  let check = false
  ;(function (a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
        a
      ) ||
      // eslint-disable-next-line no-useless-escape
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a.substr(0, 4)
      )
    )
      check = true
  })(navigator.userAgent || navigator.vendor || (window as any).opera)
  return check
}
