import { showToast } from 'com-ui2/utilities'
import { ToastMessage } from 'primereact/toast'

export const acceptFormatImage = (files: File[]) => {
  const arrayCheck = ['.jpg', '.jpeg', '.png', 'webp', 'gif', '.mp4']
  const isResult = files?.every((file) => arrayCheck?.some((v) => file?.name?.includes(v)))
  if (!isResult) {
    showToast({
      severity: 'error',
      summary: `Error`,
      detail: `Please upload .jpg / .jpeg / .png / .webp / .gif / .mp4 file only`,
      life: 5000
    })
    return
  }

  return isResult
}

const imageCheck = ['.png', '.jpg', '.webp']
const GIF_TYPE = '.gif'

//check if image is one of ['.png', '.jpg', '.webp'] type and image size is larger than 2MB
const validateImage = (file: File) => {
  return imageCheck?.some((type) => file?.name?.includes(type)) && file?.size >= 2000000
}

//check if image is gif type and image size is larger than 8MB
const validateGif = (file: File) => {
  return file?.name?.includes(GIF_TYPE) && file?.size >= 8000000
}

export const acceptSizeImage = (files: File[]): File[] => {
  if (!acceptFormatImage(files)) {
    return []
  }
  const validFile = files?.filter((item) => !validateImage(item) && !validateGif(item))
  const invalidFiles = files?.filter(
    (file) =>
      !validFile?.some((img) => {
        return file?.name === img?.name
      })
  )
  if (invalidFiles?.length) {
    showToast(
      invalidFiles.map((item) => ({
        severity: 'warn',
        summary: `Warning`,
        detail: `File ${invalidFiles?.length >= 1 ? item?.name : ''} size must not exceed ${
          item?.name?.includes(GIF_TYPE) ? '8MB' : '2MB'
        }. Please optimize this file and try again.`,
        life: 7000
      })) as ToastMessage[]
    )
  }
  return validFile
}
