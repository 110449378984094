import { InputText, InputTextProps } from 'primereact/inputtext'
import { formatMobileNumber, regionCode, splitRegion } from 'com-ui2/utilities'

import { InputGroup } from './containers'
import { useRef } from 'react'

export interface InputTelProps extends Omit<InputTextProps, 'ref'> {
  region: 'CN' | 'US' | 'VN' | 'SG'
  dismissIcon?: boolean
}

export const InputTel = ({
  region,
  value,
  onChange,
  className,
  dismissIcon = true,
  error = '',
  title = '',
  ...props
}: InputTelProps) => {
  const { code, remain } = splitRegion(value)
  value = formatMobileNumber(remain)
  const ref = useRef(null)

  const calcCaret = (caret: number, tempValue: string) => {
    const prefix = tempValue.slice(0, caret)
    const count = prefix.replace(/[^0-9]/g, '').length
    if (count == 0) {
      return 1
    }
    //console.log('temp', tempValue, caret)
    //console.log('prefix', prefix, count)
    const newValue = formatMobileNumber(tempValue)
    // @ts-ignore
    const matchAll = [...newValue.matchAll(/[0-9]/g)]
    const newPos = (matchAll?.[count - 1]?.index || 0) + 1
    //console.log(/* matchAll, */ count, matchAll[count - 1], newPos)
    return newPos
  }

  const _onChange = (e: any) => {
    const input: any = e?.target
    const newPos = calcCaret(Math.min(input.selectionStart, input.selectionEnd), input.value)
    input.value = formatMobileNumber(input.value)
    input.setSelectionRange(newPos, newPos)
    const value = input.value.replace(/[^0-9]/g, '')
    const nextValue = value ? `${code || regionCode[region]}${value}` : ''
    onChange?.(nextValue)
  }

  return (
    <InputGroup
      title={title}
      prefix={code || regionCode[region]}
      prefixDelimiter={false}
      error={error}
      className={className}
    >
      <InputText
        onChange={_onChange}
        defaultValue={value}
        keyfilter={/[0-9]/}
        className="pl-0"
        maxLength={24}
        ref={ref}
        {...props}
      />
    </InputGroup>
  )
}
