import {
  concatStyleSetsWithProps,
  Checkbox,
  ICheckboxProps,
  memoizeFunction,
  ITheme,
  useCustomizationSettings
} from '@fluentui/react'
import { mergeThemes } from '@fluentui/theme'
import { checkboxStyles } from './cr-checkbox.styles'

export const generateCheckboxTheme = memoizeFunction((theme: ITheme) => {
  return mergeThemes(theme, {
    semanticColors: {
      inputBorderHovered: 'var(--secondary-100-darker)',
      inputBackgroundChecked: 'var(--secondary-100)',
      inputBackgroundCheckedHovered: 'var(--secondary-100-darker)'
    },
    effects: {
      roundedCorner2: '2px'
    }
  })
})

export const CrCheckbox: React.FC<ICheckboxProps> = (props) => {
  const settings = useCustomizationSettings(['theme'])
  return (
    <Checkbox
      {...props}
      theme={generateCheckboxTheme(settings.theme)}
      styles={(stylesProps) => concatStyleSetsWithProps(stylesProps, ...[checkboxStyles, props.styles])}
    />
  )
}
