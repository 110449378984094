import { useEffect, useState } from 'react'

import css from 'styled-jsx/css'
import usePortal from 'react-useportal'

export interface IDrawer extends React.AllHTMLAttributes<HTMLElement> {
  open?: boolean
  position?: 'left' | 'right' | 'top' | 'bottom'
}

export function Drawer({ open = false, position = 'left', children, className }: IDrawer) {
  const { Portal } = usePortal()
  const [render, setRender] = useState(false)
  useEffect(() => {
    setRender(true)
  }, [])
  return render ? (
    <Portal>
      <div className={`wrapper ${position} ${open ? 'open' : ''} ${className || ''}`}>
        {children}
        <style jsx>{drawerStyle}</style>
      </div>
    </Portal>
  ) : null
}

const drawerStyle = css`
  .wrapper {
    height: 100vh;
    height: -moz-available;
    height: -webkit-fill-available;
    height: fill-available;
    width: 100vw;
    overflow: auto;
    top: 0;
    left: 0;
    position: fixed;
    z-index: 999;
    background-color: var(--white-100);
    opacity: 0;
    transition: transform var(--t-normal), opacity var(--t-normal);
    &.left {
      transform: translateX(-100%);
    }
    &.right {
      transform: translateX(100%);
    }
    &.top {
      transform: translateY(-100%);
    }
    &.bottom {
      transform: translateY(100%);
    }
    &.open {
      transform: none;
      opacity: 1;
    }
  }
`
