import { endOfDay, startOfDay, addDays } from 'date-fns'
import { createStaticRanges, defaultStaticRanges } from 'react-date-range'

export const customRange = createStaticRanges([
  // // @ts-ignore
  // {
  //   label: 'Today',
  //   range: () => ({
  //     startDate: startOfDay(dateUs),
  //     endDate: endOfDay(dateUs)
  //   })
  // },
  // // @ts-ignore
  // {
  //   label: 'Yesterday',
  //   range: () => ({
  //     startDate: startOfDay(addDays(new Date(), -1)),
  //     endDate: endOfDay(addDays(new Date(), -1))
  //   })
  // },
  // @ts-ignore
  {
    label: 'Last 7 days',
    range: () => ({
      startDate: startOfDay(addDays(new Date(), -7)),
      endDate: endOfDay(new Date())
    })
  },
  // @ts-ignore
  {
    label: 'Last 14 days',
    range: () => ({
      startDate: startOfDay(addDays(new Date(), -14)),
      endDate: endOfDay(new Date())
    })
  },
  // @ts-ignore
  {
    label: 'Last 30 days',
    range: () => ({
      startDate: startOfDay(addDays(new Date(), -30)),
      endDate: endOfDay(new Date())
    })
  },
  // @ts-ignore
  ...defaultStaticRanges.slice(2),
  {
    label: 'Lifetime',
    // @ts-ignore
    isSelected: (range) => {
      return (
        range.startDate?.getTime() === new Date(0).getTime() &&
        range.endDate?.getTime() === endOfDay(new Date()).getTime()
      )
    },
    range: () => ({
      startDate: new Date(0),
      endDate: endOfDay(new Date())
    })
  }
])
