import SVG, { Props } from 'react-inlinesvg'

export interface IIcon extends Props {
  enableClick?: boolean
  size?:
    | '10px'
    | '12px'
    | '14px'
    | '16px'
    | '18px'
    | '20px'
    | '24px'
    | '28px'
    | '32px'
    | '40px'
    | '48px'
    | '60px'
    | '64px'
    | '66px'
    | '72px'
    | '128px'
}

export function Icon({ src, size = '24px', enableClick = true, className, fill = 'var(--d-90)', ...props }: IIcon) {
  const style = {
    ...(enableClick ? { cursor: 'pointer' } : {}),
    ...(size ? { height: size, width: size } : {})
  }

  return (
    <>
      <SVG
        src={src}
        uniquifyIDs={true}
        className={`${className || ''}`}
        cacheRequests={true}
        {...props}
        style={{ ...style, ...props.style }}
        fill={fill}
      />
    </>
  )
}
