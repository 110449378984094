import { commonShared } from 'com-ui/utilities/imagePath'
import { Button } from 'com-ui2/controls'
import { useGlobalState } from 'com-ui2/hooks'
import { Dialog } from 'primereact/dialog'
import { useSelector } from 'react-redux'
import { getPublisherState } from 'src/containers/settings/store'
import css from 'styled-jsx/css'

export const DialogWelcome = () => {
  const [activeUser, setActiveUser] = useGlobalState('_local_active_user_')
  const publisher = useSelector(getPublisherState)
  const userName = `${publisher?.first_name || ''} ${publisher?.last_name || ''}`

  const Footer = (
    <div className="w-100 d-flex align-items-center justify-content-center">
      <Button type="primary" size="big" onClick={() => setActiveUser(`showed${publisher?.id || ''}`)}>
        <span className="subtitle-2">Got it!</span>
      </Button>
    </div>
  )
  const Header = (
    <>
      <div className="logo-container pt-3">
        <img src={commonShared('selless-logo-black.svg')} alt="selless logo" />
      </div>
      <h6 className="h6 pt-4 text">Welcome to Selless, {userName}!</h6>
    </>
  )
  return (
    <Dialog
      blockScroll
      footer={Footer}
      baseZIndex={99999}
      header={Header}
      className="dialog has-footer"
      onHide={() => setActiveUser(`showed${publisher?.id || ''}`)}
      visible={activeUser == 'active'}
      draggable={false}
      resizable={false}
    >
      <div className="dialog__content">
        <div className="pb-3">
          <span className="body-1b">Congratulations! These are the fee details of your account on Selless:</span>
        </div>
        <div className="pb-3">
          <span className="body-1b">1. Selless Fee:</span>
          <span className="body-1">
            This including service fee, risk fee and system fee. They are all operation services as fulfillment,
            customer support, sourcing, software system and to cover the issues if happened. <br />
            Your starting Selless fee is
          </span>{' '}
          <span className="body-1b">
            {(publisher?.fee_system_percent || 0) + 2}% + ${publisher?.fee_system_value || 0}
          </span>
        </div>
        <div className="pb-3">
          <span className="body-1b">2. Payment Fee:</span>
          <span className="body-1">
            This is the payment processing fee for purchase transactions charged by our payment gateway and processing
            providers (including Paypal,Visa, Mastercard, American Express, Diners Club, JCB, UnionPay, Discover..){' '}
            <br />
            Your payment fee is
          </span>{' '}
          <span className="body-1b">
            {publisher?.fee_payment_percent || 0}% + ${publisher?.fee_payment_value || 0}
          </span>
        </div>
        <div className="pb-3">
          <span className="body-1b">3. Hold amount:</span>
          <span className="body-1">
            No jumpstart hold is applied to your account. <br />
            <span>There is a rolling hold as</span>{' '}
            <span className="body-1b">
              {publisher?.hold_percent || 0}% in {publisher?.hold_days || 0} days
            </span>
          </span>
        </div>
        <div className="pb-3">
          <span className="body-1">
            4. No customer refunds, chargebacks and disputes on your sales activities are charged back to you; pursuant
            to our Seller Terms and Conditions
          </span>
        </div>
      </div>
      <style jsx>{style}</style>
    </Dialog>
  )
}

const style = css`
  .dialog {
    display: flex;
    flex-direction: column;
    &__content {
      color: var(--gray-9);
      width: 700px;
    }
  }
  .body-1,
  .body-1b {
    font-size: 14px;
  }
  .text {
    color: var(--gray-9);
  }
  :global(.p-dialog-title) {
    width: 100%;
    text-align: center;
  }
  :global(.p-dialog .p-dialog-footer button) {
    width: 280px;
  }
  :global(.p-dialog-header) {
    align-items: flex-start;
  }
  :global(.p-dialog-mask.p-component-overlay) {
    background-color: rgba(0, 0, 0, 0.8);
  }
  .layout {
    min-height: 100vh;
    background-color: var(--indigo-2);
    padding: 64px 16px;
  }
  .logo-container {
    padding: 0 0 32px;
    text-align: center;
  }
`
