import { ISpinButtonStyles, SpinButton } from '@fluentui/react'

import CrLabel from './cr-label'
import { SyntheticEvent, useEffect, useState } from 'react'
import { useId } from '@fluentui/react-hooks'
import styled from 'styled-components'

export interface ICrNumberEditor extends Omit<Types.ICrEditor, 'value'>, Types.ISchema {
  onValueChanged?: (e: number) => void
  value?: number
  prefix?: string
  suffix?: string
  isInteger?: boolean
}

export default function CrNumberEditor(props: ICrNumberEditor) {
  type ActionEventType = React.MouseEvent<HTMLElement> | React.KeyboardEvent<HTMLElement> | string | void
  const spinButtonId = useId('spinButtonId')
  const [value, setValue] = useState<number>(0)

  useEffect(() => {
    setValue(props.value || 0)
  }, [props.value])

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onIncrement = (value: string, _event: ActionEventType) => {
    const max = props?.max
    let newValue = +value + (props.step ?? 1)
    newValue = max !== undefined ? (newValue > max ? max : newValue) : newValue
    props?.onValueChanged?.(newValue)
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const onDecrement = (value: string, _event: ActionEventType) => {
    const min = props?.min
    let newValue = +value - (props.step ?? 1)
    newValue = min !== undefined ? (newValue < min ? min : newValue) : newValue
    props?.onValueChanged?.(newValue)
  }

  const onChange = (event: SyntheticEvent<HTMLElement, Event>) => {
    const target = event.target as HTMLInputElement | HTMLTextAreaElement
    const newValue = target.value === '' ? 0 : !isNaN(parseFloat(target.value)) ? +target.value : value
    props?.onValueChanged?.(newValue)
  }

  return (
    <CrLabel {...{ ...props, htmlFor: spinButtonId }}>
      <NumberEditorWrapper>
        {props.prefix && <span className="prefix">{props.prefix}</span>}
        <SpinButton
          styles={{ ...spinButtonStyles, ...((props.errorMessage && errorSpinButtonStyles) || undefined) }}
          {...props}
          value={`${props?.value}`}
          label=""
          inputProps={{
            type: 'number',
            id: spinButtonId,
            placeholder: props?.placeholder || '',
            onKeyPress: (event) => props.isInteger && event.key !== '.'
          }}
          onChange={onChange}
          onIncrement={onIncrement}
          onDecrement={onDecrement}
          onBlur={(v) => v}
          onValidate={(v) => v} // Work arround for min, max issues (https://github.com/microsoft/fluentui/issues/11358)
          className=""
        />
        {props.suffix && <span className="suffix">{props.suffix}</span>}
      </NumberEditorWrapper>
    </CrLabel>
  )
}

const spinButtonStyles: Partial<ISpinButtonStyles> = {
  spinButtonWrapper: {
    height: 40,
    position: 'static'
  },
  arrowButtonsContainer: {
    display: 'none'
  }
}

const errorSpinButtonStyles = {
  spinButtonWrapper: {
    selectors: {
      '&::after': {
        borderColor: 'var(--danger-100)'
      }
    }
  },
  spinButtonWrapperHovered: {
    selectors: {
      '&:hover::after': {
        borderColor: 'var(--danger-100)'
      }
    }
  },
  spinButtonWrapperFocused: {
    selectors: {
      '&&::after': {
        borderColor: 'var(--danger-100)'
      },
      '&&:hover::after': {
        borderColor: 'var(--danger-100)'
      },
      '&&:focus::after': {
        borderColor: 'var(--danger-100)'
      }
    }
  }
}

const NumberEditorWrapper = styled.div`
  position: relative;
  display: flex;
  cursor: text;

  .prefix,
  .suffix {
    background: var(--gray-2);
    display: flex;
    align-items: center;
    padding: 0 16px;
  }

  .prefix {
    border-right: 1px solid var(--gray-3);
  }

  .suffix {
    border-left: 1px solid var(--gray-3);
  }
`
