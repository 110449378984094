import { DATE_FORMAT, InputDateProps, MASK_FORMAT, MAX_DATE, MIN_DATE, inputDateStyle } from './DateHelper'
import { format, isAfter, isBefore, isValid, parse } from 'date-fns'
import { useEffect, useMemo, useRef, useState } from 'react'

import { Icon } from 'com-ui2/components'
import { InputGroup } from '../containers'
import { InputMask } from 'primereact/inputmask'
import { PickerDate } from './PickerDate'
import { iconPath } from 'com-ui/utilities/imagePath'
import { useClickOutside } from 'com-ui2/hooks'

/** TODO :
 * implement auto: detect screen
 * bug lost value when blur input mask
 * - collapse effect
 * - clear on blur
 * - props for default config
 */
const mask = `${MASK_FORMAT}`

export function InputDate({
  date,
  minDate = MIN_DATE,
  maxDate = MAX_DATE,
  className,
  onChange,
  position = 'bottom left',
  pickerProps,
  inputProp
}: InputDateProps) {
  const [focus, setFocus] = useState(false)
  const ref = useRef(null)
  useClickOutside(ref, () => {
    setFocus(false)
  })

  return useMemo(
    () => (
      <div className={`input-date-container ${className || ''}`} ref={ref}>
        <div className="input-date" onClick={(_e) => !focus && setFocus(true)}>
          <InputMaskDate {...{ date, minDate, maxDate, onChange, focus, inputProp }} />
        </div>
        <div className={`date-wrapper ${position} ${focus ? 'open' : ''}`}>
          <PickerDate {...{ date, onChange, pickerProps }} />
        </div>
        <style jsx>{inputDateStyle}</style>
      </div>
    ),
    [date, focus]
  )
}

const InputMaskDate = ({
  date,
  minDate = MIN_DATE,
  maxDate = MAX_DATE,
  onChange,
  focus,
  inputProp
}: InputDateProps) => {
  const dateFormat = inputProp.dateFormat || DATE_FORMAT
  const slotChar = `${dateFormat}`
  const formatter = (date: any) => (date ? `${format(new Date(date), dateFormat)}` : '')
  const nextVal = formatter(date)
  const [value, setValue] = useState(nextVal)
  useEffect(() => {
    setValue((val: string) => {
      //console.log('set value', nextVal, val)
      return nextVal !== val ? nextVal : val
    })
  }, [nextVal, focus])

  const _onChange = (e: any) => {
    const _value = focus ? e.value : nextVal
    setValue(_value)
    validate(_value)
  }

  const validate = (val: string) => {
    if (!val) {
      onChange?.(undefined)
    }
    const nextDate = parse(val, dateFormat, new Date())
    if (!isValid(nextDate) || isAfter(nextDate, maxDate) || isBefore(nextDate, minDate)) {
      return
    }
    onChange?.(nextDate)
  }

  return (
    <InputGroup
      suffix={
        <div className="d-flex align-items-center">
          <Icon src={iconPath('calendar_ltr_regular')} size="20px" fill="var(--gray-7)" />
        </div>
      }
      prefixDelimiter={false}
      suffixDelimiter={false}
    >
      <InputMask
        mask={mask}
        slotChar={slotChar}
        value={value}
        onChange={_onChange}
        placeholder={`${inputProp?.placeholder ? inputProp.placeholder : 'Select a date'}`}
        //unmask={true}
        {...inputProp}
      />
    </InputGroup>
  )
}
