import {
  experience,
  investment,
  own_ecommerce_business,
  PublisherRegister,
  SellessDomain,
  total_order_last_month,
  type_ecommerce_business,
  type_payment
} from 'src/services'

export const getPrimaryDomain = (domains?: SellessDomain[]) =>
  domains ? domains.find((domain) => domain.is_primary)?.name : ''

export const getBody = (filter: any) =>
  Object.keys(filter)
    .filter((k: string) => filter[k] !== '')
    .reduce((a, k) => ({ ...a, [k]: filter[k] }), {})

// content_ids = [
//   'EB1',
//   ...(state.type_ecommerce_business?.includes(type_ecommerce_business.DROP_SHIPPING) ? ['EBD'] : []),
//   ...(state.experience === experience.LESSS_THAN_SIX_MONTH ? ['FB16'] : []),
//   ...(state.experience === experience.SIX_TO_TWELVE_MONTH ? ['FB612'] : []),
//   ...(state.experience === experience.ONE_TO_THREE_YEAR ? ['FB13Y'] : []),
//   ...(state.experience === experience.MORE_THAN_THREE_YEAR ? ['FBM3Y'] : []),
//   ...(state.total_order_last_month === total_order_last_month.LESS_THAN_500 ? ['O500'] : []),
//   ...(state.total_order_last_month === total_order_last_month.FIVE_HUNDRED_TO_2500 ? ['O2500'] : []),
//   ...(state.total_order_last_month === total_order_last_month.TWO_THOUSANDS_FIVE_HUNDRED_TO_5000
//     ? ['O5K']
//     : []),
//   ...(state.total_order_last_month === total_order_last_month.MORE_THAN_5000 ? ['OM5K'] : [])
// ]

interface pixelProps {
  content_ids: string[]
  content_type: string
  content_category?: string
}
export const pixelData = (state: PublisherRegister): pixelProps => {
  const content_ids: string[] = [
    `${state.own_ecommerce_business === own_ecommerce_business.YES ? 'EB1' : 'EB0'}${
      state.type_ecommerce_business?.includes(type_ecommerce_business.DROP_SHIPPING) ? 'EBD' : ''
    }${state.type_ecommerce_business?.includes(type_ecommerce_business.POD) ? 'EBP' : ''}${
      state.type_ecommerce_business?.includes(type_ecommerce_business.BRAND) ? 'EBB' : ''
    }${state.experience === experience.NO_EXPERIENCE ? 'FB0' : ''}${
      state.experience === experience.LESSS_THAN_SIX_MONTH ? 'FB16' : ''
    }${state.experience === experience.SIX_TO_TWELVE_MONTH ? 'FB612' : ''}${
      state.experience === experience.ONE_TO_THREE_YEAR ? 'FB13Y' : ''
    }${state.experience === experience.MORE_THAN_THREE_YEAR ? 'FBM3Y' : ''}${
      state.total_order_last_month === total_order_last_month.LESS_THAN_500 ? 'O500' : ''
    }${state.total_order_last_month === total_order_last_month.FIVE_HUNDRED_TO_2500 ? 'O2500' : ''}${
      state.total_order_last_month === total_order_last_month.TWO_THOUSANDS_FIVE_HUNDRED_TO_5000 ? 'O5K' : ''
    }${state.total_order_last_month === total_order_last_month.MORE_THAN_5000 ? ['OM5K'] : ''}${
      state.type_payments?.includes(type_payment.OTHER) ? ['PGO'] : ''
    }${state.type_payments?.includes(type_payment.PAYPAL) ? ['PGP'] : ''}${
      state.type_payments?.includes(type_payment.STRIPE) ? ['PGS'] : ''
    }${state.investment === investment.LESS_THAN_TWO_THOUSAND ? ['I2K'] : ''}${
      state.investment === investment.TWO_TO_FIVE_THOUSAND ? ['I5K'] : ''
    }${state.investment === investment.FIVE_TO_TEN_THOUSAND ? ['I10K'] : ''}${
      state.investment === investment.MORE_THAN_TEN_THOUSAND ? ['IM10K'] : ''
    }`
  ]

  let content_category = ''
  if (state.own_ecommerce_business === own_ecommerce_business.YES) {
    if (
      state.type_ecommerce_business?.includes(type_ecommerce_business.DROP_SHIPPING) &&
      state.experience !== experience.NO_EXPERIENCE
    ) {
      content_category = 'MAL_core'
    }
  } else {
    if (
      ((state.experience === experience.NO_EXPERIENCE || state.experience === experience.LESSS_THAN_SIX_MONTH) &&
        (state.investment === investment.LESS_THAN_TWO_THOUSAND ||
          state.investment === investment.TWO_TO_FIVE_THOUSAND)) ||
      ((state.experience === experience.SIX_TO_TWELVE_MONTH ||
        state.experience === experience.ONE_TO_THREE_YEAR ||
        state.experience === experience.MORE_THAN_THREE_YEAR) &&
        state.investment === investment.LESS_THAN_TWO_THOUSAND)
    ) {
      content_category = 'MAL_lowest'
    } else if (
      (state.experience === experience.NO_EXPERIENCE || state.experience === experience.LESSS_THAN_SIX_MONTH) &&
      (state.investment === investment.FIVE_TO_TEN_THOUSAND || state.investment === investment.MORE_THAN_TEN_THOUSAND)
    ) {
      content_category = 'MAL_newbie'
    } else if (
      (state.experience === experience.SIX_TO_TWELVE_MONTH ||
        state.experience === experience.ONE_TO_THREE_YEAR ||
        state.experience === experience.MORE_THAN_THREE_YEAR) &&
      (state.investment === investment.TWO_TO_FIVE_THOUSAND ||
        state.investment === investment.FIVE_TO_TEN_THOUSAND ||
        state.investment === investment.MORE_THAN_TEN_THOUSAND)
    ) {
      content_category = 'MAL_mid'
    }
  }
  return {
    content_ids,
    content_type: 'signup',
    ...(content_category ? { content_category } : {})
  }
}

export const getProductLink = (store_domain?: string, page_slug?: string) => {
  return !page_slug ? `${store_domain}` : `${store_domain}/${page_slug}`
}
