import css from 'styled-jsx/css'

export type MessageColor = 'primary' | 'secondary' | 'error' | 'warning' | 'disabled'

export interface MessageBoxProps extends Types.PlainObject {
  type?: MessageColor
  title?: string
  description?: string
}

export function MessageBox({ type, title = '', description = '', ...props }: MessageBoxProps) {
  return (
    <>
      <div {...props}>
        <div className="wrapper d-flex  ">
          <div className={`taple ${type} `}></div>
          <div className="box">
            <h5 className={`subtitle-1 title ${type} `} style={{ backgroundColor: 'var(--white-100)' }}>
              {title}
            </h5>
            <p className={`body-2 desc`}>{description}</p>
          </div>
        </div>
      </div>
      <style jsx>{style}</style>
    </>
  )
}

const style = css`
  .wrapper {
    width: 100%;
    box-shadow: var(--depth-4);
    background-color: var(--white-100);
    transition: var(--transition);
    &:hover {
      box-shadow: var(--depth-16);
    }
    &.in-bg-white {
      box-shadow: none;
      border: 2px solid var(--indigo-3);
      box-sizing: border-box;
    }
    border-radius: 4px 8px 8px 4px;
    margin-bottom: 50px;
    margin-top: 32px;
  }
  .box {
    padding: 16px 16px 22px 30px;
    width: 100%;
    border: none;
  }
  .taple {
    width: 6px;
    border-radius: 4px 0px 0px 4px;
  }
  .desc {
    margin: 4px 0px;
  }
  .primary {
    color: var(--primary-100);
    background-color: var(--primary-100);
  }
  .secondary {
    color: var(--secondary-100);
    background-color: var(--secondary-100);
  }
  .error {
    color: var(--red-400);
    background-color: var(--red-400);
  }
  .warning {
    color: var(--orange-500);
    background-color: var(--orange-500);
  }
  .disabled {
    color: var(--indigo-8);
    background-color: var(--indigo-8);
  }
`
