import { Tooltip as PTooltip, TooltipProps } from 'primereact/tooltip'

interface PTooltipProps extends TooltipProps {
  children?: React.ReactElement | string
  type?: 'dark' | 'gray' | 'blue' | 'white' | 'warning'
  className?: string
}

export function Tooltip({ className, ...props }: PTooltipProps) {
  return (
    <>
      <PTooltip
        {...props}
        position={props.position || 'top'}
        className={`${className || ''} p-tooltip-${props.type || 'white'}`}
      >
        {props.children}
      </PTooltip>
    </>
  )
}
