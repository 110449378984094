import { InputDateProps, MAX_DATE, MIN_DATE } from './DateHelper'

import dynamic from 'next/dynamic'

const Calendar = dynamic(() => import('react-date-range').then((mod: any) => mod.Calendar), {
  ssr: false
})

export function PickerDate({
  date = new Date(),
  minDate = MIN_DATE,
  maxDate = MAX_DATE,
  onChange,
  pickerProps
}: InputDateProps) {
  const inputDate = date ? new Date(date) : new Date()
  const _onChange = (date: any) => {
    if (date) onChange?.(date)
  }
  return (
    <div className="date-picker-container">
      <Calendar
        date={inputDate}
        onChange={_onChange}
        direction="vertical"
        scroll={{ enabled: true }}
        dateDisplayFormat="dd-MM-yyyy"
        weekdayDisplayFormat="EEEEE"
        showMonthAndYearPickers={true}
        weekStartsOn={1}
        {...{ minDate: minDate, maxDate: maxDate, ...pickerProps }}
      />
    </div>
  )
}
