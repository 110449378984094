import css from 'styled-jsx/css'

export interface CardBorderProps extends Types.PlainObject {
  className?: string
  label?: string
}

export default function CardBorder({ className, label, ...props }: CardBorderProps) {
  return (
    <>
      {label && <span className="subtitle-1">{label}</span>}
      <article {...props} className={`cart-border mt-1 ${className || ''} `}>
        {props.children}
        <style jsx>{style}</style>
      </article>
    </>
  )
}

const style = css`
  .cart-border {
    padding: 24px;
    background: #ffffff;
    /* Indigo 3 */

    border: 2px solid var(--indigo-3);
    box-sizing: border-box;
    border-radius: 8px;
    background-color: var(--white-100);
  }
`
