import getConfig from 'next/config'

export const PCM_IMAGE_FILE_PATH = getConfig().publicRuntimeConfig.imageSourceBasePath

export const SCM_IMAGE_FILE_PATH = getConfig().publicRuntimeConfig.imageSourceSCM

export const productImageUriBuilder = (productId: string, imageSource: string): string =>
  imageSource && productId ? `${PCM_IMAGE_FILE_PATH}bases/${productId}/gallery/${imageSource}` : ''

export const scmImageBuilder = (path: string) => `${SCM_IMAGE_FILE_PATH}${path}`
